import { useCallback, useMemo, useState } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import {
  IRemoteNotification,
  useNotificationFactory,
} from '@magicbell/react-headless';
import MagicBellInboxLink from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInbox/components/MagicBellInboxLink';
import { formatDateTimeDistance } from 'libs/date';
import { useInterval } from 'libs/hooks';

const useStyles = makeStyles()((theme) => ({
  menuItem: {
    minHeight: 64,
    cursor: 'default',
  },
  primaryTypography: {
    width: '85%',
    display: '-webkit-box',
    lineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    boxOrient: 'vertical',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  secondaryTypography: {
    width: '85%',
    display: '-webkit-box',
    lineClamp: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    boxOrient: 'vertical',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  typographyFontWeightRegular: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const formatMagicBellUnixTimestamp = (unixTimestamp: number | null) => {
  const epochTimestamp = unixTimestamp ? unixTimestamp * 1000 : null;

  return formatDateTimeDistance(epochTimestamp);
};

export type MagicBellInboxMenuListItemProps = {
  notification: IRemoteNotification;
};

export default function MagicBellInboxMenuListItem({
  notification,
}: MagicBellInboxMenuListItemProps) {
  const { classes } = useStyles();

  const magicBellNotification = useNotificationFactory(notification);

  const { sentAt, actionUrl, customAttributes } = notification ?? {};

  const actionMessage: string = useMemo(
    () =>
      typeof customAttributes === 'string'
        ? customAttributes
        : (customAttributes?.['actionMessage'] as string),
    [customAttributes]
  );

  const {
    isRead,
    markAsRead = () => null,
    markAsUnread = () => null,
  } = magicBellNotification ?? {};

  const [formattedSentAt, setFormattedSentAt] = useState(
    formatMagicBellUnixTimestamp(sentAt)
  );

  useInterval(
    () => setFormattedSentAt(formatMagicBellUnixTimestamp(sentAt)),
    60_000
  );

  const handleOnClickToggleIsRead = useCallback(() => {
    if (!isRead) {
      markAsRead();
    }

    if (isRead) {
      markAsUnread();
    }
  }, [isRead, markAsRead, markAsUnread]);

  return (
    <MenuItem className={classes.menuItem}>
      <ListItemText
        primary={notification.title}
        primaryTypographyProps={{ className: classes.primaryTypography }}
        secondary={
          <>
            <Typography variant="body2" className={classes.secondaryTypography}>
              {magicBellNotification.sanitizedContent}
            </Typography>
            <Box pt={1} width="85%">
              <Typography variant="caption" color="textSecondary">
                {formattedSentAt}
              </Typography>
              {actionUrl && (
                <Box ml={1} component="span">
                  <MagicBellInboxLink
                    actionUrl={actionUrl}
                    actionMessage={actionMessage}
                  />
                </Box>
              )}
            </Box>
          </>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
      <ListItemSecondaryAction>
        <Tooltip title={isRead ? 'Mark as Unread' : 'Mark as Read'}>
          <IconButton onClick={handleOnClickToggleIsRead} edge="end">
            <Badge color="primary" variant="dot" invisible={isRead}>
              <MessageIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </MenuItem>
  );
}
