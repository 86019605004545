import { ConsoleErrorHandler } from 'libs/error/console';
import { SentryErrorHandler } from 'libs/error/sentry';

export enum ErrorHandlerProvider {
  console = 'console',
  sentry = 'sentry',
}

export interface ErrorHandler {
  handle(error?: unknown): void;
}

export const initErrorHandler = (): ErrorHandler => {
  const provider =
    ErrorHandlerProvider[
      import.meta.env.VITE_ERROR_HANDLER as keyof typeof ErrorHandlerProvider
    ] ?? ErrorHandlerProvider.console;

  switch (provider) {
    case ErrorHandlerProvider.console:
      return new ConsoleErrorHandler();
    case ErrorHandlerProvider.sentry:
      return new SentryErrorHandler();
    default:
      return new ConsoleErrorHandler();
  }
};

export const errorHandler = initErrorHandler();
