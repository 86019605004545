import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CampaignAsTableRowProjectionDto,
  CampaignDto,
  CampaignDtoStatusEnum,
  CampaignMetricsDto,
  RequestForProposalFormOutputDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getCampaignMetrics = createAsyncThunk<
  CampaignMetricsDto,
  { clientId: number; advertiserIds?: number[] },
  AsyncThunkConfig
>(
  'campaigns/getCampaignMetrics',
  async ({ clientId, advertiserIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.campaign.getCampaignMetricsByClientId(
        clientId,
        advertiserIds
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllCampaigns = createAsyncThunk<
  CampaignDto[],
  {
    clientId: number;
    advertiserIds?: number[];
  },
  AsyncThunkConfig
>(
  'campaigns/getCampaigns',
  async ({ clientId, advertiserIds = [] }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.campaign.getAllCampaignsByClientId(
        clientId,
        undefined,
        ['Closed Won'],
        undefined,
        advertiserIds
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllCampaignsByStatusIn = createAsyncThunk<
  CampaignDto[],
  {
    clientId: number;
    advertiserIds?: number[];
    statuses: CampaignDtoStatusEnum[];
  },
  AsyncThunkConfig
>(
  'campaigns/getAllCampaignsByStatusIn',
  async (
    { clientId, advertiserIds = [], statuses = [] },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.campaign.getAllCampaignsByClientId(
        clientId,
        statuses,
        ['Closed Won'],
        undefined,
        advertiserIds
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllCampaignsByAdvertiserId = createAsyncThunk<
  CampaignDto[],
  {
    clientId: number;
    advertiserId: number;
  }
>(
  'campaigns/getAllCampaignsByAdvertiserId',
  async ({ clientId, advertiserId }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.campaign.getAllCampaignsByClientIdAndAdvertiserId(
          clientId,
          advertiserId,
          [],
          ['Closed Won'],
          undefined
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllCampaignsByAdvertiserIdAndPlatformIn = createAsyncThunk<
  CampaignDto[],
  {
    clientId: number;
    advertiserId: number;
    platforms: ('XANDR' | 'CELTRA' | 'GOOGLE_CAMPAIGN_MANAGER')[];
  }
>(
  'campaigns/getAllCampaignsByAdvertiserIdAndPlatformIn',
  async (
    { clientId, advertiserId, platforms },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await api.campaign.getAllCampaignsByClientIdAndAdvertiserId(
          clientId,
          advertiserId,
          [],
          ['Closed Won'],
          platforms
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllCampaignsUsingTableRowProjection = createAsyncThunk<
  CampaignAsTableRowProjectionDto[],
  {
    clientId: number;
    advertiserIds?: number[];
  },
  AsyncThunkConfig
>(
  'campaigns/getAllCampaignsUsingTableRowProjection',
  async ({ clientId, advertiserIds = [] }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.campaign.getAllCampaignsByClientIdUsingTableRowProjection(
          clientId,
          undefined,
          ['Closed Won'],
          advertiserIds
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllCampaignsUsingTableRowProjectionByStatusIn =
  createAsyncThunk<
    CampaignAsTableRowProjectionDto[],
    {
      clientId: number;
      advertiserIds?: number[];
      statuses: CampaignDtoStatusEnum[];
    },
    AsyncThunkConfig
  >(
    'campaigns/getAllCampaignsUsingTableRowProjectionByStatusIn',
    async (
      { clientId, advertiserIds = [], statuses = [] },
      { dispatch, rejectWithValue }
    ) => {
      try {
        const response =
          await api.campaign.getAllCampaignsByClientIdUsingTableRowProjection(
            clientId,
            statuses,
            ['Closed Won'],
            advertiserIds
          );

        return parseApiPayload(response);
      } catch (error) {
        const apiError = toApiError(error);

        if (!apiError.isAbortedError) {
          dispatch(
            setError({
              message: apiError.message,
              uiMessage: apiError.uiMessage,
            })
          );
        }

        return rejectWithValue(apiError);
      }
    }
  );

export const getAllCampaignsByAdvertiserIdUsingTableRowProjection =
  createAsyncThunk<
    CampaignAsTableRowProjectionDto[],
    {
      clientId: number;
      advertiserId: number;
    }
  >(
    'campaigns/getAllCampaignsByAdvertiserIdUsingTableRowProjection',
    async ({ clientId, advertiserId }, { dispatch, rejectWithValue }) => {
      try {
        const response =
          await api.campaign.getAllCampaignsByClientIdAndAdvertiserIdUsingTableRowProjection(
            clientId,
            advertiserId,
            [],
            ['Closed Won']
          );

        return parseApiPayload(response);
      } catch (error) {
        const apiError = toApiError(error);

        if (!apiError.isAbortedError) {
          dispatch(
            setError({
              message: apiError.message,
              uiMessage: apiError.uiMessage,
            })
          );
        }

        return rejectWithValue(apiError);
      }
    }
  );

export const getRequestForProposalForms = createAsyncThunk<
  RequestForProposalFormOutputDto[],
  {
    clientId: number;
    advertiserIds?: number[];
  },
  AsyncThunkConfig
>(
  'campaigns/getRequestForProposalForms',
  async ({ clientId, advertiserIds = [] }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.forms.getAllRequestForProposalFormsByClientId(
        clientId,
        [],
        [
          'PROSPECT',
          'PROPOSAL_SENT',
          'AWAITING_IO',
          'PENDING_APPROVAL',
          'RECEIVED_VERBAL_CONFIRMATION',
        ],
        advertiserIds
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
