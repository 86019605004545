import createPalette from '@mui/material/styles/createPalette';

const paletteOptions = {
  primary: {
    main: '#00CCFF',
  },
  secondary: {
    main: '#001830',
  },
  kortxAccentBlue: {
    light: '#EAECFC',
    main: '#6672E8',
  },
  kortxAccentGreen: {
    light: '#E0F9ED',
    main: '#09D898',
  },
  kortxAccentMagenta: {
    light: '#F7E2ED',
    main: '#D63384',
  },
  kortxAccentYellow: {
    light: '#FFF8E0',
    main: '#FECD00',
  },
};

export const lightPalette = createPalette(paletteOptions);

export const darkPalette = createPalette({
  mode: 'dark',
  ...paletteOptions,
});

export const pieChartPalette: string[] = [
  '#00CCFF', // light blue
  '#6366F1', // purple
  '#FBBF24', // yellow
  '#4ADE80', // aqua
  '#334659', // dark blue
  '#D946EF', // magenta
  '#F97316', // orange
];
