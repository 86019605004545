import Box from '@mui/material/Box';
import KampusBadge from 'components/shared/KampusBadge';

export type KampusDrawerHeaderProps = {
  mini?: boolean;
};

function KampusDrawerHeader({ mini = false }: KampusDrawerHeaderProps) {
  return (
    <Box
      sx={{
        height: '4rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: (theme) => theme.spacing(1.5),
        paddingX: (theme) => theme.spacing(3),
      }}
    >
      <KampusBadge size="small" showLogotype={!mini} />
    </Box>
  );
}

export default KampusDrawerHeader;
