import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSelectedAdvertiserById } from 'features/advertiser/async';
import { useAppDispatch } from 'store/hooks';

function WithSelectedAdvertiser({ children }: React.PropsWithChildren) {
  const dispatch = useAppDispatch();

  const { clientId: clientIdAsParam, advertiserId: advertiserIdAsParam } =
    useParams();

  useEffect(() => {
    if (advertiserIdAsParam && clientIdAsParam) {
      const clientId = parseInt(clientIdAsParam, 10);
      const advertiserId = parseInt(advertiserIdAsParam, 10);

      dispatch(
        getSelectedAdvertiserById({
          clientId: clientId,
          id: advertiserId,
        })
      );
    }
  }, [clientIdAsParam, advertiserIdAsParam, dispatch]);

  return <>{children}</>;
}

export default WithSelectedAdvertiser;
