import React from 'react';
import { MagicBellProvider } from '@magicbell/react-headless';
import config from 'libs/config';

export type KampusMagicBellProviderTestProps = React.PropsWithChildren & {
  userEmail?: string;
  userKey?: string;
};

export default function KampusMagicBellProvider({
  userEmail,
  userKey,
  children,
}: KampusMagicBellProviderTestProps) {
  const { apiKey } = config.magicBell;

  return (
    <MagicBellProvider
      apiKey={apiKey}
      userEmail={userEmail?.toLowerCase()}
      userKey={userKey}
    >
      <>{children}</>
    </MagicBellProvider>
  );
}
