/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import {
  getAllCampaigns,
  getAllCampaignsByStatusIn,
  getAllCampaignsByAdvertiserId,
  getAllCampaignsByAdvertiserIdAndPlatformIn,
  getCampaignMetrics,
  getRequestForProposalForms,
  getAllCampaignsUsingTableRowProjection,
  getAllCampaignsUsingTableRowProjectionByStatusIn,
  getAllCampaignsByAdvertiserIdUsingTableRowProjection,
} from './async';
import { CampaignsState } from './types';

export const initialState: CampaignsState = {
  campaigns: {
    data: undefined,
    request: initialRequestState,
  },
  campaignsByStatusIn: {
    data: undefined,
    request: initialRequestState,
  },
  campaignsByAdvertiserId: {
    data: undefined,
    request: initialRequestState,
  },
  campaignsByAdvertiserIdAndPlatformIn: {
    data: undefined,
    request: initialRequestState,
  },
  campaignsUsingTableRowProjection: {
    data: undefined,
    request: initialRequestState,
  },
  campaignsByStatusInUsingTableRowProjection: {
    data: undefined,
    request: initialRequestState,
  },
  campaignsByAdvertiserIdUsingTableRowProjection: {
    data: undefined,
    request: initialRequestState,
  },
  metrics: {
    data: undefined,
    request: initialRequestState,
  },
  requestForProposalForms: {
    count: undefined,
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    searchForCampaignsOrRequestForProposalForms: undefined,
    searchForCampaignsByAdvertiserId: undefined,
  },
};
const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setSearchForCampaignsOrRequestForProposalForms(state, { payload }) {
      state.ui.searchForCampaignsOrRequestForProposalForms = payload;
    },
    setSearchForCampaignsByAdvertiserId(state, { payload }) {
      state.ui.searchForCampaignsByAdvertiserId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCampaignMetrics.fulfilled,
      (state, { meta, payload }) => {
        if (state.metrics.request.id === meta.requestId) {
          state.metrics.data = payload;
          state.metrics.request = requestReducer(
            state.metrics.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getCampaignMetrics.pending, (state, { meta }) => {
      state.metrics.data = undefined;
      state.metrics.request = requestReducer(
        state.metrics.request,
        pending({ meta })
      );
    });
    builder.addCase(getCampaignMetrics.rejected, (state, { meta }) => {
      state.metrics.data = undefined;
      state.metrics.request = requestReducer(
        state.metrics.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllCampaigns.fulfilled,
      (state, { meta, payload: campaigns }) => {
        if (state.campaigns.request.id === meta.requestId) {
          state.campaigns.data = campaigns;
          state.campaigns.request = requestReducer(
            state.campaigns.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getAllCampaigns.pending, (state, { meta }) => {
      state.campaigns.data = undefined;
      state.campaigns.request = requestReducer(
        state.campaigns.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllCampaigns.rejected, (state, { meta }) => {
      state.campaigns.data = undefined;
      state.campaigns.request = requestReducer(
        state.campaigns.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllCampaignsByStatusIn.fulfilled,
      (state, { meta, payload: campaigns }) => {
        if (state.campaignsByStatusIn.request.id === meta.requestId) {
          state.campaignsByStatusIn.data = campaigns;
          state.campaignsByStatusIn.request = requestReducer(
            state.campaignsByStatusIn.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getAllCampaignsByStatusIn.pending, (state, { meta }) => {
      state.campaignsByStatusIn.data = undefined;
      state.campaignsByStatusIn.request = requestReducer(
        state.campaignsByStatusIn.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllCampaignsByStatusIn.rejected, (state, { meta }) => {
      state.campaignsByStatusIn.data = undefined;
      state.campaignsByStatusIn.request = requestReducer(
        state.campaignsByStatusIn.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllCampaignsByAdvertiserId.fulfilled,
      (state, { meta, payload }) => {
        if (state.campaignsByAdvertiserId.request.id === meta.requestId) {
          state.campaignsByAdvertiserId.data = payload;
          state.campaignsByAdvertiserId.request = requestReducer(
            state.campaignsByAdvertiserId.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserId.pending,
      (state, { meta }) => {
        state.campaignsByAdvertiserId.data = undefined;
        state.campaignsByAdvertiserId.request = requestReducer(
          state.campaignsByAdvertiserId.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserId.rejected,
      (state, { meta }) => {
        state.campaignsByAdvertiserId.data = undefined;
        state.campaignsByAdvertiserId.request = requestReducer(
          state.campaignsByAdvertiserId.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserIdAndPlatformIn.fulfilled,
      (state, { meta, payload: campaigns }) => {
        if (
          state.campaignsByAdvertiserIdAndPlatformIn.request.id ===
          meta.requestId
        ) {
          state.campaignsByAdvertiserIdAndPlatformIn.data = campaigns;
          state.campaignsByAdvertiserIdAndPlatformIn.request = requestReducer(
            state.campaignsByAdvertiserIdAndPlatformIn.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserIdAndPlatformIn.pending,
      (state, { meta }) => {
        state.campaignsByAdvertiserIdAndPlatformIn.data = undefined;
        state.campaignsByAdvertiserIdAndPlatformIn.request = requestReducer(
          state.campaignsByAdvertiserIdAndPlatformIn.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserIdAndPlatformIn.rejected,
      (state, { meta }) => {
        state.campaignsByAdvertiserIdAndPlatformIn.data = undefined;
        state.campaignsByAdvertiserIdAndPlatformIn.request = requestReducer(
          state.campaignsByAdvertiserIdAndPlatformIn.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(
      getAllCampaignsUsingTableRowProjection.fulfilled,
      (state, { meta, payload: campaigns }) => {
        if (
          state.campaignsUsingTableRowProjection.request.id === meta.requestId
        ) {
          state.campaignsUsingTableRowProjection.data = campaigns;
          state.campaignsUsingTableRowProjection.request = requestReducer(
            state.campaignsUsingTableRowProjection.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      getAllCampaignsUsingTableRowProjection.pending,
      (state, { meta }) => {
        state.campaignsUsingTableRowProjection.data = undefined;
        state.campaignsUsingTableRowProjection.request = requestReducer(
          state.campaignsUsingTableRowProjection.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      getAllCampaignsUsingTableRowProjection.rejected,
      (state, { meta }) => {
        state.campaignsUsingTableRowProjection.data = undefined;
        state.campaignsUsingTableRowProjection.request = requestReducer(
          state.campaignsUsingTableRowProjection.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(
      getAllCampaignsUsingTableRowProjectionByStatusIn.fulfilled,
      (state, { meta, payload: campaigns }) => {
        if (
          state.campaignsByStatusInUsingTableRowProjection.request.id ===
          meta.requestId
        ) {
          state.campaignsByStatusInUsingTableRowProjection.data = campaigns;
          state.campaignsByStatusInUsingTableRowProjection.request =
            requestReducer(
              state.campaignsByStatusInUsingTableRowProjection.request,
              fulfilled({ meta })
            );
        }
      }
    );

    builder.addCase(
      getAllCampaignsUsingTableRowProjectionByStatusIn.pending,
      (state, { meta }) => {
        state.campaignsByStatusInUsingTableRowProjection.data = undefined;
        state.campaignsByStatusInUsingTableRowProjection.request =
          requestReducer(
            state.campaignsByStatusInUsingTableRowProjection.request,
            pending({ meta })
          );
      }
    );

    builder.addCase(
      getAllCampaignsUsingTableRowProjectionByStatusIn.rejected,
      (state, { meta }) => {
        state.campaignsByStatusInUsingTableRowProjection.data = undefined;
        state.campaignsByStatusInUsingTableRowProjection.request =
          requestReducer(
            state.campaignsByStatusInUsingTableRowProjection.request,
            rejected({ meta })
          );
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserIdUsingTableRowProjection.fulfilled,
      (state, { meta, payload: campaigns }) => {
        if (
          state.campaignsByAdvertiserIdUsingTableRowProjection.request.id ===
          meta.requestId
        ) {
          state.campaignsByAdvertiserIdUsingTableRowProjection.data = campaigns;
          state.campaignsByAdvertiserIdUsingTableRowProjection.request =
            requestReducer(
              state.campaignsByAdvertiserIdUsingTableRowProjection.request,
              fulfilled({ meta })
            );
        }
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserIdUsingTableRowProjection.pending,
      (state, { meta }) => {
        state.campaignsByAdvertiserIdUsingTableRowProjection.data = undefined;
        state.campaignsByAdvertiserIdUsingTableRowProjection.request =
          requestReducer(
            state.campaignsByAdvertiserIdUsingTableRowProjection.request,
            pending({ meta })
          );
      }
    );

    builder.addCase(
      getAllCampaignsByAdvertiserIdUsingTableRowProjection.rejected,
      (state, { meta }) => {
        state.campaignsByAdvertiserIdUsingTableRowProjection.data = undefined;
        state.campaignsByAdvertiserIdUsingTableRowProjection.request =
          requestReducer(
            state.campaignsByAdvertiserIdUsingTableRowProjection.request,
            rejected({ meta })
          );
      }
    );

    builder.addCase(
      getRequestForProposalForms.fulfilled,
      (state, { meta, payload: requestForProposalForms }) => {
        if (state.requestForProposalForms.request.id === meta.requestId) {
          const formCountChanged =
            requestForProposalForms && Array.isArray(requestForProposalForms);

          if (formCountChanged) {
            state.requestForProposalForms.count =
              requestForProposalForms.length;
          }

          state.requestForProposalForms.data = requestForProposalForms;
          state.requestForProposalForms.request = requestReducer(
            state.requestForProposalForms.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getRequestForProposalForms.pending, (state, { meta }) => {
      state.requestForProposalForms.data = undefined;
      state.requestForProposalForms.request = requestReducer(
        state.requestForProposalForms.request,
        pending({ meta })
      );
    });

    builder.addCase(getRequestForProposalForms.rejected, (state, { meta }) => {
      state.requestForProposalForms.count = undefined;
      state.requestForProposalForms.data = undefined;
      state.requestForProposalForms.request = requestReducer(
        state.requestForProposalForms.request,
        rejected({ meta })
      );
    });
  },
});

const { actions, reducer } = slice;

export const {
  setSearchForCampaignsOrRequestForProposalForms,
  setSearchForCampaignsByAdvertiserId,
} = actions;

export default reducer;
