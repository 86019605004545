import Badge from '@mui/material/Badge';
import { BellOutline } from 'mdi-material-ui';

export type MagicBellInboxIconProps = {
  badgeContent: number;
};

export default function MagicBellInboxIcon({
  badgeContent,
}: MagicBellInboxIconProps) {
  return (
    <Badge badgeContent={badgeContent}>
      <BellOutline sx={{ m: 0.5 }} />
    </Badge>
  );
}
