export const defaultErrorMessage = 'Kampus Error. Please contact support.';

export const defaultNetworkConnectionErrorMessage =
  'Network Error. Please check internet connection and try again.';

export interface ErrorWithUiMessage extends Error {
  uiMessage: string;
  uiFieldErrorMessages?: string[];
}

export const isErrorWithUiMessage = (error: Error): boolean => {
  return 'uiMessage' in error;
};
