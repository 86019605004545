import { useMediaQuery, useTheme } from '@mui/material';

export default function useKampusAppBarHeight(): number {
  const {
    mixins: { toolbar: toolbarMixin },
    breakpoints,
  } = useTheme();

  const toolbarDesktopQuery = breakpoints.up('sm');
  const toolbarLandscapeQuery = `${breakpoints.up(
    'xs'
  )} and (orientation: landscape)`;

  const isDesktop = useMediaQuery(toolbarDesktopQuery);
  const isLandscape = useMediaQuery(toolbarLandscapeQuery);

  const toolbar =
    toolbarMixin[
      isDesktop ? toolbarDesktopQuery : isLandscape ? toolbarLandscapeQuery : ''
    ] ?? toolbarMixin;

  return (toolbar as { minHeight: number })?.minHeight ?? 0;
}
