import { LazyRouteFunction, NonIndexRouteObject } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isModuleNotFoundError(error: any): boolean {
  return (
    typeof error?.message === 'string' &&
    (/Failed to fetch dynamically imported module/.test(error.message) ||
      /error loading dynamically imported module/.test(error.message) ||
      /'text\/html' is not a valid JavaScript MIME type/.test(error.message))
  );
}

// https://github.com/TanStack/router/blob/main/packages/react-router/src/lazyRouteComponent.tsx
// https://tanstack.com/router/v1/docs/guide/code-splitting
export function lazyRouteFunction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  TKey extends keyof T = 'default'
>(
  importer: () => Promise<T>,
  exportName?: TKey
): LazyRouteFunction<NonIndexRouteObject> {
  return async () => {
    try {
      const moduleExports = await importer();

      const Page = moduleExports[exportName ?? 'default'];

      return {
        Component: Page,
      };
    } catch (error) {
      if (
        error instanceof Error &&
        isModuleNotFoundError(error) &&
        typeof window !== 'undefined' &&
        typeof sessionStorage !== 'undefined'
      ) {
        const sessionsStorageKey = `react_router_reload:${error.message}`;

        if (!sessionStorage.getItem(sessionsStorageKey)) {
          sessionStorage.setItem(sessionsStorageKey, 'true');
          window.location.reload();

          // Return empty component while we wait for window to reload
          return {
            Component: null,
          };
        }
      }

      throw error;
    }
  };
}
