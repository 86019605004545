/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import { submitChatMessage } from 'features/assistant/async';
import { ChatbotState, ChatMessageSources } from 'features/assistant/types';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import { v4 as uuidv4 } from 'uuid';

export const initialState: ChatbotState = {
  messages: [
    {
      id: uuidv4(),
      text: `Hi! I'm Tex, your AI-powered assistant capable of delivering personalized campaign insights. Feel free to ask me any questions you might have.`,
      source: ChatMessageSources.AI,
    },
  ],
  promptRequest: {
    data: undefined,
    request: initialRequestState,
  },
};
const slice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    resetChatbotMessages(state) {
      state.messages = [
        {
          id: uuidv4(),
          text: `Hi! I'm Tex, your AI-powered assistant capable of delivering personalized campaign insights. Feel free to ask me any questions you might have.`,
          source: ChatMessageSources.AI,
        },
      ];
      state.promptRequest = {
        data: undefined,
        request: initialRequestState,
      };
    },
    addUserChatbotMessage(state, { payload }: { payload: string }) {
      state.messages.push({
        id: uuidv4(),
        text: payload,
        source: ChatMessageSources.User,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitChatMessage.fulfilled, (state, { meta, payload }) => {
      if (state.promptRequest.request.id === meta.requestId) {
        state.promptRequest.data = payload;
        state.promptRequest.request = requestReducer(
          state.promptRequest.request,
          fulfilled({ meta })
        );

        state.messages.push({
          id: uuidv4(),
          text: payload.response,
          source: ChatMessageSources.AI,
        });
      }
    });
    builder.addCase(submitChatMessage.pending, (state, { meta }) => {
      state.promptRequest.data = undefined;
      state.promptRequest.request = requestReducer(
        state.promptRequest.request,
        pending({ meta })
      );
    });
    builder.addCase(submitChatMessage.rejected, (state, { meta }) => {
      state.promptRequest.data = undefined;
      state.promptRequest.request = requestReducer(
        state.promptRequest.request,
        rejected({ meta })
      );

      state.messages.push({
        id: uuidv4(),
        text: "I'm sorry, I can't seem to answer that right now. Please try again.",
        source: ChatMessageSources.AI,
      });
    });
  },
});

const { actions, reducer } = slice;

export const { resetChatbotMessages, addUserChatbotMessage } = actions;

export default reducer;
