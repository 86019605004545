import {
  StandardUserWithMetadataDto,
  UserWithMetadataDto,
  UserWithMetadataDtoTypeEnum,
} from '@kortxio/hub-api';
import { errorHandler } from 'libs/error';

// Validates two passwords are the same and are long enough.
export const isValidPasswordPair = (
  password: string,
  passwordConfirmation: string
) => {
  return (
    password === passwordConfirmation &&
    password.match(/[A-Z]/) &&
    password.match(/[0-9]/) &&
    password.match(/[a-z]/) &&
    password.match(/[^a-z0-9]/i) &&
    password.length >= 8
  );
};

// errors from cognito are pretty much 'any'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toLoginError(error: any) {
  switch (error.name) {
    case 'NotAuthorizedException':
    case 'UserNotFoundException':
      return {
        title: 'Login failed',
        detail: 'Incorrect username or password',
      };
    case 'LimitExceededException':
      return {
        title: 'Too many attempts',
        detail:
          'The attempt limit was exceeded. Wait a few hours and try again.',
      };
    default:
      errorHandler.handle(error);

      return {
        title: 'Unexpected Error',
        detail: 'Something went wrong on our side. Please try again later',
      };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toPasswordRecoveryError(error: any) {
  switch (error.name) {
    case 'LimitExceededException':
      return {
        title: 'Too many attempts',
        detail:
          'The attempt limit was exceeded. Wait a few hours and try again.',
      };
    default:
      return {
        title: 'Password reset failed',
        detail: "Make sure you're using the last code you received.",
      };
  }
}

const newPasswordTitledError = {
  title: 'New password procedure failed',
  detail: 'We apologize, something went wrong on our end.',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toNewPasswordError(error: any) {
  switch (error.name) {
    case 'NotAuthorizedException': {
      return {
        title: "Temporary password doesn't match our records",
        detail: 'Try again later',
      };
    }
    case 'InvalidParameterException': {
      errorHandler.handle(error);

      return newPasswordTitledError;
    }
    default: // InvalidParameterException
      return newPasswordTitledError;
  }
}

export function loginRedirectUrl(
  user: UserWithMetadataDto,
  requestedUrl?: string
) {
  const { type, termsOfUseAccepted } = user;

  // We should do this with pure permissions one day, but for now we can hardcode it
  // Should any other role have any "preferences" related to where to land after login
  // we should have something more structured here...
  const isReportingOnly = user?.role === 'REPORTING_ONLY';

  // Reporting only can't specify a requested URL, they can only see reporting after all...
  if (requestedUrl && !isReportingOnly) {
    return requestedUrl;
  }

  if (!requestedUrl) {
    // client decomposition out here prevents https://eslint.org/docs/latest/rules/no-case-declarations
    const { client } = user as StandardUserWithMetadataDto;
    switch (type) {
      case UserWithMetadataDtoTypeEnum.Internal:
        return '/client-select';
      case UserWithMetadataDtoTypeEnum.Standard:
        if (client) {
          if (isReportingOnly) {
            return `/clients/${client.id}/reporting-suite`;
          }
          return `/clients/${client.id}/home`;
        }
        // shouldn't happen but just in case...
        return '/profile';
      default:
        return '/profile';
    }
  }

  return termsOfUseAccepted ? requestedUrl : '/terms-of-use';
}
