import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginRedirectUrl } from 'features/auth/functions';
import { userSelector } from 'features/user/selectors';
import { base64ToUrl } from 'libs/string';
import { useAppSelector } from 'store/hooks';

export default function Index() {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const user = useAppSelector(userSelector);

  useEffect(() => {
    if (user) {
      // If string is not undefined it contained a requested redirect:
      // - requested from the user
      // - internal user menu
      // - terms of services
      // When undefined we should find the client id and redirect to home.
      const redirectUrl = loginRedirectUrl(
        user,
        base64ToUrl(hash.substring(1))
      );
      navigate(redirectUrl);
    }
  }, [hash, navigate, user]);

  return null;
}
