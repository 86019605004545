import { ReactNode } from 'react';
import Button from '@mui/material/Button';
import MagicBellInboxIcon from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInboxIcon';

export default function EmptyMagicBellInbox({ text }: { text?: ReactNode }) {
  return (
    <Button
      id="empty-otification-menu-button"
      size="large"
      sx={{
        justifyContent: 'start',
        color: 'inherit',
        flex: 1,
      }}
    >
      <MagicBellInboxIcon badgeContent={0} />
      {text !== undefined && text}
    </Button>
  );
}
