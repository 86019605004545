import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'features/auth/UseAuth';
import { getTeamByClientIdWithUpdates } from 'features/client/async';
import { selectedClientSelector } from 'features/client/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

function WithTeam({ children }: React.PropsWithChildren) {
  const location = useLocation();

  const { pathname } = location;

  const dispatch = useAppDispatch();

  const { user: authUser } = useAuth();

  const hasAuth = authUser !== undefined;

  const selectedClient = useAppSelector(selectedClientSelector);

  const { id: selectedClientId } = selectedClient ?? {};

  useEffect(() => {
    if (hasAuth && selectedClientId) {
      dispatch(getTeamByClientIdWithUpdates({ clientId: selectedClientId }));
    }
  }, [hasAuth, selectedClientId, pathname, dispatch]);

  return <>{children}</>;
}

export default WithTeam;
