import { Theme, useMediaQuery } from '@mui/material';
import MiniVariantDrawer from './MiniVariantDrawer';
import TemporaryDrawer from './TemporaryDrawer';

function KampusDrawer() {
  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  return (
    <>
      {matchesUpMd && <MiniVariantDrawer />}
      {!matchesUpMd && <TemporaryDrawer />}
    </>
  );
}

export default KampusDrawer;
