import { createAsyncThunk } from '@reduxjs/toolkit';
import { InvoiceDtoWithMetadata, InvoiceMetricsDto } from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { blobToBlobURL } from 'libs/media';
import { AsyncThunkConfig } from 'store/types';

export const getAllInvoices = createAsyncThunk<
  InvoiceDtoWithMetadata[],
  { clientId: number; advertiserIds?: number[] },
  AsyncThunkConfig
>(
  'invoice/getAllInvoices',
  async ({ clientId, advertiserIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.invoice.getAllInvoicesWithMetadataByClientId(
        clientId,
        advertiserIds
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const downloadInvoiceFileById = createAsyncThunk<
  string,
  { clientId: number; id: string },
  AsyncThunkConfig
>(
  'invoice/downloadInvoiceFileById',
  async ({ clientId, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.invoice.downloadInvoiceFileById(clientId, id, {
        responseType: 'blob',
      });

      const payload = parseApiPayload(response);

      return blobToBlobURL(payload, 'application/pdf');
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getInvoicesMetrics = createAsyncThunk<
  InvoiceMetricsDto,
  { clientId: number; advertiserIds?: number[] },
  AsyncThunkConfig
>(
  'invoice/getInvoicesMetrics',
  async ({ clientId, advertiserIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.invoice.getInvoiceMetricsByClientId(
        clientId,
        advertiserIds
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
