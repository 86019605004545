import { useContext, createContext } from 'react';

export type LayoutContextProps = {
  isTemporaryDrawerOpen: boolean;
  setIsTemporaryDrawerOpen: (isTemporaryDrawerOpen: boolean) => void;
};

export const LayoutContext = createContext<LayoutContextProps | undefined>(
  undefined
);

export const useLayoutContext = (): LayoutContextProps => {
  const context = useContext(LayoutContext);

  if (context === undefined) {
    throw new Error(
      'useLayoutContext must be used within a LayoutContextProvider'
    );
  }

  return context;
};
