import { Theme } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import { alpha, Components } from '@mui/material/styles';
import LinkBehavior from 'theme/components/LinkBehavior';

export default {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontVariantNumeric: 'tabular-nums',
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        minWidth: 35, // so we can have square buttons
        borderRadius: '0.375rem',
        textTransform: 'none',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '0.75rem',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.grey[300]}`,
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
        textDecorationColor: alpha(theme.palette.secondary.main, 0.4),
      }),
    },
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        borderRadius: '0.375rem',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: '0.375rem',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '0.375rem',
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        kampus: 'h1',
      },
    },
  },
} as Components<Theme>;
