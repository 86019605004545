import React from 'react';
import { Box, BoxProps } from '@mui/material';

export type ColumnsBoxProps<
  D extends React.ElementType = 'div',
  P = unknown
> = BoxProps<D, P>;

const Columns: React.FC<ColumnsBoxProps> = (props) => {
  const { sx: otherSx, ...otherProps } = props;
  return (
    <Box
      sx={{ ...otherSx, display: 'flex', flexDirection: 'row' }}
      {...otherProps}
    />
  );
};

export default Columns;
