import { createSelector } from '@reduxjs/toolkit';
import { NotificationsState } from 'features/notification/types';
import { RootState } from 'store/types';

export const notificationRootSelector = (
  state: RootState
): NotificationsState => state.notification;

export const magicBellHmacSelector = createSelector(
  notificationRootSelector,
  (notificationRoot: NotificationsState): string | undefined =>
    notificationRoot.magicBellHmac
);
