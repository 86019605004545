import { Theme, useMediaQuery, useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { drawerListItemStyles } from 'layout/components/drawer/drawerListItemStyles';
import ProfileMenu from 'layout/components/drawer/ProfileMenu';

export default function ProfileMenuDrawerListItem() {
  const theme = useTheme();

  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  const classes = drawerListItemStyles(theme);

  const showTooltip = Boolean(matchesUpMd);

  const showListItemText = Boolean(!matchesUpMd);

  return (
    <ListItem disablePadding sx={classes.listItem}>
      <Stack
        direction="row"
        sx={{
          ...classes.listItemButton,
        }}
      >
        <ListItemIcon
          sx={{ ...classes.listItemIcon, flex: 1, justifyContent: 'start' }}
        >
          <ProfileMenu
            text={
              showListItemText && (
                <ListItemText
                  sx={{ ...classes.listItemText, flexGrow: 0 }}
                  primary="Profile"
                />
              )
            }
            tooltipText={showTooltip ? 'Profile' : undefined}
            textFieldPlaceholder="Switch to client..."
          />
        </ListItemIcon>
      </Stack>
    </ListItem>
  );
}
