import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  CognitoNoAuthSessionError,
  defaultCognitoNoAuthSessionErrorMessage,
} from 'features/auth/cognitoFunctions';
import { defaultErrorMessage } from 'libs/error/types';
import { toTitleCase } from 'libs/string';
import { ApiError } from './types';

export const parseApiPayload = (response: AxiosResponse) => {
  if (!response) {
    return undefined;
  }

  return response.data;
};

export const parseApiHeader = (
  headerName: string,
  response: AxiosResponse
): string | undefined => {
  if (!response) {
    return undefined;
  }

  const { headers } = response;

  if (!headers) {
    return undefined;
  }

  return headers[headerName];
};

export const parseApiStatusCode = (
  response: AxiosResponse | undefined
): number | undefined => {
  if (!response) {
    return undefined;
  }

  const { status } = response;

  if (!status) {
    return undefined;
  }

  return status;
};

export const parseApiStatusCodeFamily = (
  status: number | undefined
):
  | 'INFORMATIONAL'
  | 'SUCCESSFUL'
  | 'REDIRECTION'
  | 'CLIENT_ERROR'
  | 'SERVER_ERROR'
  | 'OTHER'
  | undefined => {
  if (!status) {
    return undefined;
  }

  switch (Math.floor(status / 100)) {
    case 1:
      return 'INFORMATIONAL';
    case 2:
      return 'SUCCESSFUL';
    case 3:
      return 'REDIRECTION';
    case 4:
      return 'CLIENT_ERROR';
    case 5:
      return 'SERVER_ERROR';
    default:
      return 'OTHER';
  }
};

export const isAbortedError = (error: Error): boolean => {
  if (axios.isCancel(error)) {
    return true;
  }

  const { message } = error || {};

  return message === 'Request aborted';
};

export const isNetworkError = (error: Error): boolean => {
  const { message } = error || {};

  return message === 'Network Error';
};

export const isApiError = (error: Error): boolean => {
  if (!error) {
    return false;
  }

  const isAxiosError = axios.isAxiosError(error);

  if (!isAxiosError) {
    return false;
  }

  const { response } = error;

  return Boolean(response);
};

export const isApi400Error = (error: Error) => {
  const isAxiosError = axios.isAxiosError(error);

  if (!isAxiosError) {
    return false;
  }

  const { response } = error;

  const status = parseApiStatusCode(response);
  const statusFamily = parseApiStatusCodeFamily(status);

  return statusFamily === 'CLIENT_ERROR';
};

export const isApiHttpError = (error: Error, test: number) => {
  const isAxiosError = axios.isAxiosError(error);

  if (!isAxiosError) {
    return false;
  }

  const { response } = error;

  const status = parseApiStatusCode(response);
  return status === test;
};

export const toApiFieldErrorsUiMessage = (
  response: AxiosResponse | undefined
): string[] | undefined => {
  if (!response) {
    return undefined;
  }

  const { data } = response;

  const { fields = undefined } = data;

  const hasFieldErrors = fields != undefined && Array.isArray(fields);

  if (!hasFieldErrors) {
    return undefined;
  }

  return fields.map((field) => {
    const { fieldName = undefined, message = undefined } = field ?? {};

    const hasFieldName = fieldName != undefined;
    const hasMessage = message != undefined;

    if (!hasFieldName && !hasMessage) {
      return undefined;
    }

    if (!hasFieldName) {
      return message;
    }

    if (!hasMessage) {
      return fieldName;
    }

    return `${toTitleCase(fieldName)} ${message}.`;
  });
};

export const toApiErrorUiMessage = (
  response: AxiosResponse | undefined
): string => {
  if (!response) {
    return defaultErrorMessage;
  }

  const { data, status } = response;

  const { error, message = '' } = data;

  if (error) {
    return `${status} - ${error}`;
  }

  if (message) {
    return `${status} - ${message}`;
  }

  return `${status} - Please contact support.`;
};

export const toApiError = (error: unknown): ApiError => {
  const isError = error instanceof Error;

  if (!isError) {
    return {
      name: '',
      message: '',
      response: undefined,
      uiMessage: defaultErrorMessage,
      uiFieldErrorMessages: undefined,
      isAbortedError: false,
      isApiError: false,
      isApi400Error: false,
      isApi404Error: false,
    };
  }

  const isCognitoNoAuthSessionError =
    error instanceof CognitoNoAuthSessionError;

  if (isCognitoNoAuthSessionError) {
    return {
      name: '',
      message: '',
      response: undefined,
      uiMessage: defaultCognitoNoAuthSessionErrorMessage,
      uiFieldErrorMessages: undefined,
      isAbortedError: false,
      isApiError: false,
      isApi400Error: false,
      isApi404Error: false,
    };
  }

  const isNetwork = isNetworkError(error);

  if (isNetwork) {
    return {
      name: '',
      message: '',
      response: undefined,
      uiMessage:
        'Network Error. Please check internet connection and try again.',
      uiFieldErrorMessages: undefined,
      isAbortedError: false,
      isApiError: false,
      isApi400Error: false,
      isApi404Error: false,
    };
  }

  const isAborted = isAbortedError(error);
  const isApi = isApiError(error);
  const isApi400 = isApi ? isApi400Error(error) : false;
  const isApi404 = isApi ? isApiHttpError(error, 404) : false;

  if (!isApi) {
    return {
      name: error.name,
      message: error.message,
      response: undefined,
      uiMessage: defaultErrorMessage,
      uiFieldErrorMessages: undefined,
      isAbortedError: isAborted,
      isApiError: isApi,
      isApi400Error: isApi400,
      isApi404Error: isApi404,
    };
  }

  const axiosError = error as AxiosError;

  return {
    name: axiosError.name,
    message: axiosError.message,
    response:
      axiosError?.response?.data instanceof Blob
        ? { status: axiosError?.response?.status, data: undefined }
        : {
            status: axiosError?.response?.status,
            data: axiosError?.response?.data,
          },
    uiMessage: toApiErrorUiMessage(axiosError.response),
    uiFieldErrorMessages: toApiFieldErrorsUiMessage(axiosError.response),
    isAbortedError: isAborted,
    isApiError: isApi,
    isApi400Error: isApi400,
    isApi404Error: isApi404,
  };
};

export const toFetchApiErrorUiMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any | undefined
): string => {
  if (!response) {
    return defaultErrorMessage;
  }

  const { status, error, message = '' } = response;

  if (error) {
    return `${status} - ${error}`;
  }

  if (message) {
    return `${status} - ${message}`;
  }

  return `${status} - Please contact support.`;
};
