import React from 'react';
import { Alert, ThemeProvider, Typography } from '@mui/material';
import { darkTheme } from 'theme';
import { TitledMessage } from 'features/auth/types';

export type LoginErrorAlertProps = {
  error: TitledMessage;
};

const LoginErrorAlert: React.FC<LoginErrorAlertProps> = ({ error }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Alert severity="error" sx={{ display: 'flex', my: 2 }}>
        <Typography
          variant="body1"
          sx={{
            overflowWrap: 'break-word',
          }}
        >
          {error.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            overflow: 'wrap',
            overflowWrap: 'break-word',
          }}
        >
          {error.detail}
        </Typography>
      </Alert>
    </ThemeProvider>
  );
};

export default LoginErrorAlert;
