import { useParams } from 'react-router-dom';
import { CAMPAIGN_TAB, CampaignTab } from 'features/campaign/types';
import { selectedClientSelector } from 'features/client/selectors';
import { useAppSelector } from 'store/hooks';

type RouteOverrides = {
  advertiserId?: string | number;
  campaignId?: string | number;
  clientId?: string | number;
  campaignActiveTab?: CampaignTab;
  audienceId?: string | number;
  campaignReportingActiveTab?: string;
};

export const useKampusRoutes = (overrides?: RouteOverrides) => {
  const {
    advertiserId: advertiserIdAsParam,
    campaignId: campaignIdAsParam,
    clientId: clientIdAsParam,
    campaignActiveTab: campaignActiveTabAsParam,
    audienceId,
    activeTab: campaignReportingActiveTabAsParam,
  } = useParams();

  const client = useAppSelector(selectedClientSelector);
  const { id: selectedClientId } = client ?? {};

  const advertiserId = overrides?.advertiserId ?? advertiserIdAsParam;
  const campaignId = overrides?.campaignId ?? campaignIdAsParam;
  const clientId = overrides?.clientId ?? selectedClientId ?? clientIdAsParam;
  const campaignActiveTab =
    overrides?.campaignActiveTab ??
    campaignActiveTabAsParam ??
    CAMPAIGN_TAB.Overview;
  const campaignReportingActiveTab =
    overrides?.campaignReportingActiveTab ?? campaignReportingActiveTabAsParam;

  return {
    baseClientRoute: createBaseClientRoute(clientId),
    routeHome: createRouteToHome(clientId),
    routeAdvertisers: createRouteToAdvertisers(clientId),
    routeAdvertiserById: createRouteToAdvertiserById(clientId, advertiserId),
    routeAudiences: createRouteToAudiences(clientId),
    routeAudienceTaxonomy: createRouteToAudienceTaxonomy(
      clientId,
      advertiserId
    ),
    routeAudienceSegmentAnalysis: createRouteToAudienceSegmentAnalysis(
      clientId,
      advertiserId
    ),
    routeAudienceWebInsights: createRouteToAudienceWebInsights(
      clientId,
      advertiserId,
      audienceId
    ),
    routeAudienceAudienceComposition: createRouteToAudienceComposition(
      clientId,
      advertiserId,
      audienceId
    ),
    routeCampaigns: createRouteToCampaigns(clientId),
    routeCampaignById: createRouteToCampaignById(
      clientId,
      campaignId,
      campaignActiveTab
    ),
    routeReportingSuite: createRouteToReportingSuite(clientId),
    routeReportingSuiteById: createRouteToReportingSuiteById(
      clientId,
      advertiserId
    ),
    routeCampaignReporting: createRouteToCampaignReporting(
      clientId,
      advertiserId
    ),
    routeCampaignReportingById: createRouteToCampaignReportingById(
      clientId,
      advertiserId,
      campaignId
    ),
    routeCampaignReportingByActiveTab:
      createRouteToCampaignReportingByActiveTab(
        clientId,
        advertiserId,
        campaignId,
        campaignReportingActiveTab
      ),
    routeDataConnections: createRouteToReportingDataConnections(
      clientId,
      advertiserId
    ),
    routeAIAssistant: createRouteToAIAssistant(clientId, advertiserId),
    routeDashboards: createRouteToReportingDashboards(clientId, advertiserId),
    routeReportingSuiteBuilder: createRouteToReportingSuiteBuilder(
      clientId,
      advertiserId
    ),
    routeReportingSuiteBuilderSchedules:
      createRouteToReportingSuiteBuilderSchedules(clientId, advertiserId),
  };
};

export const createBaseClientRoute = (clientId?: string | number) => {
  return `/clients/${clientId}`;
};

export const createRouteToHome = (clientId?: string | number) => {
  return `/clients/${clientId}/home`;
};

export const createRouteToAdvertisers = (clientId?: string | number) => {
  return `/clients/${clientId}/advertisers`;
};

export const createRouteToAdvertiserById = (
  clientId?: string | number,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/advertisers/${advertiserId}`;
};
``;

export const createRouteToAudiences = (clientId?: number | string) => {
  return `/clients/${clientId}/audiences`;
};

export const createRouteToAudienceSegmentAnalysis = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/segment-analysis`;
};

export const createRouteToAudienceWebInsights = (
  clientId?: number | string,
  advertiserId?: number | string,
  audienceId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/segment-analysis/${audienceId}/web-insights`;
};

export const createRouteToAudienceComposition = (
  clientId?: number | string,
  advertiserId?: number | string,
  audienceId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/segment-analysis/${audienceId}/audience-composition`;
};

export const createRouteToAudienceTaxonomy = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/audiences/${advertiserId}/taxonomy`;
};

export const createRouteToCampaigns = (clientId?: string | number) => {
  return `/clients/${clientId}/campaigns`;
};

export const createRouteToCampaignById = (
  clientId?: string | number,
  campaignId?: number | string,
  tab?: string
) => {
  return `/clients/${clientId}/campaigns/${campaignId}/${tab}`;
};

export const createRouteToReportingSuite = (clientId?: number | string) => {
  return `/clients/${clientId}/reporting-suite`;
};

export const createRouteToReportingSuiteById = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  return `/clients/${clientId}/reporting-suite/${advertiserId}`;
};

export const createRouteToCampaignReporting = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteById(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/campaign-reporting`;
};

export const createRouteToCampaignReportingById = (
  clientId?: number | string,
  advertiserId?: number | string,
  campaignId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteById(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/campaign-reporting/${campaignId}`;
};

export const createRouteToCampaignReportingByActiveTab = (
  clientId?: number | string,
  advertiserId?: number | string,
  campaignId?: number | string,
  activeTab?: string
) => {
  const campaignReportingRoute = createRouteToCampaignReportingById(
    clientId,
    advertiserId,
    campaignId
  );

  return `${campaignReportingRoute}/${activeTab}`;
};

export const createRouteToReportingDataConnections = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteById(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/data-connections`;
};

export const createRouteToAIAssistant = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteById(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/assistant`;
};

export const createRouteToReportingDashboards = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteById(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/dashboards`;
};

export const createRouteToReportingSuiteBuilder = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteRoute = createRouteToReportingSuiteById(
    clientId,
    advertiserId
  );

  return `${reportingSuiteRoute}/builder`;
};

export const createRouteToReportingSuiteBuilderSchedules = (
  clientId?: number | string,
  advertiserId?: number | string
) => {
  const reportingSuiteBuilderRoute = createRouteToReportingSuiteBuilder(
    clientId,
    advertiserId
  );

  return `${reportingSuiteBuilderRoute}/schedules`;
};
