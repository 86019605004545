import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PageProductCategoryOutputDto,
  PageProductOutputDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getAllProducts = createAsyncThunk<
  PageProductOutputDto,
  void,
  AsyncThunkConfig
>('product/getAllProducts', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.product.getAllProducts();

    return parseApiPayload(response);
  } catch (error) {
    const apiError = toApiError(error);

    if (!apiError.isAbortedError) {
      dispatch(
        setError({ message: apiError.message, uiMessage: apiError.uiMessage })
      );
    }

    return rejectWithValue(apiError);
  }
});

export const getAllProductCategories = createAsyncThunk<
  PageProductCategoryOutputDto,
  void,
  AsyncThunkConfig
>(
  'product/getAllProductCategories',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.product.getAllProductCategories();

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
