import React from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import { drawerListItemStyles } from 'layout/components/drawer/drawerListItemStyles';

export type DrawerListItemButtonProps = {
  component?: React.ElementType;
} & ListItemButtonProps &
  RouterLinkProps &
  React.RefAttributes<HTMLAnchorElement>;

export type DrawerListItemProps = {
  text?: string;
  icon?: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  ListItemButtonProps?: DrawerListItemButtonProps;
} & ListItemProps;

const useStyles = makeStyles()((theme) => drawerListItemStyles(theme));

function DrawerListItem({
  selected = false,
  disabled = false,
  icon,
  text,
  ListItemButtonProps,
  ...props
}: DrawerListItemProps) {
  const { classes } = useStyles();

  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  const showTooltip = Boolean(text && matchesUpMd);

  const showListItemText = Boolean(text && !matchesUpMd);

  return (
    <ListItem disablePadding className={classes.listItem} {...props}>
      <Tooltip title={showTooltip ? text : null} placement="right">
        <ListItemButton
          selected={selected}
          disabled={disabled}
          className={classes.listItemButton}
          {...ListItemButtonProps}
        >
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
          {showListItemText && (
            <ListItemText className={classes.listItemText} primary={text} />
          )}
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}

export default DrawerListItem;
