import React from 'react';
import AuthContext from 'features/auth/AuthContext';

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('`useAuth` context missing');
  }

  return context;
};
