import { createSlice } from '@reduxjs/toolkit';
import {
  AudienceCountForDayDto,
  AudienceWithCountsDto,
  LookerReportOutputDto,
} from '@kortxio/hub-api';
import {
  getAudienceCountsHistoryById,
  getAllAudiences,
  getAllReportsForAudience,
  runQueryForAudience,
} from 'features/audiences/async';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
  RequestState,
} from 'features/shared/request';

export type AudienceReportQueryResult = {
  name: string;
  data: LookerReportOutputDto | undefined;
  request: RequestState;
};

export type AudiencesState = {
  audiences: {
    data: AudienceWithCountsDto[] | undefined;
    request: RequestState;
  };
  countsHistory: {
    data: AudienceCountForDayDto[] | undefined;
    request: RequestState;
  };
  reports: {
    data: LookerReportOutputDto[] | undefined;
    request: RequestState;
  };
  queryResults: AudienceReportQueryResult[] | undefined;
  selectedReport: LookerReportOutputDto | undefined;
};

export const initialState: AudiencesState = {
  audiences: {
    data: undefined,
    request: initialRequestState,
  },
  countsHistory: {
    data: undefined,
    request: initialRequestState,
  },
  reports: {
    data: undefined,
    request: initialRequestState,
  },
  selectedReport: undefined,
  queryResults: undefined,
};

const slice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    setSelectedReport(state, { payload: report }) {
      state.selectedReport = report;
      state.queryResults = undefined;
    },
    resetReports: (state) => {
      state.reports = {
        data: undefined,
        request: initialRequestState,
      };
      state.selectedReport = undefined;
      state.queryResults = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAudiences.fulfilled, (state, { meta, payload }) => {
      if (state.audiences.request.id === meta.requestId) {
        state.audiences.data = payload;
        state.audiences.request = requestReducer(
          state.audiences.request,
          fulfilled({ meta })
        );
      }
    });
    builder.addCase(getAllAudiences.pending, (state, { meta }) => {
      state.audiences.data = undefined;
      state.audiences.request = requestReducer(
        state.audiences.request,
        pending({ meta })
      );
    });
    builder.addCase(getAllAudiences.rejected, (state, { meta }) => {
      state.audiences.data = undefined;
      state.audiences.request = requestReducer(
        state.audiences.request,
        rejected({ meta })
      );
    });
    builder.addCase(
      getAudienceCountsHistoryById.fulfilled,
      (state, { meta, payload }) => {
        if (state.countsHistory.request.id === meta.requestId) {
          state.countsHistory.data = payload;
          state.countsHistory.request = requestReducer(
            state.countsHistory.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getAudienceCountsHistoryById.pending, (state, { meta }) => {
      state.countsHistory.data = undefined;
      state.countsHistory.request = requestReducer(
        state.countsHistory.request,
        pending({ meta })
      );
    });
    builder.addCase(
      getAudienceCountsHistoryById.rejected,
      (state, { meta }) => {
        state.countsHistory.data = undefined;
        state.countsHistory.request = requestReducer(
          state.countsHistory.request,
          rejected({ meta })
        );
      }
    );

    // Reporting

    builder.addCase(
      getAllReportsForAudience.fulfilled,
      (state, { meta, payload }) => {
        if (state.reports.request.id === meta.requestId) {
          state.reports.data = payload;
          state.reports.request = requestReducer(
            state.reports.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getAllReportsForAudience.pending, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        pending({ meta })
      );
    });
    builder.addCase(getAllReportsForAudience.rejected, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      runQueryForAudience.fulfilled,
      (state, { meta, payload }) => {
        const { arg } = meta || {};
        const { queryName } = arg || {};

        const queryResults =
          state.queryResults != undefined
            ? state.queryResults.filter((value) => value.name !== queryName)
            : [];

        const queryResultRequestForQueryName = state.queryResults?.find(
          (value) => value?.name === queryName
        )?.request;

        const queryResultsForQueryName = {
          name: queryName,
          data: payload,
          request: requestReducer(
            queryResultRequestForQueryName,
            fulfilled({ meta })
          ),
        };

        state.queryResults = [...queryResults, queryResultsForQueryName];
      }
    );

    builder.addCase(runQueryForAudience.pending, (state, { meta }) => {
      const { arg } = meta || {};
      const { queryName } = arg || {};

      const queryResults =
        state.queryResults != undefined
          ? state.queryResults.filter((value) => value.name !== queryName)
          : [];

      const queryResultRequestForQueryName = state.queryResults?.find(
        (value) => value?.name === queryName
      )?.request;

      const queryResultsForQueryName = {
        name: queryName,
        data: undefined,
        request: requestReducer(
          queryResultRequestForQueryName,
          pending({ meta })
        ),
      };

      state.queryResults = [...queryResults, queryResultsForQueryName];
    });

    builder.addCase(runQueryForAudience.rejected, (state, { meta }) => {
      const { arg } = meta || {};
      const { queryName } = arg || {};

      const queryResults =
        state.queryResults != undefined
          ? state.queryResults.filter((value) => value.name !== queryName)
          : [];

      const queryResultRequestForQueryName = state.queryResults?.find(
        (value) => value?.name === queryName
      )?.request;

      const queryResultsForQueryName = {
        name: queryName,
        data: undefined,
        request: requestReducer(
          queryResultRequestForQueryName,
          rejected({ meta })
        ),
      };

      state.queryResults = [...queryResults, queryResultsForQueryName];
    });
  },
});

const { reducer, actions } = slice;

export const {
  setSelectedReport: setAudienceSelectedReport,
  resetReports: resetAudienceReports,
} = actions;

export default reducer;
