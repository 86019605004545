import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'features/auth/UseAuth';
import { getMeWithUpdates } from 'features/user/async';
import { useAppDispatch } from 'store/hooks';

function WithMe({ children }: React.PropsWithChildren) {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { pathname } = location;

  const { user: authUser } = useAuth();

  const hasAuth = authUser !== undefined;

  useEffect(() => {
    if (hasAuth) {
      dispatch(getMeWithUpdates());
    }
  }, [hasAuth, pathname, dispatch]);

  return <>{children}</>;
}

export default WithMe;
