/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import {
  getAllAdvertisers,
  getAllAdvertisersUsingSummaryProjection,
} from './async';
import { AdvertiserState } from './types';

export const initialState: AdvertiserState = {
  advertisers: {
    data: undefined,
    request: initialRequestState,
  },
  advertisersUsingSummaryProjection: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    search: undefined,
  },
};
const slice = createSlice({
  name: 'advertiser',
  initialState,
  reducers: {
    setSearch(state, { payload }) {
      state.ui.search = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAdvertisers.fulfilled, (state, { meta, payload }) => {
      if (state.advertisers.request.id === meta.requestId) {
        state.advertisers.data = payload;
        state.advertisers.request = requestReducer(
          state.advertisers.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(getAllAdvertisers.pending, (state, { meta }) => {
      state.advertisers.data = undefined;
      state.advertisers.request = requestReducer(
        state.advertisers.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllAdvertisers.rejected, (state, { meta }) => {
      state.advertisers.data = undefined;
      state.advertisers.request = requestReducer(
        state.advertisers.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllAdvertisersUsingSummaryProjection.fulfilled,
      (state, { meta, payload }) => {
        if (
          state.advertisersUsingSummaryProjection.request.id === meta.requestId
        ) {
          state.advertisersUsingSummaryProjection.data = payload;
          state.advertisersUsingSummaryProjection.request = requestReducer(
            state.advertisersUsingSummaryProjection.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      getAllAdvertisersUsingSummaryProjection.pending,
      (state, { meta }) => {
        state.advertisersUsingSummaryProjection.data = undefined;
        state.advertisersUsingSummaryProjection.request = requestReducer(
          state.advertisersUsingSummaryProjection.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      getAllAdvertisersUsingSummaryProjection.rejected,
      (state, { meta }) => {
        state.advertisersUsingSummaryProjection.data = undefined;
        state.advertisersUsingSummaryProjection.request = requestReducer(
          state.advertisersUsingSummaryProjection.request,
          rejected({ meta })
        );
      }
    );
  },
});

const { actions, reducer } = slice;

export const { setSearch } = actions;

export default reducer;
