import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserWithMetadataDtoRoleEnum } from '@kortxio/hub-api';
import { roleSelector } from 'features/user/selectors';

type RequireRoleProps = {
  requiredRoles: UserWithMetadataDtoRoleEnum[];
  redirectTo?: string;
  fallback?: React.ReactNode;
} & React.PropsWithChildren;

/**
 * @deprecated use RequirePrivilege instead
 */
export default function RequireRole({
  requiredRoles,
  redirectTo,
  fallback,
  children,
}: RequireRoleProps) {
  const role = useSelector(roleSelector);

  const hasRole = useMemo(() => {
    if (role === undefined) {
      return false;
    }

    return requiredRoles.includes(role);
  }, [role, requiredRoles]);

  if (hasRole) {
    return <>{children}</>;
  }

  if (role !== undefined && redirectTo !== undefined) {
    return <Navigate to={redirectTo} replace />;
  }

  if (role !== undefined && fallback != undefined) {
    return <>{fallback}</>;
  }

  return <></>;
}
