import { SxProps, Theme } from '@mui/material';

export const darkFieldSx: SxProps<Theme> = {
  '& .MuiOutlinedInput-input': {
    color: 'white',
  },
  '& .MuiInputBase-input': {
    py: 1,
    px: 1.5,
  },
  '& .MuiFormHelperText-root': {
    color: 'white',
    ml: 0,
  },
  '& .MuiOutlinedInput-root': {
    background: (theme) => theme.palette.secondary.light,
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.25)',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderSize: '4px',
      borderColor: (theme) => theme.palette.primary.main,
    },
  },
};
