/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import {
  requestReducer,
  initialRequestState,
  fulfilled,
  pending,
  rejected,
} from 'features/shared/request';
import { getAllProducts, getAllProductCategories } from './async';
import { ProductState } from './types';

export const initialState: ProductState = {
  products: {
    data: undefined,
    request: initialRequestState,
  },
  productCategories: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    search: undefined,
    contactDialog: {
      isOpen: false,
      contentType: 'form',
      selectedProduct: undefined,
    },
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setSearch(state, { payload }) {
      state.ui.search = payload;
    },
    setIsContactDialogOpen(state, { payload }) {
      state.ui.contactDialog.isOpen = payload;
    },
    setContactDialogContentType(state, { payload }) {
      state.ui.contactDialog.contentType = payload;
    },
    setContactDialogSelectedProduct(state, { payload }) {
      state.ui.contactDialog.selectedProduct = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.fulfilled, (state, { meta, payload }) => {
      if (state.products.request.id === meta.requestId) {
        state.products.data = payload;
        state.products.request = requestReducer(
          state.products.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(getAllProducts.pending, (state, { meta }) => {
      state.products.data = undefined;
      state.products.request = requestReducer(
        state.products.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllProducts.rejected, (state, { meta }) => {
      state.products.data = undefined;
      state.products.request = requestReducer(
        state.products.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllProductCategories.fulfilled,
      (state, { meta, payload }) => {
        if (state.productCategories.request.id === meta.requestId) {
          state.productCategories.data = payload;
          state.productCategories.request = requestReducer(
            state.productCategories.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getAllProductCategories.pending, (state, { meta }) => {
      state.productCategories.data = undefined;
      state.productCategories.request = requestReducer(
        state.productCategories.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllProductCategories.rejected, (state, { meta }) => {
      state.productCategories.data = undefined;
      state.productCategories.request = requestReducer(
        state.productCategories.request,
        rejected({ meta })
      );
    });
  },
});

const { actions, reducer } = slice;

export const {
  setSearch,
  setIsContactDialogOpen,
  setContactDialogContentType,
  setContactDialogSelectedProduct,
} = actions;

export default reducer;
