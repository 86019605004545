import { CustomReportDto } from '@kortxio/hub-api';
import { ReportTypes } from 'features/reports/campaigns/types';
import { toKebabCase } from 'libs/string';

export const customReportToRoute = (customReport: CustomReportDto) => {
  return toKebabCase('custom-' + customReport.name);
};

export const customReportToCustomReportMetadata = (
  customReport: CustomReportDto
) => {
  return {
    id: `custom-${customReport.id}`,
    reportName: customReportToRoute(customReport),
    tabNameAsParam: customReportToRoute(customReport),
    label: customReport.name ?? '',
    url: customReport.url,
    reportType: ReportTypes.CUSTOM,
    destination: customReport.destination,
  };
};
