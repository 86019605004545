import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  JiraGlobalSupportTicketOutputDto,
  SupportTicketInputDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getAllGlobalSupportTickets = createAsyncThunk<
  JiraGlobalSupportTicketOutputDto[],
  { clientId: number },
  AsyncThunkConfig
>(
  'support/getAllGlobalSupportTickets',
  async ({ clientId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.support.getAllGlobalSupportTicketsByClientId(
        clientId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const createGlobalSupportTicket = createAsyncThunk<
  JiraGlobalSupportTicketOutputDto,
  { clientId: number; requestBody: SupportTicketInputDto },
  AsyncThunkConfig
>(
  'support/createGlobalSupportTicket',
  async ({ clientId, requestBody }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.support.postGlobalSupportTicket(
        clientId,
        requestBody
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
            uiFieldErrorMessages: apiError.uiFieldErrorMessages,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
