import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { defaultCognitoNoAuthSessionErrorMessage } from 'features/auth/cognitoFunctions';
import { useAuth } from 'features/auth/UseAuth';
import { dismissError } from 'features/error/slice';
import { defaultErrorMessage } from 'libs/error/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';

function ErrorBackdrop() {
  const dispatch = useAppDispatch();

  const { hasError, uiMessage, uiFieldErrorMessages } = useAppSelector(
    (state) => state?.error
  );

  const { user: authUser, logout } = useAuth();

  const hasAuth = authUser !== undefined;

  const [isOpen, setIsOpen] = useState(false);

  const isLogoutOnExitedError =
    uiMessage === defaultCognitoNoAuthSessionErrorMessage;

  useEffect(() => {
    if (hasAuth && hasError) {
      setIsOpen(true);
    }
  }, [hasAuth, hasError]);

  useEffect(() => {
    if (!hasAuth && hasError) {
      setIsOpen(false);

      dispatch(dismissError());
    }
  }, [hasAuth, hasError, dispatch]);

  const onClose = () => {
    setIsOpen(false);
  };

  const onExited = () => {
    dispatch(dismissError());

    if (isLogoutOnExitedError) {
      logout();
    }
  };

  return (
    <>
      {hasAuth && (
        <Backdrop
          open={isOpen}
          onClick={onClose}
          onExited={onExited}
          sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
        >
          <Alert severity="error" onClose={onClose}>
            {uiMessage ?? defaultErrorMessage}
            {uiFieldErrorMessages && (
              <Box
                component="ul"
                sx={{
                  padding: 0,
                  listStyle: 'none',
                }}
              >
                {uiFieldErrorMessages.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </Box>
            )}
          </Alert>
        </Backdrop>
      )}
    </>
  );
}

export default ErrorBackdrop;
