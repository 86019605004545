import { useEffect } from 'react';
import { useAuth } from 'features/auth/UseAuth';
import { getMagicBellHmacForCurrentUser } from 'features/notification/async';
import { magicBellHmacSelector } from 'features/notification/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function WithMagicBellHmacForCurrentUser() {
  const dispatch = useAppDispatch();

  const { user: authUser } = useAuth();

  const hasAuth = authUser !== undefined;

  const magicBellHmac = useAppSelector(magicBellHmacSelector);

  useEffect(() => {
    if (hasAuth && !magicBellHmac) {
      dispatch(getMagicBellHmacForCurrentUser());
    }
  }, [hasAuth, magicBellHmac, dispatch]);

  return <></>;
}
