import {
  combineReducers,
  configureStore,
  createAction,
} from '@reduxjs/toolkit';
import adminUserManagement from 'features/admin-user-management/slice';
import advertiserById from 'features/advertiser/slice';
import advertiser from 'features/advertisers/slice';
import { api } from 'features/analytics/api';
import analytics from 'features/analytics/slice';
import chatbot from 'features/assistant/slice';
import audiences from 'features/audiences/slice';
import campaignById from 'features/campaign/slice';
import campaigns from 'features/campaigns/slice';
import client from 'features/client/slice';
import error from 'features/error/slice';
import featureFlags from 'features/feature-flags/slice';
import invoice from 'features/invoice/slice';
import notification from 'features/notification/slice';
import product from 'features/product/slice';
import advertiserReports from 'features/reports/advertisers/slice';
import campaignReports from 'features/reports/campaigns/slice';
import support from 'features/support/slice';
import user from 'features/user/slice';

export const resetStore = createAction<void>('store/reset');

const combinedReducers = combineReducers({
  advertiser,
  advertiserById,
  advertiserReports,
  analytics,
  audiences,
  campaigns,
  chatbot,
  campaignById,
  campaignReports,
  client,
  error,
  featureFlags,
  invoice,
  notification,
  product,
  support,
  user,
  adminUserManagement,
  [api.reducerPath]: api.reducer,
});

const resettableCombinedReducers: typeof combinedReducers = (state, action) => {
  if (resetStore.match(action)) {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: resettableCombinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
