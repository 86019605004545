/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import {
  requestReducer,
  initialRequestState,
  fulfilled,
  pending,
  rejected,
} from 'features/shared/request';
import { getAllGlobalSupportTickets } from './async';
import { SupportState } from './types';

export const initialState: SupportState = {
  globalSupportTickets: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    globalSupport: {
      search: undefined,
      isShowClosedChecked: false,
      createDialog: {
        isOpen: false,
        contentType: 'listOfCategories',
        selectedCategory: undefined,
      },
    },
  },
};

const slice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setGlobalSupportSearch(state, { payload }) {
      state.ui.globalSupport.search = payload;
    },
    setIsGlobalSupportShowClosedChecked(state, { payload }) {
      state.ui.globalSupport.isShowClosedChecked = payload;
    },
    setIsGlobalSupportCreateDialogOpen(state, { payload }) {
      state.ui.globalSupport.createDialog.isOpen = payload;
    },
    setGlobalSupportCreateDialogContentType(state, { payload }) {
      state.ui.globalSupport.createDialog.contentType = payload;
    },
    setGlobalSupportCreateDialogSelectedCategory(state, { payload }) {
      state.ui.globalSupport.createDialog.selectedCategory = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllGlobalSupportTickets.fulfilled,
      (state, { meta, payload }) => {
        if (state.globalSupportTickets.request.id === meta.requestId) {
          state.globalSupportTickets.data = payload;
          state.globalSupportTickets.request = requestReducer(
            state.globalSupportTickets.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getAllGlobalSupportTickets.pending, (state, { meta }) => {
      state.globalSupportTickets.data = undefined;
      state.globalSupportTickets.request = requestReducer(
        state.globalSupportTickets.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllGlobalSupportTickets.rejected, (state, { meta }) => {
      state.globalSupportTickets.data = undefined;
      state.globalSupportTickets.request = requestReducer(
        state.globalSupportTickets.request,
        rejected({ meta })
      );
    });
  },
});

const { actions, reducer } = slice;

export const {
  setGlobalSupportSearch,
  setIsGlobalSupportShowClosedChecked,
  setIsGlobalSupportCreateDialogOpen,
  setGlobalSupportCreateDialogContentType,
  setGlobalSupportCreateDialogSelectedCategory,
} = actions;

export default reducer;
