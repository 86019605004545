/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import { getMagicBellHmacForUsername } from 'features/notification/async';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import {
  getAllInternalUsers,
  getAllStandardUsers,
  getCognitoUserByClientIdAndUsername,
  getMe,
  getMeWithUpdates,
} from 'features/user/async';
import { StandardUserDialogState, UserState } from 'features/user/types';

export const initialState: UserState = {
  cognitoUser: {
    data: undefined,
    request: initialRequestState,
  },
  user: {
    data: undefined,
    request: initialRequestState,
  },
  magicBellHmac: {
    data: undefined,
    request: initialRequestState,
  },
  standardUsers: {
    data: undefined,
    request: initialRequestState,
  },
  internalUsers: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    updateAccountConfirmationDialog: {
      isOpen: false,
    },
    changePasswordConfirmationDialog: {
      isOpen: false,
    },
    standardUser: {
      search: undefined,
      isShowDisabledChecked: false,
      createDialog: {
        isOpen: false,
      },
      updateDialog: {
        isOpen: false,
        user: undefined,
      },
      manageNotificationsDialog: {
        isOpen: false,
        user: undefined,
      },
      disableDialog: {
        isOpen: false,
        user: undefined,
      },
      enableDialog: {
        isOpen: false,
        user: undefined,
      },
      resetPasswordDialog: {
        isOpen: false,
        user: undefined,
      },
    },
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsUpdateAccountConfirmationDialogOpen(state, { payload }) {
      state.ui.updateAccountConfirmationDialog.isOpen = payload;
    },
    setIsChangePasswordConfirmationDialogOpen(state, { payload }) {
      state.ui.changePasswordConfirmationDialog.isOpen = payload;
    },
    setStandardUserSearch(state, { payload }) {
      state.ui.standardUser.search = payload;
    },
    setIsStandardUserShowDisabledChecked(state, { payload }) {
      state.ui.standardUser.isShowDisabledChecked = payload;
    },
    setIsStandardUserCreateDialogOpen(state, { payload }) {
      state.ui.standardUser.createDialog.isOpen = payload;
    },
    setIsStandardUserUpdateDialogOpen(state, { payload }) {
      state.ui.standardUser.updateDialog.isOpen = payload;
    },
    setStandardUserUpdateDialogUser(state, { payload }) {
      state.ui.standardUser.updateDialog.user = payload;
    },
    setIsStandardUserManageNotificationsDialogOpen(state, { payload }) {
      state.ui.standardUser.manageNotificationsDialog.isOpen = payload;
    },
    setStandardUserManageNotificationsDialogUser(state, { payload }) {
      state.ui.standardUser.manageNotificationsDialog.user = payload;
    },
    setIsStandardUserDisableDialogOpen(state, { payload }) {
      state.ui.standardUser.disableDialog.isOpen = payload;
    },
    setStandardUserDisableDialogUser(state, { payload }) {
      state.ui.standardUser.disableDialog.user = payload;
    },
    setIsStandardUserEnableDialogOpen(state, { payload }) {
      state.ui.standardUser.enableDialog.isOpen = payload;
    },
    setStandardUserEnableDialogUser(state, { payload }) {
      state.ui.standardUser.enableDialog.user = payload;
    },
    setStandardUserResetPasswordDialogState(
      state,
      { payload }: { payload: StandardUserDialogState; type: string }
    ) {
      state.ui.standardUser.resetPasswordDialog = payload;
    },
    resetMagicBellHmacForUsername(state) {
      state.magicBellHmac.data = undefined;
      state.magicBellHmac.request = initialRequestState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, { meta, payload }) => {
      if (state.user.request.id === meta.requestId) {
        state.user.data = payload;
        state.user.request = requestReducer(
          state.user.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(getMe.pending, (state, { meta }) => {
      state.user.data = undefined;
      state.user.request = requestReducer(
        state.user.request,
        pending({ meta })
      );
    });

    builder.addCase(getMe.rejected, (state, { meta }) => {
      state.user.data = undefined;
      state.user.request = requestReducer(
        state.user.request,
        rejected({ meta })
      );
    });

    builder.addCase(getMeWithUpdates.fulfilled, (state, { payload }) => {
      const isUpdated =
        JSON.stringify(state.user.data) !== JSON.stringify(payload);

      if (isUpdated) {
        state.user.data = payload;
      }
    });

    builder.addCase(
      getAllStandardUsers.fulfilled,
      (state, { meta, payload }) => {
        if (state.standardUsers.request.id === meta.requestId) {
          state.standardUsers.data = payload;
          state.standardUsers.request = requestReducer(
            state.standardUsers.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getAllStandardUsers.pending, (state, { meta }) => {
      state.standardUsers.data = undefined;
      state.standardUsers.request = requestReducer(
        state.standardUsers.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllStandardUsers.rejected, (state, { meta }) => {
      state.standardUsers.data = undefined;
      state.standardUsers.request = requestReducer(
        state.standardUsers.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllInternalUsers.fulfilled,
      (state, { meta, payload }) => {
        if (state.internalUsers.request.id === meta.requestId) {
          state.internalUsers.data = payload;
          state.internalUsers.request = requestReducer(
            state.internalUsers.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getAllInternalUsers.pending, (state, { meta }) => {
      state.internalUsers.data = undefined;
      state.internalUsers.request = requestReducer(
        state.internalUsers.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllInternalUsers.rejected, (state, { meta }) => {
      state.internalUsers.data = undefined;
      state.internalUsers.request = requestReducer(
        state.internalUsers.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getCognitoUserByClientIdAndUsername.fulfilled,
      (state, { meta, payload }) => {
        if (state.cognitoUser.request.id === meta.requestId) {
          state.cognitoUser.data = payload;
          state.cognitoUser.request = requestReducer(
            state.cognitoUser.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      getCognitoUserByClientIdAndUsername.pending,
      (state, { meta }) => {
        state.cognitoUser.data = undefined;
        state.cognitoUser.request = requestReducer(
          state.cognitoUser.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      getCognitoUserByClientIdAndUsername.rejected,
      (state, { meta }) => {
        state.cognitoUser.data = undefined;
        state.cognitoUser.request = requestReducer(
          state.cognitoUser.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(
      getMagicBellHmacForUsername.fulfilled,
      (state, { meta, payload }) => {
        if (state.user.request.id === meta.requestId) {
          state.magicBellHmac.data = payload;
          state.magicBellHmac.request = requestReducer(
            state.user.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getMagicBellHmacForUsername.pending, (state, { meta }) => {
      state.magicBellHmac.data = undefined;
      state.magicBellHmac.request = requestReducer(
        state.user.request,
        pending({ meta })
      );
    });

    builder.addCase(getMagicBellHmacForUsername.rejected, (state, { meta }) => {
      state.magicBellHmac.data = undefined;
      state.magicBellHmac.request = requestReducer(
        state.user.request,
        rejected({ meta })
      );
    });
  },
});

const { actions, reducer } = slice;

export const {
  setIsChangePasswordConfirmationDialogOpen,
  setIsStandardUserCreateDialogOpen,
  setIsStandardUserDisableDialogOpen,
  setIsStandardUserEnableDialogOpen,
  setIsStandardUserShowDisabledChecked,
  setIsStandardUserUpdateDialogOpen,
  setIsUpdateAccountConfirmationDialogOpen,
  setStandardUserDisableDialogUser,
  setStandardUserEnableDialogUser,
  setStandardUserResetPasswordDialogState:
    setStandardUserResendInvitationDialogState,
  setStandardUserSearch,
  setStandardUserUpdateDialogUser,
  setIsStandardUserManageNotificationsDialogOpen,
  setStandardUserManageNotificationsDialogUser,
  resetMagicBellHmacForUsername,
} = actions;

export default reducer;
