import { ChatResponse } from '@kortxio/assistant-api';
import { RequestState } from 'features/shared/request';
import { ObjectValues } from 'libs/types/types';

export const ChatMessageSources = {
  AI: 'ai',
  User: 'user',
} as const;
export type ChatMessageSource = ObjectValues<typeof ChatMessageSources>;

export type ChatMessageData = {
  id: string;
  source: ChatMessageSource;
  text: string;
};

export type ChatbotState = {
  messages: ChatMessageData[];
  promptRequest: {
    data: ChatResponse | undefined;
    request: RequestState;
  };
};
