import { Theme, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TemporaryDrawerToggleIconButton from 'layout/components/drawer/TemporaryDrawerToggleIconButton';

export default function KampusAppBar() {
  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  return (
    <>
      {!matchesUpMd && (
        <>
          <AppBar color="secondary" position="absolute">
            <Toolbar>
              <TemporaryDrawerToggleIconButton />
            </Toolbar>
          </AppBar>
        </>
      )}
    </>
  );
}
