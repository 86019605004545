import {
  AdvertiserSummaryProjectionDto,
  CognitoUserDto,
  PageInternalUserOutputDto,
  PageStandardUserOutputDto,
  StandardUserOutputDto,
  StandardUserOutputDtoRoleEnum,
  UserWithMetadataDto,
} from '@kortxio/hub-api';
import { RequestState } from 'features/shared/request';

export const getAutoGeneratedUsername = (
  firstName: string,
  lastName: string,
  clientId?: number
) => {
  // Replacing spaces with underscores, server does not accept usernames with spaces.
  const firstNameInitialFormatted = firstName
    .replace(' ', '_')
    .trim()
    .charAt(0)
    .toLowerCase();
  const lastNameFormatted = lastName
    .replace(' ', '_')
    .trim()
    .toLowerCase()
    .slice(0, 10);

  const unixTimestampInSeconds = Math.floor(Date.now() / 1000);

  return clientId === undefined
    ? `${firstNameInitialFormatted}${lastNameFormatted}.${unixTimestampInSeconds}`.replace(
        /\s+/g,
        ''
      )
    : `${firstNameInitialFormatted}${lastNameFormatted}.${clientId}.${unixTimestampInSeconds}`.replace(
        /\s+/g,
        ''
      );
};

export type StandardUserAsRow = {
  id?: string;
  username?: string;
  lastName?: string;
  firstName?: string;
  fullName?: string;
  email?: string;
  role: StandardUserOutputDtoRoleEnum;
  clientId?: number;
  advertiserIds?: number[];
  advertiserNames?: string[];
  enabled: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export const dtoToStandardUserAsRow = (
  dto: StandardUserOutputDto,
  advertisers: AdvertiserSummaryProjectionDto[] | undefined
): StandardUserAsRow => {
  return {
    id: dto.id,
    username: dto.username,
    lastName: dto.lastName,
    firstName: dto.firstName,
    fullName: `${dto.firstName} ${dto.lastName}`,
    email: dto.email,
    role: dto.role as StandardUserOutputDtoRoleEnum, // If we have an undefined role here it means the server is broken.
    clientId: dto.clientId,
    advertiserIds: dto.advertiserIds,
    advertiserNames:
      advertisers && dto.advertiserIds
        ? (
            dto.advertiserIds
              .map((id) => {
                const advertiser = advertisers.find((value) => value.id === id);

                return advertiser?.name ?? undefined;
              })
              .filter((value) => value !== undefined) as string[]
          ).sort((a, b) => a.localeCompare(b))
        : [],
    enabled: dto.enabled ?? false,
    createdAt: dto.createdAt,
    updatedAt: dto.updatedAt,
  };
};
export type DialogState = {
  isOpen: boolean;
};

export type StandardUserDialogState = DialogState & {
  user: StandardUserOutputDto | undefined;
};

export type StandardUserUiState = {
  search: string | undefined;
  isShowDisabledChecked: boolean;
  createDialog: DialogState;
  updateDialog: StandardUserDialogState;
  manageNotificationsDialog: StandardUserDialogState;
  disableDialog: StandardUserDialogState;
  enableDialog: StandardUserDialogState;
  resetPasswordDialog: StandardUserDialogState;
};

export type UserUiState = {
  updateAccountConfirmationDialog: DialogState;
  changePasswordConfirmationDialog: DialogState;
  standardUser: StandardUserUiState;
};

export type UserState = {
  cognitoUser: {
    data: CognitoUserDto | undefined;
    request: RequestState;
  };
  user: {
    data: UserWithMetadataDto | undefined;
    request: RequestState;
  };
  magicBellHmac: {
    data: string | undefined;
    request: RequestState;
  };
  standardUsers: {
    data: PageStandardUserOutputDto | undefined;
    request: RequestState;
  };
  internalUsers: {
    data: PageInternalUserOutputDto | undefined;
    request: RequestState;
  };
  ui: UserUiState;
};
