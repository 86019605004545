import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputLabel, TextField } from '@mui/material';
import Columns from 'components/shared/Layout/Columns';
import Rows from 'components/shared/Layout/Rows';
import NoVisitLink from 'components/shared/NoVisitLink';
import { darkFieldSx } from 'features/auth/sx';

export type LoginFormProps = {
  authenticating?: boolean;
  username: string;
  password: string;
  disabled: boolean;
  setUsername: (a: string) => void;
  setPassword: (a: string) => void;
  onSubmit: () => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  authenticating = false,
  username,
  password,
  setUsername,
  setPassword,
  disabled,
  onSubmit,
}) => {
  const wrappedSubmit = React.useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
    ) => {
      e.preventDefault();
      if ((username || '').length === 0 || (password || '').length === 0) {
        return;
      }
      onSubmit();
    },
    [onSubmit, password, username]
  );

  return (
    <Rows sx={{ pt: 1, pb: 4 }}>
      <form onSubmit={wrappedSubmit}>
        <Rows>
          <InputLabel htmlFor="username" sx={{ color: 'white' }}>
            <strong>Email</strong>
          </InputLabel>
          <TextField
            sx={darkFieldSx}
            name="username"
            autoComplete="username"
            disabled={disabled}
            InputLabelProps={{ shrink: false }}
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputLabel htmlFor="password" sx={{ color: 'white', pt: 2 }}>
            <strong>Password</strong>
          </InputLabel>
          <TextField
            sx={darkFieldSx}
            name="password"
            autoComplete="current-password"
            disabled={disabled}
            type="password"
            margin="normal"
            InputLabelProps={{ shrink: false }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Rows>
        <Columns sx={{ my: 2 }}>
          <Columns
            sx={{ flex: 1, alignItems: 'center', justifyContent: 'end' }}
          >
            <NoVisitLink href="/auth/forgot-password">
              Forgot Password?
            </NoVisitLink>
          </Columns>
        </Columns>
        <LoadingButton
          type="submit"
          sx={{ width: '100%' }}
          disabled={disabled}
          loading={authenticating}
          variant="contained"
        >
          <strong>Sign In</strong>
        </LoadingButton>
      </form>
    </Rows>
  );
};

export default LoginForm;
