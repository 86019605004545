import { fetchAuthSession } from 'aws-amplify/auth';

export class CognitoNoAuthSessionError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = 'NoCognitoSessionError'; // (2)
  }
}

export const defaultCognitoNoAuthSessionErrorMessage =
  'Session has expired. Please sign in again.';

export async function getCognitoJwtToken(): Promise<string> {
  const cognitoUserSession = await fetchAuthSession();

  const { tokens } = cognitoUserSession;

  if (tokens === undefined) {
    throw new CognitoNoAuthSessionError(
      defaultCognitoNoAuthSessionErrorMessage
    );
  }

  const { idToken } = tokens ?? {};

  const jwtToken = idToken?.toString();

  if (jwtToken === undefined) {
    throw new CognitoNoAuthSessionError(
      defaultCognitoNoAuthSessionErrorMessage
    );
  }

  return jwtToken;
}
