import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getIsRichMediaActive = createAsyncThunk<
  boolean,
  void,
  AsyncThunkConfig
>(
  'featureFlags/active/rich-media',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.featureFlags.isAnyFeatureActive([
        'SHOW_RICH_MEDIA_REVIEW_TABLE',
        'SHOW_RICH_MEDIA_REVIEW_TABLE_ADMIN',
      ]);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
