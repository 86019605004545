import { Draft } from '@reduxjs/toolkit';
import {
  fulfilled,
  pending,
  rejected,
  requestReducer,
  RequestState,
} from 'features/shared/request';

interface CallState<T> {
  data?: T;
  request: RequestState;
}
export function onPending<T, M>(callState: Draft<CallState<T>>, meta: M) {
  callState.data = undefined;
  callState.request = requestReducer(callState.request, pending({ meta }));
}

export function onRejected<T, M>(callState: Draft<CallState<T>>, meta: M) {
  callState.data = undefined;
  callState.request = requestReducer(callState.request, rejected({ meta }));
}

export function onFulfilled<M, T>(
  callState: Draft<CallState<T>>,
  meta: M,
  requestId: string | undefined,
  payload: Draft<T> | undefined
) {
  {
    if (callState.request.id === requestId) {
      callState.data = payload;
      callState.request = requestReducer(
        callState.request,
        fulfilled({ meta })
      );
    }
  }
}
