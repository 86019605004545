import { CampaignReportSpecificationOutputDtoObjectiveEnum } from '@kortxio/hub-api';

export enum ReportObjective {
  Conversions = 'Conversions',
  Revenue = 'Revenue',
  Clicks = 'Clicks',
  CompletionRate = 'Completion Rate',
  Viewability = 'Viewability',
}

export const allReportObjectives = Object.values(ReportObjective);

export const objectiveToSearchParam = (
  objective: CampaignReportSpecificationOutputDtoObjectiveEnum | undefined
) => {
  switch (objective) {
    case undefined:
      return undefined;
    case CampaignReportSpecificationOutputDtoObjectiveEnum.Clicks:
      return ReportObjective.Clicks;
    case CampaignReportSpecificationOutputDtoObjectiveEnum.CompletionRate:
      return ReportObjective.CompletionRate;
    case CampaignReportSpecificationOutputDtoObjectiveEnum.Conversions:
      return ReportObjective.Conversions;
    case CampaignReportSpecificationOutputDtoObjectiveEnum.Revenue:
      return ReportObjective.Revenue;
    case CampaignReportSpecificationOutputDtoObjectiveEnum.Viewability:
      return ReportObjective.Viewability;
  }
};

export enum ReportDatePreset {
  CampaignLifetime = 'Campaign Lifetime',
  Custom = 'Custom',
  Last14Days = 'Last 14 days',
  Last30Days = 'Last 30 days',
  Last7Days = 'Last 7 days',
  Mtd = 'MTD',
  PreviousMonth = 'Previous Month',
  Ytd = 'YTD',
}

export const allReportDatePresets = Object.values(ReportDatePreset);
