import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AudienceCountForDayDto,
  AudienceWithCountsDto,
  LookerReportOutputDto,
  ReportDataDto,
} from '@kortxio/hub-api';
import { RunAudienceQueryParams } from 'features/audiences/types';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';

export const getAllAudiences = createAsyncThunk<
  AudienceWithCountsDto[],
  {
    clientId: number;
    advertiserId: number;
  }
>(
  'audience/getAllAudiences',
  async ({ clientId, advertiserId }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.audiences.getAllAudiencesWithCountsByClientIdAndAdvertiserId(
          clientId,
          advertiserId
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (apiError.isApi404Error) {
        return [];
      }
      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAudienceCountsHistoryById = createAsyncThunk<
  AudienceCountForDayDto[],
  {
    clientId: number;
    advertiserId: number;
    id: string;
  }
>(
  'audience/getAudienceCountsHistoryById',
  async ({ clientId, advertiserId, id }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.audiences.getAudienceCountsHistoryByClientIdAndAdvertiserIdAndId(
          clientId,
          advertiserId,
          id
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (apiError.isApi404Error) {
        return [];
      }
      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllReportsForAudience = createAsyncThunk<
  LookerReportOutputDto[],
  {
    clientId: number;
    advertiserId: number;
    audienceId: string;
  }
>(
  'audienceReports/getAllReportsForAudience',
  async (
    { clientId, advertiserId, audienceId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.audienceReports.getAllReportsForAudience(
        clientId,
        advertiserId,
        audienceId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const runQueryForAudience = createAsyncThunk<
  ReportDataDto,
  RunAudienceQueryParams
>(
  'audienceReports/runQueryForAudience',
  async (
    { clientId, advertiserId, audienceId, id, queryId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.audienceReports.postRunQueryForAudience(
        clientId,
        advertiserId,
        audienceId,
        id,
        queryId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
