import { Theme, useMediaQuery, useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import KampusMagicBellProviderForCurrentUser from 'components/shared/magicbell/KampusMagicBellProvider/KampusMagicBellProviderForCurrentUser';
import EmptyMagicBellInbox from 'components/shared/magicbell/MagicBellInboxRegistry/components/EmptyMagicBellInbox';
import MagicBellInbox from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInbox';
import { drawerListItemStyles } from 'layout/components/drawer/drawerListItemStyles';

export default function MagicBellInboxMenuDrawerListItem() {
  const theme = useTheme();

  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  const classes = drawerListItemStyles(theme);

  const showTooltip = Boolean(matchesUpMd);

  const showListItemText = Boolean(!matchesUpMd);

  return (
    <ListItem disablePadding sx={classes.listItem}>
      <Stack
        direction="row"
        sx={{
          ...classes.listItemButton,
        }}
      >
        <ListItemIcon
          sx={{ ...classes.listItemIcon, flex: 1, justifyContent: 'start' }}
        >
          <KampusMagicBellProviderForCurrentUser
            fallbackWhileLoading={
              <EmptyMagicBellInbox
                text={
                  showListItemText && (
                    <ListItemText
                      sx={{ ...classes.listItemText, flexGrow: 0 }}
                      primary="Notifications"
                    />
                  )
                }
              />
            }
          >
            <MagicBellInbox
              text={
                showListItemText && (
                  <ListItemText
                    sx={{ ...classes.listItemText, flexGrow: 0 }}
                    primary="Notifications"
                  />
                )
              }
              tooltipText={showTooltip ? 'Notifications' : undefined}
            />
          </KampusMagicBellProviderForCurrentUser>
        </ListItemIcon>
      </Stack>
    </ListItem>
  );
}
