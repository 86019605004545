import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  StandardUserWithMetadataDto,
  UserWithMetadataDtoTypeEnum,
} from '@kortxio/hub-api';
import { useAuth } from 'features/auth/UseAuth';
import { getAllClientsWithUpdates } from 'features/client/async';
import { setClients } from 'features/client/slice';
import { userSelector } from 'features/user/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

function WithClients({ children }: React.PropsWithChildren) {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { pathname } = location;

  const { user: authUser } = useAuth();

  const hasAuth = authUser !== undefined;

  const user = useAppSelector(userSelector);

  const { type: typeOfUser } = user ?? {};

  useEffect(() => {
    if (hasAuth && typeOfUser === UserWithMetadataDtoTypeEnum.Standard) {
      const standardUser = user as StandardUserWithMetadataDto;

      const { client } = standardUser;

      if (client) {
        dispatch(
          setClients([{ id: client.id, name: client.name, apps: client.apps }])
        );
      }
    }
  }, [hasAuth, user, typeOfUser, dispatch]);

  useEffect(() => {
    if (hasAuth && typeOfUser === UserWithMetadataDtoTypeEnum.Internal) {
      dispatch(getAllClientsWithUpdates());
    }
  }, [hasAuth, typeOfUser, pathname, dispatch]);

  return <>{children}</>;
}

export default WithClients;
