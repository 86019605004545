import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChatResponse } from '@kortxio/assistant-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const submitChatMessage = createAsyncThunk<
  ChatResponse,
  {
    clientId: string;
    campaignId: number;
    question: string;
  },
  AsyncThunkConfig
>(
  'chatbot/submitChatMessage',
  async ({ clientId, campaignId, question }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.chatbot.submitChatMessage(
        clientId,
        campaignId,
        { question: question }
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const prepareChatBot = createAsyncThunk<
  void,
  {
    clientId: string;
    campaignId: number;
  },
  AsyncThunkConfig
>(
  'chatbot/prepareChatBot',
  async ({ clientId, campaignId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.chatbot.prepareChatBot(clientId, campaignId);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
