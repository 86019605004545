import { Link as RouterLink, useMatch, useParams } from 'react-router-dom';
import { selectedAdvertiserSelector } from 'features/advertiser/selectors';
import { selectedClientSelector } from 'features/client/selectors';
import { useAppSelector } from 'store/hooks';
import DrawerListItem from './DrawerListItem';

export type RouterDrawerListItemProps = {
  to?: string;
  text?: string;
  icon?: React.ReactNode;
  visible?: boolean;
};

const RouterDrawerListItem = ({
  to,
  text,
  icon,
  visible = true,
}: RouterDrawerListItemProps) => {
  const selectedClient = useAppSelector(selectedClientSelector);
  const selectedAdvertiser = useAppSelector(selectedAdvertiserSelector);

  const { id: selectedClientId } = selectedClient ?? {};

  const { id: selectedAdvertiserId } = selectedAdvertiser ?? {};

  const { advertiserId } = useParams();

  const toAudiences = to?.includes('audiences');
  const toReportingSuite = to?.includes('reporting-suite');

  const optionalAdvertiserId =
    advertiserId && (toAudiences || toReportingSuite)
      ? `/${selectedAdvertiserId}`
      : '';

  const toWithClientRoutingPrefix = `/clients/${selectedClientId}${to}${optionalAdvertiserId}`;

  const match = useMatch(`${toWithClientRoutingPrefix}/*`);

  const selected = Boolean(match);
  const disabled = Boolean(!selectedClientId);

  return visible ? (
    <DrawerListItem
      text={text}
      icon={icon}
      selected={selected}
      disabled={disabled}
      ListItemButtonProps={{
        component: RouterLink,
        to: toWithClientRoutingPrefix,
      }}
    />
  ) : null;
};

export default RouterDrawerListItem;
