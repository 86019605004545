import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthProvider from 'features/auth/AuthProvider';
import { resetAppcues, sendUserToAppcues } from 'libs/appcues';
import { fullstoryAnonymize, fullstoryIdentify } from 'libs/fullstory';

export default function KampusUserProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <AuthProvider
      onAfterLogin={(user) => {
        fullstoryIdentify(user);
        sendUserToAppcues(user);
      }}
      onAfterLogout={() => {
        fullstoryAnonymize();
        resetAppcues();
        // This line prevents the automatic behavior of `RequireAuth`, to save the last visited page
        // While RequireAuth is stable enough to do it... we don't want an exiting user remembering their page
        // If we change their role they may enter a login tornado of errors. We force regular login redirect.
        navigate('auth/login');
      }}
    >
      {children}
    </AuthProvider>
  );
}
