import { useState } from 'react';
import { Typography } from '@mui/material';
import Rows from 'components/shared/Layout/Rows';
import LoginErrorAlert from 'features/auth/Alerts/LoginErrorAlert';
import LoginForm from 'features/auth/Login/LoginForm';
import { useAuth } from 'features/auth/UseAuth';

export default function Login() {
  const { login, isBusy, error, user } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const hasUser = Boolean(user);

  return (
    <Rows sx={{ width: '100%' }}>
      <Typography color="white" variant="h5" sx={{ fontWeight: '900', pb: 6 }}>
        Sign in to your account
      </Typography>
      <LoginForm
        authenticating={isBusy}
        username={username}
        password={password}
        setUsername={setUsername}
        setPassword={setPassword}
        disabled={isBusy || hasUser}
        onSubmit={() => login(username, password)}
      />
      {error && <LoginErrorAlert error={error} />}
    </Rows>
  );
}
