/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import {
  getAllFilesByCampaignId,
  getProvenProcessInfo,
  getSelectedCampaignById,
} from 'features/campaign/async';
import {
  DOCUMENT_FILE_TYPE_FILTER_LABELS,
  CampaignByIdState,
  DocumentFileTypeFilterLabel,
} from 'features/campaign/types';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';

const initialState: CampaignByIdState = {
  selectedCampaign: {
    data: undefined,
    request: initialRequestState,
  },
  provenProcess: {
    data: undefined,
    request: initialRequestState,
  },
  documents: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    searchForDocuments: undefined,
    fileTypeFilterLabel: DOCUMENT_FILE_TYPE_FILTER_LABELS.All,
  },
};

const slice = createSlice({
  name: 'campaignById',
  initialState,
  reducers: {
    setSelectedCampaign: (state, { payload }) => {
      state.selectedCampaign.data = payload;
      state.selectedCampaign.request = initialRequestState;
    },
    setSearchForDocuments(state, { payload }: { payload: string | undefined }) {
      state.ui.searchForDocuments = payload;
    },
    setFileTypeFilterForDocuments(
      state,
      { payload }: { payload: DocumentFileTypeFilterLabel }
    ) {
      state.ui.fileTypeFilterLabel = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSelectedCampaignById.fulfilled,
      (state, { meta, payload }) => {
        if (state.selectedCampaign.request.id === meta.requestId) {
          state.selectedCampaign.data = payload;
          state.selectedCampaign.request = requestReducer(
            state.selectedCampaign.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getSelectedCampaignById.pending, (state, { meta }) => {
      state.selectedCampaign.data = undefined;
      state.selectedCampaign.request = requestReducer(
        state.selectedCampaign.request,
        pending({ meta })
      );
    });
    builder.addCase(getSelectedCampaignById.rejected, (state, { meta }) => {
      state.selectedCampaign.data = undefined;
      state.selectedCampaign.request = requestReducer(
        state.selectedCampaign.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getProvenProcessInfo.fulfilled,
      (state, { meta, payload }) => {
        if (state.provenProcess.request.id === meta.requestId) {
          state.provenProcess.data = payload;
          state.provenProcess.request = requestReducer(
            state.provenProcess.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getProvenProcessInfo.pending, (state, { meta }) => {
      state.provenProcess.data = undefined;
      state.provenProcess.request = requestReducer(
        state.provenProcess.request,
        pending({ meta })
      );
    });
    builder.addCase(getProvenProcessInfo.rejected, (state, { meta }) => {
      state.provenProcess.data = undefined;
      state.provenProcess.request = requestReducer(
        state.provenProcess.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllFilesByCampaignId.fulfilled,
      (state, { meta, payload }) => {
        if (state.documents.request.id === meta.requestId) {
          state.documents.data = payload;
          state.documents.request = requestReducer(
            state.documents.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(getAllFilesByCampaignId.pending, (state, { meta }) => {
      state.documents.data = undefined;
      state.documents.request = requestReducer(
        state.documents.request,
        pending({ meta })
      );
    });
    builder.addCase(getAllFilesByCampaignId.rejected, (state, { meta }) => {
      state.documents.data = undefined;
      state.documents.request = requestReducer(
        state.documents.request,
        rejected({ meta })
      );
    });
  },
});

const { actions, reducer } = slice;

export const { setSearchForDocuments, setFileTypeFilterForDocuments } = actions;

export default reducer;
