import { ForwardedRef, PropsWithChildren } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  menuPaper: {
    maxHeight: 512,
  },
  menuList: {
    minHeight: 550,
    maxHeight: 550,
    maxWidth: 450,
    minWidth: 450,
    padding: theme.spacing(0),
    '&:hover': {
      background: 'inherit',
    },
  },
}));

export type MagicBellInboxMenuProps = PropsWithChildren &
  MenuProps & {
    menuRef: ForwardedRef<HTMLDivElement | null>;
  };

export default function MagicBellInboxMenu({
  children,
  menuRef,
  ...props
}: MagicBellInboxMenuProps) {
  const { classes } = useStyles();

  return (
    <Menu
      id="notification-menu"
      keepMounted
      MenuListProps={{
        'aria-labelledby': 'notification-menu-button',
      }}
      slotProps={{
        paper: {
          sx: { marginLeft: 11 },
          ref: menuRef,
        },
      }}
      classes={{
        paper: classes.menuPaper,
        list: classes.menuList,
      }}
      {...props}
    >
      {children}
    </Menu>
  );
}
