import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from 'layout';
import { userSelector } from 'features/user/selectors';

function RequireTermsOfUseAccepted({ children }: React.PropsWithChildren) {
  const user = useSelector(userSelector);

  if (!user) return <Layout />;

  const { termsOfUseAccepted } = user;

  if (termsOfUseAccepted) {
    return <>{children}</>;
  }

  if (termsOfUseAccepted !== undefined && !termsOfUseAccepted) {
    return <Navigate to="terms-of-use" />;
  }

  return <Layout />;
}

export default RequireTermsOfUseAccepted;
