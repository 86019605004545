import {
  StandardUserWithMetadataDto,
  UserWithMetadataDto,
} from '@kortxio/hub-api';
import config from 'libs/config';

declare global {
  interface Window {
    Appcues: Appcues;
  }
}

// https://docs.appcues.com/dev-api-data/javascript-api-developer
interface Appcues {
  identify(uid: string, userVars?: object): void;

  group(gid: string): void;

  reset(): void;
}

const { isEnabled } = config.appcues;

export const sendUserToAppcues = (
  user: UserWithMetadataDto & StandardUserWithMetadataDto
) => {
  if (isEnabled) {
    const { id, email, lastName, firstName, type, role, client } = user || {};

    const { id: clientId, name: clientName } = client || {};

    window.Appcues.identify(id || 'no-uid', {
      displayName: `${firstName} ${lastName}`,
      email,
      clientId: type === 'INTERNAL' ? 0 : clientId,
      clientName: type === 'INTERNAL' ? 'KORTX' : clientName,
      userType: type,
      userRole: role,
    });
    window.Appcues.group(
      (type === 'INTERNAL' ? 'KORTX' : clientName) || 'no-gid'
    );
  }
};

export const resetAppcues = () => {
  window.Appcues.reset();
};
