import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function NotFound() {
  const navigate = useNavigate();

  const onClickGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: 'secondary.main',
        minHeight: '100vh',
      }}
    >
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            fontSize: '30vh',
            color: 'common.white',
          }}
        >
          404
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="h2" gutterBottom sx={{ color: 'grey.500' }}>
            Oops. Nothing here...
          </Typography>
        </Box>
        <Box mt={2} textAlign="center">
          <Button color="primary" variant="contained" onClick={onClickGoBack}>
            Go Back
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default NotFound;
