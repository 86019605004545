import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import {
  CategoryNameEnum,
  ChannelTypeEnum,
  NotificationPreferencesInputDto,
} from '@kortxio/hub-api';
import { useNotificationPreferences } from '@magicbell/react-headless';
import {
  CategoryChannelPreference,
  ChannelPreference,
} from '@magicbell/react-headless/dist/types/IRemoteNotificationPreferences';
import { setError } from 'features/error/slice';
import { toApiError } from 'libs/api/helper';
import { toTitleCase } from 'libs/string';

export type MagicBellInboxPreferencesListItemProps = {
  category: CategoryChannelPreference & { description: string };
  handleCloseMenu: () => void;
  handleUpdatePreferences: (
    requestBody: NotificationPreferencesInputDto
  ) => unknown;
};

export default function MagicBellInboxPreferencesListItem({
  category,
  handleCloseMenu,
  handleUpdatePreferences,
}: MagicBellInboxPreferencesListItemProps) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { fetch: getAllNotificationPreferences } = useNotificationPreferences();

  const { slug, channels, description = [] } = category ?? {};

  const channelStates = useMemo(() => {
    return channels.reduce((acc: Record<string, boolean>, channel) => {
      acc[channel.slug] = channel.enabled;

      return acc;
    }, {});
  }, [channels]);

  const categoryName = slug as CategoryNameEnum;

  const handleOnChangeSwitch = useCallback(
    async (
      event: ChangeEvent<HTMLInputElement>,
      channel: ChannelPreference
    ) => {
      const isChannelEnabled = Boolean(event?.target?.checked);

      setIsLoading(true);

      const { slug } = channel ?? {};
      const channelType = slug as ChannelTypeEnum;

      const requestBody: NotificationPreferencesInputDto = {
        categories: [
          {
            name: categoryName,
            channels: [{ type: channelType, enabled: isChannelEnabled }],
          },
        ],
      };

      try {
        await handleUpdatePreferences(requestBody);

        return;
      } catch (error) {
        const apiError = toApiError(error);

        if (!apiError.isAbortedError) {
          dispatch(
            setError({
              message: apiError.message,
              uiMessage: apiError.uiMessage,
            })
          );
        }

        handleCloseMenu();
      } finally {
        await getAllNotificationPreferences();

        setIsLoading(false);
      }
    },
    [
      dispatch,
      categoryName,
      handleUpdatePreferences,
      handleCloseMenu,
      getAllNotificationPreferences,
    ]
  );

  // MenuItem needed extra rules to become a stack, so we made it a stack
  // This also solves a problem where rows are highlighted for no reason on hover
  return (
    <Stack direction="row" sx={{ px: 2 }} alignItems="center">
      <ListItemText
        primary={toTitleCase(categoryName)}
        secondary={description}
      />
      {channels
        .filter((channel) => ['in_app', 'email'].includes(channel.slug))
        .map((channel) => (
          <Switch
            sx={{ width: 60 }}
            key={channel.slug}
            checked={Boolean(channelStates[channel.slug])}
            disabled={isLoading}
            onChange={(event) => handleOnChangeSwitch(event, channel)}
          />
        ))}
    </Stack>
  );
}
