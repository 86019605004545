/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultErrorMessage } from 'libs/error/types';
import { ErrorState } from './types';

const initialState: ErrorState = {
  hasError: false,
  message: undefined,
  uiMessage: undefined,
  uiFieldErrorMessages: undefined,
};

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    dismissError(state) {
      state.hasError = false;
      state.message = undefined;
      state.uiMessage = undefined;
      state.uiFieldErrorMessages = undefined;
    },
    setError: (
      state,
      {
        payload,
      }: PayloadAction<{
        message: string;
        uiMessage: string;
        uiFieldErrorMessages?: string[];
      }>
    ) => {
      state.hasError = true;
      state.message = payload.message;
      state.uiMessage = payload.uiMessage ?? defaultErrorMessage;
      state.uiFieldErrorMessages = payload.uiFieldErrorMessages;
    },
  },
});

const { actions, reducer } = slice;

export const { dismissError, setError } = actions;

export default reducer;
