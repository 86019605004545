import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCognitoJwtToken } from 'features/auth/cognitoFunctions';
import config from 'libs/config';
import { errorHandler } from 'libs/error';

// https://redux-toolkit.js.org/rtk-query/usage/code-generation
// initialize an empty api service that we'll inject endpoints into later as needed
export const apiExtensions = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: config.api.analyticsApiUrl,
    prepareHeaders: async (headers) => {
      try {
        const jwtToken = await getCognitoJwtToken();

        if (jwtToken) {
          headers.set('authorization', `Bearer ${jwtToken}`);
        }
        return headers;
      } catch (error) {
        errorHandler.handle(error);

        return headers;
      }
    },
  }),
  endpoints: () => ({}),
});
