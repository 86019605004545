import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import KampusAppBar from 'layout/components/appbar/KampusAppBar';
import useKampusAppBarHeight from 'layout/components/appbar/useKampusAppBarHeight';
import KampusDrawer from './components/drawer/KampusDrawer';
import LayoutContextProvider from './context/LayoutContextProvider';

function Layout() {
  const mobileAppBarHeight = useKampusAppBarHeight();

  return (
    <LayoutContextProvider>
      <Container
        component="main"
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <KampusAppBar />
        <KampusDrawer />
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          minWidth={0}
          sx={{
            mt: { xs: `${mobileAppBarHeight}px`, md: 0 },
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </LayoutContextProvider>
  );
}

export default Layout;
