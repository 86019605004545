import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';

interface VisibilityAdornmentProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  tooltipHideMessage?: string;
}

export default function VisibilityAdornment({
  isVisible,
  setIsVisible,
}: VisibilityAdornmentProps) {
  return (
    <InputAdornment position="end">
      <Tooltip title={isVisible ? 'Hide' : 'Show'}>
        <IconButton
          aria-label="toggle visibility"
          onClick={() => setIsVisible(!isVisible)}
          onMouseDown={(event) => event.preventDefault()}
        >
          {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
}
