import React, { useState, useMemo } from 'react';
import { LayoutContext, LayoutContextProps } from 'layout/context';

function LayoutContextProvider({ children }: React.PropsWithChildren) {
  const [isTemporaryDrawerOpen, setIsTemporaryDrawerOpen] =
    useState<boolean>(false);

  const value = useMemo(
    (): LayoutContextProps => ({
      isTemporaryDrawerOpen,
      setIsTemporaryDrawerOpen,
    }),
    [isTemporaryDrawerOpen]
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}

export default LayoutContextProvider;
