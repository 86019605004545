/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import {
  getAllClients,
  getAllClientsWithUpdates,
  getSelectedClientById,
  getSelectedClientByIdWithUpdates,
  getTeamByClientId,
  getTeamByClientIdWithUpdates,
  downloadAccountManagerPhotoByClientId,
} from './async';
import { ClientState } from './types';

export const initialState: ClientState = {
  clients: {
    data: undefined,
    request: initialRequestState,
  },
  selectedClient: {
    data: undefined,
    request: initialRequestState,
  },
  team: {
    data: undefined,
    request: initialRequestState,
  },
  accountManagerPhotoBlobUrl: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    contactAccountManagerDialog: {
      isOpen: false,
      contentType: 'form',
    },
  },
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClients(state, { payload }) {
      state.clients.data = payload;
    },
    setIsContactAccountManagerDialogOpen(state, { payload }) {
      state.ui.contactAccountManagerDialog.isOpen = payload;
    },
    setContactAccountManagerDialogContentType(state, { payload }) {
      state.ui.contactAccountManagerDialog.contentType = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllClients.fulfilled, (state, { meta, payload }) => {
      if (state.clients.request.id === meta.requestId) {
        state.clients.data = payload;
        state.clients.request = requestReducer(
          state.clients.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(getAllClients.pending, (state, { meta }) => {
      state.clients.data = undefined;
      state.clients.request = requestReducer(
        state.clients.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllClients.rejected, (state, { meta }) => {
      state.clients.data = undefined;
      state.clients.request = requestReducer(
        state.clients.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllClientsWithUpdates.fulfilled,
      (state, { payload }) => {
        const isUpdated =
          JSON.stringify(state.clients.data) !== JSON.stringify(payload);

        if (isUpdated) {
          state.clients.data = payload;
        }
      }
    );

    builder.addCase(
      getSelectedClientById.fulfilled,
      (state, { meta, payload }) => {
        if (state.selectedClient.request.id === meta.requestId) {
          state.selectedClient.data = payload;
          state.accountManagerPhotoBlobUrl.data = undefined;
          state.accountManagerPhotoBlobUrl.request = initialRequestState;
          state.selectedClient.request = requestReducer(
            state.selectedClient.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getSelectedClientById.pending, (state, { meta }) => {
      state.selectedClient.data = undefined;
      state.selectedClient.request = requestReducer(
        state.selectedClient.request,
        pending({ meta })
      );
    });

    builder.addCase(getSelectedClientById.rejected, (state, { meta }) => {
      state.selectedClient.data = undefined;
      state.selectedClient.request = requestReducer(
        state.selectedClient.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getSelectedClientByIdWithUpdates.fulfilled,
      (state, { payload }) => {
        const isUpdated =
          JSON.stringify(state.selectedClient.data) !== JSON.stringify(payload);

        if (isUpdated) {
          state.selectedClient.data = payload;
          state.accountManagerPhotoBlobUrl.data = undefined;
          state.accountManagerPhotoBlobUrl.request = initialRequestState;
        }
      }
    );

    builder.addCase(getTeamByClientId.fulfilled, (state, { meta, payload }) => {
      if (state.team.request.id === meta.requestId) {
        state.team.data = payload;
        state.team.request = requestReducer(
          state.team.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(getTeamByClientId.pending, (state, { meta }) => {
      state.team.data = undefined;
      state.team.request = requestReducer(
        state.team.request,
        pending({ meta })
      );
    });

    builder.addCase(getTeamByClientId.rejected, (state, { meta }) => {
      state.team.data = undefined;
      state.team.request = requestReducer(
        state.team.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getTeamByClientIdWithUpdates.fulfilled,
      (state, { payload }) => {
        const isUpdated =
          JSON.stringify(state.team.data) !== JSON.stringify(payload);

        if (isUpdated) {
          state.team.data = payload;
        }
      }
    );

    builder.addCase(
      downloadAccountManagerPhotoByClientId.fulfilled,
      (state, { meta, payload: blobUrl }) => {
        if (state.accountManagerPhotoBlobUrl.request.id === meta.requestId) {
          state.accountManagerPhotoBlobUrl.data = blobUrl;
          state.accountManagerPhotoBlobUrl.request = requestReducer(
            state.accountManagerPhotoBlobUrl.request,
            fulfilled({ meta })
          );
        }
      }
    );
    builder.addCase(
      downloadAccountManagerPhotoByClientId.pending,
      (state, { meta }) => {
        state.accountManagerPhotoBlobUrl.data = undefined;
        state.accountManagerPhotoBlobUrl.request = requestReducer(
          state.accountManagerPhotoBlobUrl.request,
          pending({ meta })
        );
      }
    );
    builder.addCase(
      downloadAccountManagerPhotoByClientId.rejected,
      (state, { meta }) => {
        state.accountManagerPhotoBlobUrl.data = undefined;
        state.accountManagerPhotoBlobUrl.request = requestReducer(
          state.accountManagerPhotoBlobUrl.request,
          rejected({ meta })
        );
      }
    );
  },
});

const { actions, reducer } = slice;

export const {
  setClients,
  setIsContactAccountManagerDialogOpen,
  setContactAccountManagerDialogContentType,
} = actions;

export default reducer;
