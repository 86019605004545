import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdvertiserDto,
  AdvertiserSummaryProjectionDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getAllAdvertisers = createAsyncThunk<
  AdvertiserDto[],
  { clientId: number; advertiserIds?: number[] },
  AsyncThunkConfig
>(
  'advertiser/getAllAdvertisers',
  async ({ clientId, advertiserIds }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.advertiser.getAllAdvertisersByClientId(
        clientId,
        advertiserIds
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllAdvertisersUsingSummaryProjection = createAsyncThunk<
  AdvertiserSummaryProjectionDto[],
  { clientId: number; advertiserIds?: number[] },
  AsyncThunkConfig
>(
  'advertiser/getAllAdvertisersUsingSummaryProjection',
  async ({ clientId, advertiserIds }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.advertiser.getAllAdvertisersByClientIdUsingSummaryProjection(
          clientId,
          advertiserIds
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
