import { createSlice } from '@reduxjs/toolkit';
import {
  getAllReportsForAdvertiser,
  runQueryForBuilderReport,
  getAllReportSchedulesForAdvertiserAndBuilderReport,
  getReportScheduleHistoryByIdForAdvertiser,
} from 'features/reports/advertisers/async';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import { AdvertiserReportsState } from './types';

export const initialState: AdvertiserReportsState = {
  reports: {
    data: undefined,
    request: initialRequestState,
  },
  queryParamsForBuilderReport: undefined,
  columnNamesToQueryForBuilderReport: undefined,
  columnNamesToShowForBuilderReport: undefined,
  fieldsToQueryForBuilderReport: undefined,
  fieldsToShowForBuilderReport: undefined,
  queryResultsForBuilderReport: {
    data: undefined,
    request: initialRequestState,
  },
  schedulesForBuilderReport: {
    data: undefined,
    request: initialRequestState,
  },
  scheduleHistoryById: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    searchForBuilderReport: undefined,
    createScheduledDialog: {
      isOpen: false,
      reportId: undefined,
      queryId: undefined,
      queryParams: undefined,
    },
    deleteScheduledDialog: {
      isOpen: false,
      schedule: undefined,
    },
    scheduleHistoryDialog: {
      isOpen: false,
      scheduleId: undefined,
    },
  },
};

export const slice = createSlice({
  name: 'advertiserReports',
  initialState,
  reducers: {
    resetReports: (state) => {
      state.reports = {
        data: undefined,
        request: initialRequestState,
      };
      state.columnNamesToQueryForBuilderReport = undefined;
      state.columnNamesToShowForBuilderReport = undefined;
      state.queryParamsForBuilderReport = undefined;
      state.queryResultsForBuilderReport = {
        data: undefined,
        request: initialRequestState,
      };
    },
    setSearchForBuilderReport(state, { payload }) {
      state.ui.searchForBuilderReport = payload;
    },
    setIsCreateScheduleDialogOpen(state, { payload }) {
      state.ui.createScheduledDialog.isOpen = payload;
    },
    setCreateScheduleDialogReportId(state, { payload }) {
      state.ui.createScheduledDialog.reportId = payload;
    },
    setCreateScheduleDialogQueryId(state, { payload }) {
      state.ui.createScheduledDialog.queryId = payload;
    },
    setCreateScheduleDialogQueryParams(state, { payload }) {
      state.ui.createScheduledDialog.queryParams = payload;
    },
    setIsDeleteScheduleDialogOpen(state, { payload }) {
      state.ui.deleteScheduledDialog.isOpen = payload;
    },
    setDeleteScheduleDialogSchedule(state, { payload }) {
      state.ui.deleteScheduledDialog.schedule = payload;
    },
    setIsScheduleHistoryDialogOpen(state, { payload }) {
      state.ui.scheduleHistoryDialog.isOpen = payload;
    },
    setScheduleHistoryDialogScheduleId(state, { payload }) {
      state.ui.scheduleHistoryDialog.scheduleId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllReportsForAdvertiser.fulfilled,
      (state, { meta, payload }) => {
        if (state.reports.request.id === meta.requestId) {
          state.reports.data = payload;
          state.reports.request = requestReducer(
            state.reports.request,
            fulfilled({ meta })
          );

          state.columnNamesToQueryForBuilderReport = undefined;
          state.columnNamesToShowForBuilderReport = undefined;
          state.queryParamsForBuilderReport = undefined;
          state.queryResultsForBuilderReport = {
            data: undefined,
            request: initialRequestState,
          };
        }
      }
    );

    builder.addCase(getAllReportsForAdvertiser.pending, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllReportsForAdvertiser.rejected, (state, { meta }) => {
      state.reports.data = undefined;
      state.reports.request = requestReducer(
        state.reports.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      runQueryForBuilderReport.fulfilled,
      (state, { meta, payload }) => {
        const { arg } = meta || {};

        if (state.queryResultsForBuilderReport.request.id === meta.requestId) {
          state.columnNamesToQueryForBuilderReport = arg.columnNamesToQuery;
          state.columnNamesToShowForBuilderReport = arg.columnNamesToShow;
          state.fieldsToQueryForBuilderReport = arg.fieldsToQuery;
          state.fieldsToShowForBuilderReport = arg.fieldsToShow;
          state.queryParamsForBuilderReport = arg.queryParams;

          state.queryResultsForBuilderReport.data = payload;
          state.queryResultsForBuilderReport.request = requestReducer(
            state.queryResultsForBuilderReport.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(runQueryForBuilderReport.pending, (state, { meta }) => {
      state.columnNamesToQueryForBuilderReport = undefined;
      state.columnNamesToShowForBuilderReport = undefined;
      state.queryParamsForBuilderReport = undefined;

      state.queryResultsForBuilderReport.data = undefined;
      state.queryResultsForBuilderReport.request = requestReducer(
        state.queryResultsForBuilderReport.request,
        pending({ meta })
      );
    });

    builder.addCase(runQueryForBuilderReport.rejected, (state, { meta }) => {
      state.columnNamesToQueryForBuilderReport = undefined;
      state.columnNamesToShowForBuilderReport = undefined;
      state.queryParamsForBuilderReport = undefined;

      state.queryResultsForBuilderReport.data = undefined;
      state.queryResultsForBuilderReport.request = requestReducer(
        state.queryResultsForBuilderReport.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getAllReportSchedulesForAdvertiserAndBuilderReport.fulfilled,
      (state, { meta, payload }) => {
        if (state.schedulesForBuilderReport.request.id === meta.requestId) {
          state.schedulesForBuilderReport.data = payload;
          state.schedulesForBuilderReport.request = requestReducer(
            state.schedulesForBuilderReport.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      getAllReportSchedulesForAdvertiserAndBuilderReport.pending,
      (state, { meta }) => {
        state.schedulesForBuilderReport.data = undefined;
        state.schedulesForBuilderReport.request = requestReducer(
          state.schedulesForBuilderReport.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      getAllReportSchedulesForAdvertiserAndBuilderReport.rejected,
      (state, { meta }) => {
        state.schedulesForBuilderReport.data = undefined;
        state.schedulesForBuilderReport.request = requestReducer(
          state.schedulesForBuilderReport.request,
          rejected({ meta })
        );
      }
    );

    builder.addCase(
      getReportScheduleHistoryByIdForAdvertiser.fulfilled,
      (state, { meta, payload }) => {
        if (state.scheduleHistoryById.request.id === meta.requestId) {
          state.scheduleHistoryById.data = payload;
          state.scheduleHistoryById.request = requestReducer(
            state.scheduleHistoryById.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(
      getReportScheduleHistoryByIdForAdvertiser.pending,
      (state, { meta }) => {
        state.scheduleHistoryById.data = undefined;
        state.scheduleHistoryById.request = requestReducer(
          state.scheduleHistoryById.request,
          pending({ meta })
        );
      }
    );

    builder.addCase(
      getReportScheduleHistoryByIdForAdvertiser.rejected,
      (state, { meta }) => {
        state.scheduleHistoryById.data = undefined;
        state.scheduleHistoryById.request = requestReducer(
          state.scheduleHistoryById.request,
          rejected({ meta })
        );
      }
    );
  },
});

const { actions, reducer } = slice;

export const {
  resetReports,
  setSearchForBuilderReport,
  setIsCreateScheduleDialogOpen,
  setCreateScheduleDialogReportId,
  setCreateScheduleDialogQueryId,
  setCreateScheduleDialogQueryParams,
  setIsDeleteScheduleDialogOpen,
  setDeleteScheduleDialogSchedule,
  setIsScheduleHistoryDialogOpen,
  setScheduleHistoryDialogScheduleId,
} = actions;

export default reducer;
