import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getCustomReportsForCampaign } from 'features/reports/campaigns/async';
import { CampaignReportsState } from 'features/reports/campaigns/types';
import { onFulfilled, onPending, onRejected } from 'libs/slice';

export function customReportsCases(
  builder: ActionReducerMapBuilder<CampaignReportsState>
) {
  builder.addCase(
    getCustomReportsForCampaign.fulfilled,
    (state, { meta, payload }) => {
      onFulfilled(state.customReports, meta, meta.requestId, payload);
    }
  );

  builder.addCase(getCustomReportsForCampaign.pending, (state, { meta }) => {
    onPending(state.customReports, meta);
  });

  builder.addCase(getCustomReportsForCampaign.rejected, (state, { meta }) => {
    onRejected(state.customReports, meta);
  });
}
