import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { UserWithMetadataDto } from '@kortxio/hub-api';
import { ChallengeState } from 'features/auth/types';
import { useAuth } from 'features/auth/UseAuth';
import { userSelector } from 'features/user/selectors';
import { useAppSelector } from 'store/hooks';

export type WalledProps = PropsWithChildren & {
  // AuthWall can work in 'inverse' mode. Meaning it will keep logged users from
  // accessing pages reserved for anonymous/unauthenticated users.
  // Also known as RequireNoAuth.
  inverse?: boolean;
  // path to Login page. NOTICE: on logout user will be undefined.
  redirectPath?: string | ((user?: UserWithMetadataDto) => string);
  // path to new password page
  newPasswordPage?: string;
  // Activity Indicator
  activityIndicator?: ReactElement;
};

const RequireAuth: React.FC<WalledProps> = ({
  children,
  inverse = false,
  redirectPath = '/auth/login',
  newPasswordPage = '/auth/new-password',
  activityIndicator,
}) => {
  const { user, username, isAuthenticating, newPasswordState } = useAuth();

  // Kampus user only appears if RequireAuth is appropriately wrapped in the right context. undefined otherwise.
  const kampusUser = useAppSelector(userSelector);

  const [hideContent, setHideContent] = useState(!inverse);

  const navigate = useNavigate();

  useEffect(() => {
    // Show nothing while authenticating
    if (isAuthenticating) {
      return;
    }
    // New password handling
    if (newPasswordState === ChallengeState.Requested) {
      // new password request needs username and password again
      navigate(
        `${newPasswordPage}?username=${window.encodeURIComponent(
          username || ''
        )}`
      );
      return;
    }

    // User handling
    const hasUser = user !== undefined;
    // inverse is false and hasUser is true => shows (logged in, pass)
    // inverse is false and hasUser is false => redirects (not logged in)
    // inverse is true and hasUser is true => redirects (auth not needed)
    // inverse is true and hasUser is false => shows (auth needed)
    // after finishing authentication of course
    if (inverse === hasUser) {
      // we avoid running any code until kampusUser is not undefined.
      // removing this will cause a double redirect and the preselected redirect path not to work
      if (inverse && !kampusUser) {
        return;
      }
      setHideContent(true);
      const actualPath =
        typeof redirectPath === 'function'
          ? redirectPath(kampusUser)
          : redirectPath;
      navigate(actualPath);
    } else {
      setHideContent(false);
    }
  }, [
    user,
    username,
    kampusUser,
    inverse,
    redirectPath,
    navigate,
    isAuthenticating,
    newPasswordState,
    newPasswordPage,
  ]);

  return (
    <>
      {hideContent && activityIndicator}
      {!hideContent && children}
    </>
  );
};

export default RequireAuth;
