import React from 'react';
import WithMagicBellHmacForCurrentUser from 'components/shared/magicbell/KampusMagicBellProvider/components/WithMagicBellHmacForCurrentUser';
import KampusMagicBellProvider from 'components/shared/magicbell/KampusMagicBellProvider/KampusMagicBellProvider';
import { magicBellHmacSelector } from 'features/notification/selectors';
import { userDataSelector } from 'features/user/selectors';
import config from 'libs/config';
import { useAppSelector } from 'store/hooks';

type KampusMagicBellProviderProps = {
  fallbackWhileLoading?: React.ReactNode;
} & React.PropsWithChildren;

export default function KampusMagicBellProviderForCurrentUser({
  fallbackWhileLoading,
  children,
}: KampusMagicBellProviderProps) {
  const user = useAppSelector(userDataSelector);

  const magicBellHmac = useAppSelector(magicBellHmacSelector);

  const { email } = user || {};

  const { isEnabled } = config.magicBell;

  // will delay rendering of children until MagicBellProvider is ready
  // show children with MagicBellProvider when
  const showChildrenWithMagicBellProvider = Boolean(
    isEnabled &&
      // userEmail prop allows undefined but MagicBell API returns 422
      // 'X-MAGICBELL-USER-EMAIL' or 'X-MAGICBELL-USER-EXTERNAL-ID' header must be provided
      email !== undefined &&
      // userKey prop allows undefined but MagicBell API returns 401
      // 'X-MAGICBELL-USER-HMAC' header nor 'X-MAGICBELL-API-SECRET' header must not be undefined
      magicBellHmac !== undefined
  );

  const isLoadingMagicBellHmac = Boolean(
    isEnabled && (email === undefined || magicBellHmac === undefined)
  );

  // show children without MagicBellProvider when
  // !hasAuth
  // or config.magicBell.isEnabled is false
  // or config.magicBell.apiKey is undefined
  const showChildrenWithoutMagicBellProvider = Boolean(!isEnabled);

  return (
    <>
      <WithMagicBellHmacForCurrentUser />
      {isLoadingMagicBellHmac && fallbackWhileLoading !== undefined && (
        <>{fallbackWhileLoading}</>
      )}
      {showChildrenWithMagicBellProvider && (
        <KampusMagicBellProvider
          userEmail={email?.toLowerCase()}
          userKey={magicBellHmac}
        >
          <>{children}</>
        </KampusMagicBellProvider>
      )}
      {showChildrenWithoutMagicBellProvider && <>{children}</>}
    </>
  );
}
