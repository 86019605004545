import { useCallback, useEffect } from 'react';

export const useCtrlKeyDown = (
  callback: (event: KeyboardEvent) => void,
  activationKey: string
) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const alt = window.navigator.platform.match('Mac')
        ? event.ctrlKey
        : // On non macs, do (ctrl + shift)
          event.ctrlKey && event.altKey;
      if (activationKey === event.key && alt) {
        event.preventDefault();
        callback(event);
      }
    },
    [activationKey, callback]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};
