import Drawer from '@mui/material/Drawer';
import Rows from 'components/shared/Layout/Rows';
import KampusDrawerHeader from 'layout/components/drawer/KampusDrawerHeader';
import { MINI_VARIANT_DRAWER_WIDTH } from 'layout/constants';
import DrawerList from './DrawerList';

function MiniVariantDrawer() {
  return (
    <nav aria-label="\kampus navigation">
      <Drawer
        variant="permanent"
        sx={{
          width: MINI_VARIANT_DRAWER_WIDTH,
        }}
        PaperProps={{
          sx: {
            width: 'inherit',
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        <KampusDrawerHeader mini />
        <Rows sx={{ flex: 1, mt: 3 }}>
          <DrawerList />
        </Rows>
      </Drawer>
    </nav>
  );
}

export default MiniVariantDrawer;
