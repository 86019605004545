import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ReportOutputDto,
  ReportDataDto,
  AdvertiserReportScheduleInputDto,
  PageReportScheduleOutputDto,
  ReportScheduleHistoryDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import { RunQueryParamsForBuilderReport } from 'features/reports/advertisers/types';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';

export const getAllReportsForAdvertiser = createAsyncThunk<
  ReportOutputDto[],
  {
    clientId: number;
    advertiserId: number;
  }
>(
  'advertiserReports/getAllReportsForAdvertiser',
  async ({ clientId, advertiserId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.advertiserReports.getAllReportsForAdvertiser(
        clientId,
        advertiserId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const runQueryForBuilderReport = createAsyncThunk<
  ReportDataDto,
  RunQueryParamsForBuilderReport
>(
  'advertiserReports/runQueryForBuilderReport',
  async (
    { clientId, advertiserId, id, queryId, queryParams },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await api.advertiserReportRunner.postRunQueryForAdvertiser(
          clientId,
          advertiserId,
          id,
          queryId,
          queryParams
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
export const getAllReportSchedulesForAdvertiserAndBuilderReport =
  createAsyncThunk<
    PageReportScheduleOutputDto,
    {
      clientId: number;
      advertiserId: number;
      id?: string;
    }
  >(
    'advertiserReports/getAllReportSchedulesForAdvertiserAndBuilderReport',
    async ({ clientId, advertiserId, id }, { dispatch, rejectWithValue }) => {
      try {
        const response =
          await api.advertiserReports.getAllReportSchedulesForAdvertiser(
            clientId,
            advertiserId,
            'LOOKER',
            id
          );

        return parseApiPayload(response);
      } catch (error) {
        const apiError = toApiError(error);

        if (!apiError.isAbortedError) {
          dispatch(
            setError({
              message: apiError.message,
              uiMessage: apiError.uiMessage,
            })
          );
        }

        return rejectWithValue(apiError);
      }
    }
  );

export const createReportScheduleForAdvertiser = createAsyncThunk<
  ReportDataDto,
  {
    clientId: number;
    advertiserId: number;
    id: string;
    queryId: string;
    requestBody: AdvertiserReportScheduleInputDto;
  }
>(
  'advertiserReports/createReportScheduleForAdvertiser',
  async (
    { clientId, advertiserId, id, queryId, requestBody },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await api.advertiserReports.postReportScheduleForAdvertiser(
          clientId,
          advertiserId,
          id,
          queryId,
          requestBody
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
            uiFieldErrorMessages: apiError.uiFieldErrorMessages,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const deleteReportScheduleForAdvertiser = createAsyncThunk<
  ReportDataDto,
  {
    clientId: number;
    advertiserId: number;
    scheduleId: string;
  }
>(
  'advertiserReports/deleteReportScheduleForAdvertiser',
  async (
    { clientId, advertiserId, scheduleId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await api.advertiserReports.deleteReportScheduleForAdvertiser(
          clientId,
          advertiserId,
          scheduleId
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getReportScheduleHistoryByIdForAdvertiser = createAsyncThunk<
  ReportScheduleHistoryDto,
  {
    clientId: number;
    advertiserId: number;
    scheduleId: string;
  }
>(
  'advertiserReports/getReportScheduleHistoryByIdForAdvertiser',
  async (
    { clientId, advertiserId, scheduleId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await api.advertiserReports.getReportScheduleHistoryByIdForAdvertiser(
          clientId,
          advertiserId,
          scheduleId
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
