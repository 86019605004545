import { createSlice } from '@reduxjs/toolkit';
import { advertiserDocuments } from 'features/advertiser/slice-advertiser-documents';
import { customReportsCases } from 'features/advertiser/slice-custom-reports';
import { selectedAdvertiserCases } from 'features/advertiser/slice-selected-advertiser';
import { AdvertiserByIdState } from 'features/advertiser/types';
import { DocumentFileTypeFilterLabel } from 'features/campaign/types';
import { initialRequestState } from 'features/shared/request';

export const initialState: AdvertiserByIdState = {
  selectedAdvertiser: {
    data: undefined,
    request: initialRequestState,
  },
  customReports: {
    data: undefined,
    request: initialRequestState,
  },
  documents: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    searchForDocuments: undefined,
    fileTypeFilterLabel: 'All',
  },
};

const slice = createSlice({
  initialState,
  name: 'advertiserById',
  reducers: {
    setSearchForDocuments(state, { payload }: { payload: string | undefined }) {
      state.ui.searchForDocuments = payload;
    },
    setFileTypeFilterForDocuments(
      state,
      { payload }: { payload: DocumentFileTypeFilterLabel }
    ) {
      state.ui.fileTypeFilterLabel = payload;
    },
  },
  extraReducers: (builder) => {
    selectedAdvertiserCases(builder);
    customReportsCases(builder);
    advertiserDocuments(builder);
  },
});

const { actions, reducer } = slice;
export const { setSearchForDocuments, setFileTypeFilterForDocuments } = actions;
export default reducer;
