import { BudgetGroupDto, CampaignFileDto } from '@kortxio/hub-api';
import {
  DOCUMENT_FILE_TYPE_FILTER_LABELS,
  CAMPAIGN_TAB,
  DocumentFileTypeFilterLabel,
} from 'features/campaign/types';
import { filterNonValuesFromArray } from 'libs/array';

type FileTypeFilterMetadata = {
  includedMimeTypes?: readonly string[];
  excludedMimeTypes?: readonly string[];
};

const DOCUMENT_MIME_TYPES = [
  'text/markdown',
  'text/plain',
  'application/rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.google-apps.document',
] as const;
const IMAGE_MIME_TYPES = [
  'image/apng',
  'image/avif',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/tiff',
  'image/png',
  'image/svg+xml',
  'image/vnd.microsoft.icon',
  'image/webp',
] as const;
const PDF_MIME_TYPES = ['application/pdf'] as const;
const SPREADSHEET_MIME_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.google-apps.spreadsheet',
] as const;

export const FILE_TYPE_FILTER_METADATA: Record<
  DocumentFileTypeFilterLabel,
  FileTypeFilterMetadata
> = {
  All: {},
  Document: {
    includedMimeTypes: DOCUMENT_MIME_TYPES,
  },
  Image: {
    includedMimeTypes: IMAGE_MIME_TYPES,
  },
  Other: {
    excludedMimeTypes: [
      ...DOCUMENT_MIME_TYPES,
      ...IMAGE_MIME_TYPES,
      ...PDF_MIME_TYPES,
      ...SPREADSHEET_MIME_TYPES,
    ],
  },
  PDF: {
    includedMimeTypes: PDF_MIME_TYPES,
  },
  Spreadsheet: {
    includedMimeTypes: SPREADSHEET_MIME_TYPES,
  },
} as const;

const validCampaignTabs: string[] = Object.values(CAMPAIGN_TAB);

export const isValidCampaignTab = (value: string) =>
  validCampaignTabs.includes(value);

export const filterDocumentsByFileTypeFilterLabel = (
  documents: CampaignFileDto[],
  label: DocumentFileTypeFilterLabel
): CampaignFileDto[] => {
  const metadata = FILE_TYPE_FILTER_METADATA[label];

  let newDocuments = [...documents];

  if (metadata.includedMimeTypes) {
    newDocuments = newDocuments.filter(
      (document) =>
        document.mimeType &&
        metadata.includedMimeTypes?.includes(document.mimeType)
    );
  }

  if (metadata.excludedMimeTypes) {
    newDocuments = newDocuments.filter(
      (document) =>
        document.mimeType &&
        !metadata.excludedMimeTypes?.includes(document.mimeType)
    );
  }

  return newDocuments;
};

export const getFileLabelFromMimeType = (
  mimeType: string | undefined
): DocumentFileTypeFilterLabel => {
  if (!mimeType) {
    return DOCUMENT_FILE_TYPE_FILTER_LABELS.Other;
  }

  const matchEntry = Object.entries(FILE_TYPE_FILTER_METADATA)
    .map(([label, metadata]) => ({ label, ...metadata }))
    .find((value) => value.includedMimeTypes?.includes(mimeType));

  if (matchEntry) {
    return matchEntry.label as DocumentFileTypeFilterLabel;
  }

  return DOCUMENT_FILE_TYPE_FILTER_LABELS.Other;
};

export const mapBudgetGroupToLineItems = (budgetGroup: BudgetGroupDto) => {
  return filterNonValuesFromArray(budgetGroup.budgetGroupPlatforms).flatMap(
    (platform) =>
      filterNonValuesFromArray(platform.budgetGroupPlatformLineItems)
  );
};
