import { createSelector } from '@reduxjs/toolkit';
import {
  AdvertiserDto,
  CampaignDto,
  CognitoUserDto,
  InternalUserOutputDto,
  PageInternalUserOutputDto,
  PageStandardUserOutputDto,
  StandardUserOutputDto,
  StandardUserWithMetadataDto,
  UserWithMetadataDto,
  UserWithMetadataDtoRoleEnum,
  UserWithMetadataDtoTypeEnum,
} from '@kortxio/hub-api';
import { advertisersUsingSummaryProjectionSelector } from 'features/advertisers/selectors';
import { RequestState } from 'features/shared/request';
import {
  DialogState,
  dtoToStandardUserAsRow,
  StandardUserAsRow,
  StandardUserDialogState,
  StandardUserUiState,
  UserState,
  UserUiState,
} from 'features/user/types';
import { RootState } from 'store/types';

export const userRootSelector = (state: RootState): UserState => state.user;

export const userDataSelector = createSelector(
  userRootSelector,
  (userRoot: UserState): UserWithMetadataDto | undefined => {
    if (!userRoot) {
      return undefined;
    }

    const { user } = userRoot;

    if (!user) {
      return undefined;
    }

    const { data } = user;

    if (!data) {
      return undefined;
    }

    return data;
  }
);

export const usersRequestSelector = createSelector(
  userRootSelector,
  (userRoot: UserState): RequestState | undefined => {
    if (!userRoot) {
      return undefined;
    }

    const { user } = userRoot;

    if (!user) {
      return undefined;
    }

    const { request } = user;

    if (!request) {
      return undefined;
    }

    return request;
  }
);

export const userSelector = userDataSelector;

export const magicBellHmacForUserDataSelector = createSelector(
  userRootSelector,
  (userRoot: UserState): string | undefined => {
    return userRoot?.magicBellHmac?.data;
  }
);

export const magicBellHmacForUserRequestSelector = createSelector(
  userRootSelector,
  (userRoot: UserState): RequestState | undefined => {
    return userRoot?.magicBellHmac?.request;
  }
);

export const roleSelector = createSelector(
  userSelector,
  (user): UserWithMetadataDtoRoleEnum | undefined => {
    return user?.role;
  }
);

export const privilegesSelector = createSelector(
  userSelector,
  (user): string[] | undefined => {
    return user?.privileges ? [...user.privileges] : undefined;
  }
);

export const hasRoleWithAccessToAllAdvertisersSelector = createSelector(
  roleSelector,
  (role): boolean | undefined => {
    if (!role) {
      return undefined;
    }

    const rolesWithAccessToAllAdvertisers: UserWithMetadataDtoRoleEnum[] = [
      UserWithMetadataDtoRoleEnum.KortxAdmin,
      UserWithMetadataDtoRoleEnum.KortxStandard,
      UserWithMetadataDtoRoleEnum.Admin,
      UserWithMetadataDtoRoleEnum.PartnerSolutions,
    ];

    return rolesWithAccessToAllAdvertisers.includes(role);
  }
);

export const advertiserIdsWithAccessForUserSelector = createSelector(
  userSelector,
  hasRoleWithAccessToAllAdvertisersSelector,
  (
    user: UserWithMetadataDto | undefined,
    hasRoleWithAccessToAllAdvertisers: boolean | undefined
  ): number[] | undefined => {
    if (!user) {
      return undefined;
    }

    if (hasRoleWithAccessToAllAdvertisers) {
      return undefined;
    }

    const { type } = user ?? {};

    if (type !== UserWithMetadataDtoTypeEnum.Standard) {
      return undefined;
    }

    const standardUser = user as StandardUserWithMetadataDto;

    const { advertisers } = standardUser;

    if (!advertisers) {
      return undefined;
    }

    return advertisers
      .filter((value) => value.id !== undefined)
      .map((value) => value.id as number);
  }
);

export const hasAccessToAdvertiserSelector = (
  advertiserIdAsParam: string | undefined,
  advertiser: AdvertiserDto | undefined
) =>
  createSelector(
    advertiserIdsWithAccessForUserSelector,
    hasRoleWithAccessToAllAdvertisersSelector,
    (
      advertiserIds,
      hasRoleWithAccessToAllAdvertisers: boolean | undefined
    ): 'ALLOW' | 'DENY' | 'PENDING' => {
      if (advertiserIdAsParam === undefined || advertiser === undefined) {
        return 'PENDING';
      }

      if (advertiserIdAsParam !== advertiser?.id?.toString()) {
        return 'PENDING';
      }

      if (hasRoleWithAccessToAllAdvertisers) {
        return 'ALLOW';
      }

      // StandardUser.advertiserIds isLoading
      if (advertiserIds === undefined) {
        return 'PENDING';
      }

      const hasAccess =
        advertiser?.id !== undefined && advertiserIds.includes(advertiser.id);

      return hasAccess ? 'ALLOW' : 'DENY';
    }
  );

export const hasAccessToCampaignSelector = (
  campaignIdAsParam: string | undefined,
  campaign: CampaignDto | undefined
) =>
  createSelector(
    advertiserIdsWithAccessForUserSelector,
    hasRoleWithAccessToAllAdvertisersSelector,
    (
      advertiserIds,
      hasRoleWithAccessToAllAdvertisers: boolean | undefined
    ): 'ALLOW' | 'DENY' | 'PENDING' => {
      if (campaignIdAsParam === undefined || campaign === undefined) {
        return 'PENDING';
      }

      if (campaignIdAsParam !== campaign?.id?.toString()) {
        return 'PENDING';
      }

      if (hasRoleWithAccessToAllAdvertisers) {
        return 'ALLOW';
      }

      // StandardUser.advertiserIds isLoading
      if (advertiserIds === undefined) {
        return 'PENDING';
      }

      const { advertiser } = campaign;

      const hasAccess =
        advertiser?.id !== undefined && advertiserIds.includes(advertiser.id);

      return hasAccess ? 'ALLOW' : 'DENY';
    }
  );

export const standardUsersDataSelector = createSelector(
  userRootSelector,
  (usersRoot: UserState): PageStandardUserOutputDto | undefined =>
    usersRoot?.standardUsers?.data
);

export const standardUsersRequestSelector = createSelector(
  userRootSelector,
  (usersRoot: UserState): RequestState | undefined =>
    usersRoot?.standardUsers?.request
);

export const standardUsersSelector = createSelector(
  standardUsersDataSelector,
  (data): StandardUserOutputDto[] | undefined => data?.content
);

export const standardUserByIdSelector = (id: string) =>
  createSelector(
    standardUsersSelector,
    (standardUsers): StandardUserOutputDto | undefined => {
      return standardUsers?.find((standardUser) => standardUser?.id === id);
    }
  );

export const standardUsersAsRowsSelector = createSelector(
  standardUsersSelector,
  advertisersUsingSummaryProjectionSelector,
  (standardUsers, advertisers): StandardUserAsRow[] | undefined => {
    if (!standardUsers) {
      return undefined;
    }

    return [...standardUsers]
      .filter((standardUser) => standardUser != undefined)
      .map(
        (standardUser) =>
          dtoToStandardUserAsRow(standardUser, advertisers) as StandardUserAsRow
      )
      .sort((a, b) => {
        if (a.enabled !== b.enabled) {
          return Number(a.enabled) - Number(b.enabled);
        }

        if (!a.email) {
          return 1;
        }

        if (!b.email) {
          return -1;
        }

        return a.email.localeCompare(b.email);
      });
  }
);

export const standardUsersAsRowsWithFiltersSelector = ({
  search,
  showDisabled,
}: {
  search: string | undefined;
  showDisabled: boolean;
}) =>
  createSelector(
    standardUsersAsRowsSelector,
    (usersAsRows): StandardUserAsRow[] | undefined => {
      if (usersAsRows === undefined) {
        return undefined;
      }

      const usersAsRowsWithShowDisabledFilter = showDisabled
        ? usersAsRows
        : usersAsRows.filter((user) => user.enabled);

      if (search === undefined || search === '') {
        return usersAsRowsWithShowDisabledFilter;
      }

      return usersAsRowsWithShowDisabledFilter.filter((user) => {
        const fullName = user.fullName || '';
        const email = user.email || '';

        return (
          fullName.toLowerCase().includes(search.toLowerCase()) ||
          email.toLowerCase().includes(search.toLowerCase())
        );
      });
    }
  );

export const internalUsersDataSelector = createSelector(
  userRootSelector,
  (usersRoot: UserState): PageInternalUserOutputDto | undefined =>
    usersRoot?.internalUsers?.data
);

export const internalUsersRequestSelector = createSelector(
  userRootSelector,
  (usersRoot: UserState): RequestState | undefined =>
    usersRoot?.internalUsers?.request
);

export const internalUsersSelector = createSelector(
  internalUsersDataSelector,
  (data): InternalUserOutputDto[] | undefined => data?.content
);

export const internalUsersAsRowsSelector = createSelector(
  internalUsersSelector,
  (users) => {
    if (!users) {
      return null;
    }

    return [
      ...users.map((user) => ({
        id: user.id,
        username: user.username,
        name: `${user.firstName} ${user.lastName}`,
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        role: user.role,
        isArchived: !user.enabled,
      })),
    ].sort((a, b) => {
      // Sort by isArchived, treating undefined as "greater" than true/false
      const archivedComparison =
        Number(a.isArchived ?? true) - Number(b.isArchived ?? true);
      if (archivedComparison !== 0) return archivedComparison;

      // Sort by email, treating undefined or null as "greater" than valid strings
      return (a.email ?? '').localeCompare(b.email ?? '');
    });
  }
);

export const userUiSelector = createSelector(
  userRootSelector,
  (userRoot: UserState): UserUiState | undefined => {
    if (!userRoot) {
      return undefined;
    }

    const { ui } = userRoot;

    if (!ui) {
      return undefined;
    }

    return ui;
  }
);

export const updateAccountConfirmationDialogSelector = createSelector(
  userUiSelector,
  (ui: UserUiState | undefined): DialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { updateAccountConfirmationDialog } = ui;

    if (!updateAccountConfirmationDialog) {
      return undefined;
    }

    return updateAccountConfirmationDialog;
  }
);

export const changePasswordConfirmationDialogSelector = createSelector(
  userUiSelector,
  (ui: UserUiState | undefined): DialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { changePasswordConfirmationDialog } = ui;

    if (!changePasswordConfirmationDialog) {
      return undefined;
    }

    return changePasswordConfirmationDialog;
  }
);

export const standardUserUiSelector = createSelector(
  userUiSelector,
  (ui: UserUiState | undefined): StandardUserUiState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { standardUser } = ui;

    if (!standardUser) {
      return undefined;
    }

    return standardUser;
  }
);

export const standardUserCreateDialogSelector = createSelector(
  standardUserUiSelector,
  (ui: StandardUserUiState | undefined): DialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { createDialog } = ui;

    if (!createDialog) {
      return undefined;
    }

    return createDialog;
  }
);

export const standardUserUpdateDialogSelector = createSelector(
  standardUserUiSelector,
  (
    ui: StandardUserUiState | undefined
  ): StandardUserDialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { updateDialog } = ui;

    if (!updateDialog) {
      return undefined;
    }

    return updateDialog;
  }
);

export const standardUserManageNotificationsDialogSelector = createSelector(
  standardUserUiSelector,
  (ui: StandardUserUiState | undefined): StandardUserDialogState | undefined =>
    ui?.manageNotificationsDialog
);

export const standardUserDisableDialogSelector = createSelector(
  standardUserUiSelector,
  (
    ui: StandardUserUiState | undefined
  ): StandardUserDialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { disableDialog } = ui;

    if (!disableDialog) {
      return undefined;
    }

    return disableDialog;
  }
);

export const standardUserEnableDialogSelector = createSelector(
  standardUserUiSelector,
  (
    ui: StandardUserUiState | undefined
  ): StandardUserDialogState | undefined => {
    if (!ui) {
      return undefined;
    }

    const { enableDialog } = ui;

    if (!enableDialog) {
      return undefined;
    }

    return enableDialog;
  }
);

export const standardUserResetPasswordDialogSelector = createSelector(
  standardUserUiSelector,
  (ui: StandardUserUiState | undefined): StandardUserDialogState => {
    return ui ? ui.resetPasswordDialog : { isOpen: false, user: undefined };
  }
);

export const cognitoUserDataSelector = createSelector(
  userRootSelector,
  (userRoot: UserState): CognitoUserDto | undefined => {
    if (!userRoot) {
      return undefined;
    }

    const { cognitoUser } = userRoot;

    return cognitoUser ? cognitoUser.data : undefined;
  }
);
