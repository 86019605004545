import { Link, LinkProps } from '@mui/material';
import Typography from '@mui/material/Typography';

function NoVisitLink(props: LinkProps) {
  return (
    <Link {...props}>
      <Typography
        variant="body2"
        sx={{
          color: (theme) => theme.palette.primary.main,
          borderColor: (theme) => theme.palette.primary.main,
          borderBottomWidth: '2px',
        }}
      >
        <strong>{props.children}</strong>
      </Typography>
    </Link>
  );
}

export default NoVisitLink;
