export type CurrentUser = {
  username: string;
};

export type TitledMessage = {
  title: string;
  detail: string;
};

export enum ChallengeState {
  Idle, // challenge not encountered yet

  Requested, // challenge detected. Now requested, UI will likely show form

  Completed, // will show completion
}
