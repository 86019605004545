import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
  DRAWER_DEFAULT_Z_INDEX,
  TEMPORARY_DRAWER_WIDTH,
} from 'layout/constants';

export type TemporaryDrawerHideIconButtonProps = {
  onClick: () => void;
};

const TemporaryDrawerHideIconButton = ({
  onClick,
}: TemporaryDrawerHideIconButtonProps) => {
  return (
    <IconButton
      aria-label="hide secondary drawer"
      edge="end"
      onClick={onClick}
      sx={{
        position: 'absolute',
        zIndex: DRAWER_DEFAULT_Z_INDEX + 1,
        top: 8,
        left: `calc(${TEMPORARY_DRAWER_WIDTH} + 10px)`,
      }}
    >
      <CloseIcon
        sx={{ fontSize: 32, color: (theme) => theme.palette.common.white }}
      />
    </IconButton>
  );
};

export default TemporaryDrawerHideIconButton;
