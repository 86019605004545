import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';
import { router } from 'routes';
import { store } from 'store';
import theme from 'theme';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Amplify } from 'aws-amplify';
import config from 'libs/config';
import { initSentry } from 'libs/error/sentry';
import { initFullStory } from 'libs/fullstory';
import ReactDOM from 'react-dom/client';
import { TssCacheProvider } from 'tss-react';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: config.auth.userPoolClientId,
      userPoolId: config.auth.userPoolId,
    },
  },
});

LicenseInfo.setLicenseKey(config.mui.licenseKey);

initFullStory();
initSentry();

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <CacheProvider value={muiCache}>
        <TssCacheProvider value={tssCache}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline enableColorScheme />
              <RouterProvider router={router} />
            </LocalizationProvider>
          </ThemeProvider>
        </TssCacheProvider>
      </CacheProvider>
    </Provider>
  </React.StrictMode>
);
