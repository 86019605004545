// https://drive.google.com/drive/u/0/folders/1jBKThGU4kbepDloCV0dCcfKhyagBVQPy
import React from 'react';
import {
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import components from 'theme/components';
import { lightPalette, darkPalette } from 'theme/palettes';

export const fontFamily = [
  'ui-sans-serif',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'Noto San',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji',
].join(',');

declare module '@mui/material/styles' {
  interface TypographyVariants {
    kampus: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    kampus?: React.CSSProperties;
  }
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontWeightSemiBold?: number;
  }

  interface Typography {
    fontWeightSemiBold: number;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    kampus: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    kortxAccentBlue: PaletteColor;
    kortxAccentGreen: PaletteColor;
    kortxAccentMagenta: PaletteColor;
    kortxAccentYellow: PaletteColor;
  }
  interface PaletteOptions {
    kortxAccentBlue: PaletteColorOptions;
    kortxAccentGreen: PaletteColorOptions;
    kortxAccentMagenta: PaletteColorOptions;
    kortxAccentYellow: PaletteColorOptions;
  }
}

const options = {
  palette: lightPalette,
  components: components,
  typography: {
    fontFamily: fontFamily,
    fontWeightSemiBold: 600,
    kampus: {
      fontFamily: fontFamily,
      fontWeight: 300,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      letterSpacing: '0.1rem',
      textTransform: 'uppercase',
    },
  },
} as ThemeOptions;

const theme = responsiveFontSizes(createTheme(options));

export const darkTheme = responsiveFontSizes(
  createTheme({
    ...options,
    palette: darkPalette,
  })
);

export default theme;
