import { innerAnalyticsApi } from 'libs/gen/analyticsBaseApi';

// Api extensions located in src/libs/rtk/apiExtensions.ts
// This file enhance endpoints with tag invalidations and such.
export const api = innerAnalyticsApi.enhanceEndpoints<
  'Workspace' | 'Connection' | 'ManualConnection' | 'AirbyteConnection'
>({
  addTagTypes: [
    'Workspace',
    'Connection',
    'ManualConnection',
    'AirbyteConnection',
  ],
  endpoints: {
    listWorkspaces: {
      providesTags: (result) =>
        result
          ? [
              { type: 'Workspace', id: 'LIST' },
              ...result.map((workspace) => ({
                type: 'Workspace' as const,
                id: workspace.id,
              })),
            ]
          : // an error occurs, we still clear it
            [{ type: 'Workspace', id: 'LIST' }],
    },
    createWorkspace: (endpoint) => {
      endpoint.invalidatesTags = (result) =>
        result ? [{ type: 'Workspace', id: result.id }] : [];
    },
    updateWorkspace: (endpoint) => {
      // When a workspace is updated the cache for it is cleared
      // So we see changes in real time after saving in the Dialog.
      endpoint.invalidatesTags = (_, __, argument) => [
        { type: 'Workspace', id: argument['workspace-id'] },
      ];
    },
    deleteWorkspace: (endpoint) => {
      endpoint.invalidatesTags = (_, __, argument) => [
        { type: 'Workspace', id: argument['workspace-id'] },
      ];
    },
    // getWorkspaceForClientIdAdvertiserId:
    listConnectionsByWorkspace: {
      providesTags: (result) =>
        result
          ? [
              { type: 'Connection', id: 'LIST' },
              ...result.connections.map((conn) => ({
                type: 'Connection' as const,
                id: conn.id,
              })),
            ]
          : // an error occurs, we still clear it
            [{ type: 'Connection', id: 'LIST' }],
    },
    createConnection: (endpoint) => {
      endpoint.invalidatesTags = ['Connection'];
    },
    updateConnection: (endpoint) => {
      // When a connection is updated the cache for it is cleared
      endpoint.invalidatesTags = (_, __, argument) => [
        { type: 'Connection', id: argument['connection-id'] },
      ];
    },
    deleteConnection: (endpoint) => {
      endpoint.invalidatesTags = (_, __, argument) => [
        { type: 'Connection', id: argument['connection-id'] },
      ];
    },
    createManualConnection: (endpoint) => {
      endpoint.invalidatesTags = ['ManualConnection'];
    },
    updateManualConnectionFromWorkspace: (endpoint) => {
      endpoint.invalidatesTags = (_, __, argument) => [
        { type: 'ManualConnection', id: argument['manual-connection-id'] },
      ];
    },
    deleteManualConnectionFromWorkspace: (endpoint) => {
      endpoint.invalidatesTags = (_, __, argument) => [
        { type: 'ManualConnection', id: argument['manual-connection-id'] },
      ];
    },
    listManualConnectionsByWorkspace: {
      providesTags: (result) =>
        result
          ? [
              { type: 'ManualConnection', id: 'LIST' },
              ...result.map((conn) => ({
                type: 'ManualConnection' as const,
                id: conn.id,
              })),
            ]
          : // an error occurs, we still clear it
            [{ type: 'ManualConnection', id: 'LIST' }],
    },
    listUnlinkedManualConnections: {
      providesTags: (result) =>
        result
          ? [
              { type: 'AirbyteConnection', id: 'LIST' },
              ...result.map((conn) => ({
                type: 'AirbyteConnection' as const,
                id: conn.connectionId,
              })),
            ]
          : // an error occurs, we still clear it
            [{ type: 'AirbyteConnection', id: 'LIST' }],
    },
  },
});

// These are the hooks the app should be using since we are
export const {
  // Workspace/Connection API
  useDeleteConnectionMutation,
  useListWorkspacesQuery,
  useListConnectionsByWorkspaceQuery,
  useListDashboardsByWorkspaceQuery,
  useLazyListDashboardsByWorkspaceQuery,
  // Item/Account endpoints
  useGetAccountsQuery,
  // Google Auth
  useGetOAuthUrlQuery,
  // Connectors
  useCreateConnectionMutation,
  // Manual Connections
  useListManualConnectionsByWorkspaceQuery,
  useLazyListUnlinkedManualConnectionsQuery,
  useCreateManualConnectionMutation,
  useUpdateManualConnectionFromWorkspaceMutation,
  useDeleteManualConnectionFromWorkspaceMutation,
  // Data Sources
  useListDataSourcesByWorkspaceQuery,
  //
  useQueryWorkspaceMutation,
} = api;
