import React from 'react';
import { Alert, ThemeProvider, Typography } from '@mui/material';
import { darkTheme } from 'theme';
import { TitledMessage } from 'features/auth/types';

export type LoginErrorAlertProps = {
  info: TitledMessage;
};

const LoginInfoAlert: React.FC<LoginErrorAlertProps> = ({ info }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Alert severity="info" sx={{ display: 'flex', my: 2 }}>
        <Typography variant="body1">{info.title}</Typography>
        <Typography variant="body2">{info.detail}</Typography>
      </Alert>
    </ThemeProvider>
  );
};

export default LoginInfoAlert;
