import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getMagicBellHmacForCurrentUser = createAsyncThunk<
  string,
  void,
  AsyncThunkConfig
>(
  'notification/getMagicBellHmacForCurrentUser',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.magicBell.getMagicBellHmacForCurrentUser();

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getMagicBellHmacForUsername = createAsyncThunk<
  string,
  { username: string },
  AsyncThunkConfig
>(
  'notification/getMagicBellHmacForUsername',
  async ({ username }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.magicBell.getMagicBellHmacForUsername(
        username
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
