import { ChatBotControllerApi } from '@kortxio/assistant-api';
import {
  AdvertiserControllerApi,
  AdvertiserReportControllerApi,
  AdvertiserReportRunnerControllerApi,
  AudienceControllerApi,
  AudienceReportControllerApi,
  CampaignControllerApi,
  CampaignReportControllerApi,
  CampaignReportRunnerControllerApi,
  ClientControllerApi,
  CognitoUserControllerApi,
  Configuration,
  CustomReportingControllerApi,
  EmailControllerApi,
  FeatureFlagControllerApi,
  FileControllerApi,
  FormControllerApi,
  InternalUserControllerApi,
  InvoiceControllerApi,
  MagicBellNotificationControllerApi,
  NotificationControllerApi,
  PreferencesControllerApi,
  ProductControllerApi,
  ProvenProcessControllerApi,
  ReportControllerApi,
  StandardUserControllerApi,
  SupportControllerApi,
  UserControllerApi,
} from '@kortxio/hub-api';
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { getCognitoJwtToken } from 'features/auth/cognitoFunctions';
import { isAbortedError } from 'libs/api/helper';
import config from 'libs/config';
import { errorHandler } from 'libs/error';

const initAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: config.api.apiUrl,
  });

  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      config.headers.Authorization = await getCognitoJwtToken();

      return config;
    },
    (error: AxiosError) => {
      errorHandler.handle(error);

      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (!isAbortedError(error)) {
        errorHandler.handle(error);
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const axiosInstance = initAxiosInstance();

const apiConfiguration = new Configuration({
  basePath: config.api.apiUrl,
});

const initApi = () => {
  return {
    advertiser: new AdvertiserControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    advertiserReports: new AdvertiserReportControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    advertiserReportRunner: new AdvertiserReportRunnerControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    audiences: new AudienceControllerApi(apiConfiguration, '', axiosInstance),
    audienceReports: new AudienceReportControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    campaign: new CampaignControllerApi(apiConfiguration, '', axiosInstance),
    campaignReports: new CampaignReportControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    campaignReportRunner: new CampaignReportRunnerControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    chatbot: new ChatBotControllerApi(apiConfiguration, '', axiosInstance),
    cognitoUser: new CognitoUserControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    client: new ClientControllerApi(apiConfiguration, '', axiosInstance),
    email: new EmailControllerApi(apiConfiguration, '', axiosInstance),
    featureFlags: new FeatureFlagControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    file: new FileControllerApi(apiConfiguration, '', axiosInstance),
    forms: new FormControllerApi(apiConfiguration, '', axiosInstance),
    internalUser: new InternalUserControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    invoice: new InvoiceControllerApi(apiConfiguration, '', axiosInstance),
    magicBell: new MagicBellNotificationControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    notification: new NotificationControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    product: new ProductControllerApi(apiConfiguration, '', axiosInstance),
    provenProcess: new ProvenProcessControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    report: new ReportControllerApi(apiConfiguration, '', axiosInstance),
    standardUser: new StandardUserControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    support: new SupportControllerApi(apiConfiguration, '', axiosInstance),
    user: new UserControllerApi(apiConfiguration, '', axiosInstance),
    customReporting: new CustomReportingControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
    preference: new PreferencesControllerApi(
      apiConfiguration,
      '',
      axiosInstance
    ),
  };
};

const api = initApi();

export default api;
