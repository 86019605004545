import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ClientDto,
  StandardUserWithMetadataDto,
  UserWithMetadataDtoTypeEnum,
} from '@kortxio/hub-api';
import { useAuth } from 'features/auth/UseAuth';
import { getSelectedClientByIdWithUpdates } from 'features/client/async';
import {
  clientsSelector,
  selectedClientSelector,
} from 'features/client/selectors';
import { userSelector } from 'features/user/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

function WithSelectedClient({ children }: React.PropsWithChildren) {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { pathname } = location;

  const { clientId: clientIdAsParam } = params;

  const { user: authUser } = useAuth();

  const hasAuth = authUser !== undefined;

  const user = useAppSelector(userSelector);

  const { termsOfUseAccepted } = user ?? {};

  const clients = useAppSelector(clientsSelector);
  const selectedClient = useAppSelector(selectedClientSelector);

  const { id: selectedClientId } = selectedClient ?? {};

  // when path includes clientId param,
  // dispatch getSelectedClientByIdWithUpdates with clientId param
  useEffect(() => {
    if (hasAuth && termsOfUseAccepted && clientIdAsParam && clients) {
      const clientId = parseInt(clientIdAsParam, 10);

      const isNotFound = !clients.some(
        (value: ClientDto) => value?.id === clientId
      );

      if (isNotFound) {
        throw new Response('Not found', { status: 404 });
      }

      dispatch(getSelectedClientByIdWithUpdates({ id: clientId }));
    }
  }, [
    hasAuth,
    termsOfUseAccepted,
    clientIdAsParam,
    clients,
    navigate,
    dispatch,
  ]);

  // when User.Type === 'STANDARD' and path does not include clientId param,
  // dispatch getSelectedClientByIdWithUpdates with clientId in StandardUserWithMetadataDto
  useEffect(() => {
    if (
      hasAuth &&
      termsOfUseAccepted &&
      !clientIdAsParam &&
      !selectedClientId
    ) {
      const { type } = user ?? {};

      if (type === UserWithMetadataDtoTypeEnum.Standard) {
        const standardUser = user as StandardUserWithMetadataDto;

        const clientId = standardUser?.client?.id;

        if (clientId) {
          dispatch(getSelectedClientByIdWithUpdates({ id: clientId }));
        }
      }
    }
  }, [
    hasAuth,
    termsOfUseAccepted,
    user,
    clientIdAsParam,
    selectedClientId,
    dispatch,
  ]);

  // poll for Client updates on path change
  useEffect(() => {
    if (hasAuth && clientIdAsParam && selectedClientId) {
      const clientId = parseInt(clientIdAsParam, 10);

      if (clientId === selectedClientId) {
        dispatch(getSelectedClientByIdWithUpdates({ id: selectedClientId }));
      }
    }
  }, [hasAuth, clientIdAsParam, selectedClientId, pathname, dispatch]);

  return <>{children}</>;
}

export default WithSelectedClient;
