import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { NotificationPreferencesInputDto } from '@kortxio/hub-api';
import {
  useNotificationPreferences,
  useNotifications,
} from '@magicbell/react-headless';
import MagicBellInboxMenu from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInbox/components/MagicBellInboxMenu';
import MagicBellInboxMenuList from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInbox/components/MagicBellInboxMenuList';
import MagicBellInboxPreferencesList from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInbox/components/MagicBellInboxPreferencesMenuList';
import MagicBellInboxIcon from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInboxIcon';
import api from 'libs/api';

const useStyles = makeStyles()((theme) => ({
  headerTypography: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  headerElements: {
    color: theme.palette.grey[400],
    background: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function MagicBellInbox({
  text,
  tooltipText,
}: {
  text?: ReactNode;
  tooltipText?: ReactNode;
}) {
  const { classes } = useStyles();

  const menuRef = useRef<HTMLDivElement | null>(null);

  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(
    null
  );

  const isMenuOpen = useMemo(() => Boolean(buttonElement), [buttonElement]);

  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);

  const store = useNotifications();

  const {
    unreadCount = 0,
    markAllAsSeen = () => null,
    markAllAsRead = () => null,
    fetch = () => null,
  } = store ?? {};

  const resetNotificationsScroller = useCallback(async () => {
    if (menuRef && menuRef.current && menuRef.current.scrollTo) {
      menuRef.current?.scrollTo(0, 0);
    }

    await fetch({ page: 1 }, { reset: true });
  }, [fetch]);

  const { fetch: getAllNotificationPreferences } = useNotificationPreferences();

  const onClickMenuButton = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setButtonElement(event.currentTarget);

      markAllAsSeen();

      await getAllNotificationPreferences();
    },
    [getAllNotificationPreferences, markAllAsSeen]
  );

  const onClose = useCallback(() => {
    setButtonElement(null);
  }, []);

  const onExited = useCallback(async () => {
    setIsPreferencesOpen(false);

    await markAllAsSeen();
    await resetNotificationsScroller();
  }, [resetNotificationsScroller, markAllAsSeen]);

  const onClickMarkAllAsRead = useCallback(() => {
    markAllAsRead();
  }, [markAllAsRead]);

  const handleUpdatePreferences = async (
    requestBody: NotificationPreferencesInputDto
  ) => {
    return await api.notification.updateCurrentUserNotificationPreferences(
      requestBody
    );
  };

  return (
    <>
      <Tooltip
        title={tooltipText !== undefined ? tooltipText : null}
        placement="right"
      >
        <Button
          id="notification-menu-button"
          aria-controls={isMenuOpen ? 'notification-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={onClickMenuButton}
          size="large"
          sx={{
            justifyContent: 'start',
            color: 'inherit',
            flex: 1,
          }}
        >
          <MagicBellInboxIcon badgeContent={unreadCount} />
          {text !== undefined && text}
        </Button>
      </Tooltip>
      <MagicBellInboxMenu
        anchorEl={buttonElement}
        open={isMenuOpen}
        onClose={onClose}
        menuRef={menuRef}
        TransitionProps={{
          onExited: onExited,
        }}
      >
        <Box height="100%">
          {!isPreferencesOpen && (
            <>
              <Box
                height="15%"
                pt={2}
                pr={1}
                pl={3}
                pb={2}
                className={classes.headerElements}
              >
                <Typography variant="h6" className={classes.headerTypography}>
                  Inbox
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box pr={1}>
                    <Button
                      size="small"
                      color="inherit"
                      onClick={onClickMarkAllAsRead}
                    >
                      Mark all as read
                    </Button>
                  </Box>
                  <IconButton
                    onClick={() => setIsPreferencesOpen(true)}
                    color="inherit"
                  >
                    <SettingsIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <MagicBellInboxMenuList
                height="80%"
                isMenuOpen={isMenuOpen}
                menuRef={menuRef}
              />
            </>
          )}
          {isPreferencesOpen && (
            <>
              <Box
                pt={2}
                pr={1}
                pl={3}
                pb={2}
                className={classes.headerElements}
              >
                <Typography variant="h6" className={classes.headerTypography}>
                  Preferences
                </Typography>
                <IconButton
                  onClick={() => setIsPreferencesOpen(false)}
                  color="inherit"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider />
              <MagicBellInboxPreferencesList
                handleCloseMenu={onClose}
                handleUpdatePreferences={handleUpdatePreferences}
              />
            </>
          )}
        </Box>
      </MagicBellInboxMenu>
    </>
  );
}
