import { RefObject, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box/Box';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { useNotifications } from '@magicbell/react-headless';
import DocumentSVG from 'components/shared/DocumentSVG';
import HubMagicBellInboxMenuListItem from 'components/shared/magicbell/MagicBellInboxRegistry/components/MagicBellInbox/components/MagicBellInboxMenuListItem';
import InfiniteScroll from 'react-infinite-scroller';

const useStyles = makeStyles()({
  inifiniteScroll: {
    width: '100%',
    height: '100%',
  },
});

export type MagicBellInboxMenuListProps = BoxProps & {
  isMenuOpen: boolean;
  menuRef: RefObject<HTMLDivElement | null>;
};

export default function MagicBellInboxMenuList({
  isMenuOpen,
  menuRef,
  ...props
}: MagicBellInboxMenuListProps) {
  const { classes } = useStyles();

  const store = useNotifications();

  const {
    hasNextPage = false,
    fetchNextPage = () => null,
    notifications = [],
  } = store ?? {};

  const isEmpty = useMemo(() => notifications.length === 0, [notifications]);

  const handleLoadMore = useCallback(() => {
    if (isMenuOpen) {
      fetchNextPage();
    }
  }, [isMenuOpen, fetchNextPage]);

  const EmptyNotificationState = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <DocumentSVG message="No Notifications" />
    </Box>
  );

  return (
    <Box {...props} display="flex" justifyContent="center" alignItems="center">
      <InfiniteScroll
        pageStart={0}
        hasMore={hasNextPage}
        loadMore={handleLoadMore}
        useWindow={false}
        getScrollParent={() => menuRef?.current}
        className={classes.inifiniteScroll}
        loader={
          <Box
            key={0}
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={0.25}
          >
            <Typography variant="subtitle2" color="textSecondary">
              Loading...
            </Typography>
          </Box>
        }
      >
        {isEmpty ? (
          <EmptyNotificationState />
        ) : (
          <MenuList dense>
            {notifications.map((notification) => (
              <HubMagicBellInboxMenuListItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </MenuList>
        )}
      </InfiniteScroll>
    </Box>
  );
}
