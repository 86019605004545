import {
  addDays,
  addMonths,
  addYears,
  compareAsc,
  compareDesc,
  differenceInDays,
  differenceInMonths,
  format,
  formatDistance,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { isSimpleDateFormatRegex } from 'libs/string';

export const formatDateSimpleMonthDay = (
  date: number | string | Date | undefined | null
): string | undefined => {
  if (date == undefined) {
    return undefined;
  }

  const isNumber = typeof date === 'number';

  if (isNumber) {
    return format(new Date(date), 'MMM dd');
  }

  const isDate = date instanceof Date;

  if (isDate) {
    return format(date, 'MMM dd');
  }

  return format(parseISO(date), 'MMM dd');
};

export const formatDateSimple = (
  date: number | string | Date | undefined | null
): string | undefined => {
  if (date == undefined) {
    return undefined;
  }

  const isNumber = typeof date === 'number';

  if (isNumber) {
    return format(new Date(date), 'MM/dd/yyyy');
  }

  const isDate = date instanceof Date;

  if (isDate) {
    return format(date, 'MM/dd/yyyy');
  }

  return format(parseISO(date), 'MM/dd/yyyy');
};

export const formatDateSimpleWithYearMontOnly = (
  date: number | string | Date | undefined
): string | undefined => {
  if (date === undefined) {
    return undefined;
  }

  const isNumber = typeof date === 'number';

  if (isNumber) {
    return format(new Date(date), 'MM/yyyy');
  }

  const isDate = date instanceof Date;

  if (isDate) {
    return format(date, 'MM/yyyy');
  }

  return format(parseISO(date), 'MM/yyyy');
};

// https://en.wikipedia.org/wiki/ISO_8601
export const formatDateSimpleISO = (
  date: string | Date | undefined
): string | undefined => {
  if (date === undefined) {
    return undefined;
  }

  const isDate = date instanceof Date;

  if (isDate) {
    return format(date, 'yyyy-MM-dd');
  }

  return format(parseISO(date), 'yyyy-MM-dd');
};

export const formatDateSimpleISOQuick = (date: Date): string => {
  const month = date.getMonth() + 1;
  const twoDigitMonth = month < 10 ? `0${month}` : month;
  const dayOfMonth = date.getDate();
  const twoDigitDayOfMonth = dayOfMonth < 10 ? `0${dayOfMonth}` : dayOfMonth;

  return `${date.getFullYear()}-${twoDigitMonth}-${twoDigitDayOfMonth}`;
};

export const formatDateNoYear = (
  date: string | Date | undefined
): string | undefined => {
  if (date === undefined) {
    return undefined;
  }

  const isDate = date instanceof Date;

  if (isDate) {
    return format(date, 'MM/dd');
  }

  return format(parseISO(date), 'MM/dd');
};

export const formatDateTimeDistance = (date?: number | null) => {
  if (!date) {
    return null;
  }

  return formatDistance(new Date(date), new Date(), { addSuffix: true });
};

export const formatDateTime = (date: number | string | Date | undefined) => {
  if (!date) {
    return null;
  }

  const isNumber = typeof date === 'number';

  if (isNumber) {
    return format(new Date(date), 'MMMM d, yyyy h:mm a XXX');
  }

  const isDate = date instanceof Date;

  if (isDate) {
    return format(date, 'MMMM d, yyyy h:mm a XXX');
  }

  return format(parseISO(date), 'MMMM d, yyyy h:mm a XXX');
};

export const compareDateAsc = (
  value: string | Date | undefined,
  valueToCompare: string | Date | undefined
): number => {
  const hasValue = value !== undefined;
  const hasValueToCompare = valueToCompare !== undefined;

  if (!hasValue && !hasValueToCompare) {
    return 0;
  }

  if (hasValue && !hasValueToCompare) {
    return 1;
  }

  if (!hasValue && hasValueToCompare) {
    return -1;
  }

  return compareAsc(
    convertToDate(value as string | Date),
    convertToDate(valueToCompare as string | Date)
  );
};

export const compareDateDesc = (
  value: string | Date | undefined,
  valueToCompare: string | Date | undefined
): number => {
  const hasValue = value !== undefined;
  const hasValueToCompare = valueToCompare !== undefined;

  if (!hasValue && !hasValueToCompare) {
    return 0;
  }

  if (hasValue && !hasValueToCompare) {
    return -1;
  }

  if (!hasValue && hasValueToCompare) {
    return 1;
  }

  return compareDesc(
    convertToDate(value as string | Date),
    convertToDate(valueToCompare as string | Date)
  );
};

export const getAllDaysInRange = (startDate: Date, endDate: Date): Date[] => {
  const totalDays = differenceInDays(endDate, startDate);

  return [...new Array(totalDays + 1).keys()].map((index) =>
    addDays(startDate, index)
  );
};

export const getAllMonthsInRange = (startDate: Date, endDate: Date): Date[] => {
  const totalMonths = differenceInMonths(endDate, startDate);

  return [...new Array(totalMonths + 1).keys()].map((index) => {
    return startOfMonth(addMonths(startDate, index));
  });
};

export const isValidDateString = (value: string): boolean => {
  return value.match(isSimpleDateFormatRegex) !== null;
};

export const safeConvertToDate = (value?: string | Date): Date | undefined => {
  if (!value) {
    return undefined;
  }

  return convertToDate(value);
};

export const convertToDate = (value: string | Date): Date => {
  return typeof value === 'string'
    ? parseISO(value as string)
    : (value as Date);
};

// 1 is Monday
export const formatWeekDayFromNumber = (number: number | undefined): string => {
  if (number === undefined || number < 1 || 7 < number) {
    return '';
  }

  return [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ][number - 1];
};

// 1 is the first of the month
export const formatMonthDayFromNumber = (
  number: number | undefined
): string => {
  if (number === undefined) {
    return '';
  }

  if (number > 3 && number < 21) {
    return `${number}th`;
  }

  switch (number % 10) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const getOneYearFromToday = (): Date => {
  return startOfDay(addYears(new Date(), -1));
};
