import { ActionReducerMapBuilder, Draft } from '@reduxjs/toolkit';
import { getSelectedAdvertiserById } from 'features/advertiser/async';
import { AdvertiserByIdState } from 'features/advertiser/types';
import { onFulfilled, onPending, onRejected } from 'libs/slice';

export function selectedAdvertiserCases(
  builder: ActionReducerMapBuilder<AdvertiserByIdState>
) {
  builder.addCase(
    getSelectedAdvertiserById.fulfilled,
    (state: Draft<AdvertiserByIdState>, { payload, meta }) => {
      onFulfilled(state.selectedAdvertiser, meta, meta.requestId, payload);
    }
  );

  builder.addCase(getSelectedAdvertiserById.pending, (state, { meta }) => {
    onPending(state.selectedAdvertiser, meta);
  });

  builder.addCase(getSelectedAdvertiserById.rejected, (state, { meta }) => {
    onRejected(state.selectedAdvertiser, meta);
  });
}
