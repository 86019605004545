/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import { getIsRichMediaActive } from 'features/feature-flags/async';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import { FeatureFlagsState, FeatureFlagState } from './types';

const initialFlagState: FeatureFlagState = {
  isActive: undefined,
  request: initialRequestState,
};

const initialState: FeatureFlagsState = {
  richMedia: initialFlagState,
};

const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getIsRichMediaActive.fulfilled,
      (state, { meta, payload }) => {
        if (state.richMedia.request.id === meta.requestId) {
          state.richMedia.isActive = payload;
          state.richMedia.request = requestReducer(
            state.richMedia.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getIsRichMediaActive.pending, (state, { meta }) => {
      state.richMedia.isActive = undefined;
      state.richMedia.request = requestReducer(
        state.richMedia.request,
        pending({ meta })
      );
    });

    builder.addCase(getIsRichMediaActive.rejected, (state, { meta }) => {
      state.richMedia.isActive = undefined;
      state.richMedia.request = requestReducer(
        state.richMedia.request,
        rejected({ meta })
      );
    });
  },
});

const { reducer } = slice;

export default reducer;
