import { createSlice } from '@reduxjs/toolkit';
import {
  getUsers,
  createUser,
  updateUser,
  disableUser,
  enableUser,
} from 'features/admin-user-management/async';
import {
  AdminUserManagementState,
  DialogState,
} from 'features/admin-user-management/types';
import {
  requestReducer,
  initialRequestState,
  fulfilled,
  pending,
  rejected,
} from 'features/shared/request';
import { ApiError } from 'libs/api/types';

const initialAddDialogState: DialogState = {
  isOpen: false,
  request: initialRequestState,
};

const initialEditDialogState: DialogState = {
  isOpen: false,
  user: undefined,
  request: initialRequestState,
};

const initialDisableDialogState: DialogState = {
  isOpen: false,
  user: undefined,
  request: initialRequestState,
};

const initialEnableDialogState: DialogState = {
  isOpen: false,
  user: undefined,
  request: initialRequestState,
};

export const initialDialogState = {
  add: initialAddDialogState,
  edit: initialEditDialogState,
  disable: initialDisableDialogState,
  enable: initialEnableDialogState,
};

export const initialState: AdminUserManagementState = {
  showArchived: false,
  search: '',
  users: {
    data: undefined,
    request: initialRequestState,
  },
  advertisers: {
    data: undefined,
    request: initialRequestState,
  },
  dialog: initialDialogState,
};

const slice = createSlice({
  name: 'adminUserManagement',
  initialState,
  reducers: {
    setShowArchived(state, { payload: showArchived }) {
      state.showArchived = showArchived;
    },
    setSearch(state, { payload: search }) {
      state.search = search;
    },
    setIsAddDialogOpen(state, { payload: isOpen }) {
      state.dialog.add.isOpen = isOpen;
    },
    resetAddDialog(state) {
      state.dialog.add = initialAddDialogState;
    },
    setIsEditDialogOpen(state, { payload: isOpen }) {
      state.dialog.edit.isOpen = isOpen;
    },
    setEditDialogUser(state, { payload: user }) {
      state.dialog.edit.user = user;
    },
    resetEditDialog(state) {
      state.dialog.edit = initialEditDialogState;
    },
    setIsDisableDialogOpen(state, { payload: isOpen }) {
      state.dialog.disable.isOpen = isOpen;
    },
    setDisableDialogUser(state, { payload: user }) {
      state.dialog.disable.user = user;
    },
    resetDisableDialog(state) {
      state.dialog.disable = initialDisableDialogState;
    },
    setIsEnableDialogOpen(state, { payload: isOpen }) {
      state.dialog.enable.isOpen = isOpen;
    },
    setEnableDialogUser(state, { payload: user }) {
      state.dialog.enable.user = user;
    },
    resetEnableDialog(state) {
      state.dialog.enable = initialEnableDialogState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, { meta, payload: users }) => {
      if (state.users.request.id === meta.requestId) {
        state.users.data = users;
        state.users.request = requestReducer(
          state.users.request,
          fulfilled({ meta })
        );
      }
    });
    builder.addCase(getUsers.pending, (state, { meta }) => {
      state.users.data = undefined;
      state.users.request = requestReducer(
        state.users.request,
        pending({ meta })
      );
    });
    builder.addCase(getUsers.rejected, (state, { meta }) => {
      state.users.data = undefined;
      state.users.request = requestReducer(
        state.users.request,
        rejected({ meta })
      );
    });
    builder.addCase(createUser.fulfilled, (state, { meta }) => {
      if (state.dialog.add.request.id === meta.requestId) {
        state.dialog.add.isOpen = false;
        state.dialog.add.request = requestReducer(
          state.dialog.add.request,
          fulfilled({ meta })
        );
      }
    });
    builder.addCase(createUser.pending, (state, { meta }) => {
      state.dialog.add.request = requestReducer(
        state.dialog.add.request,
        pending({ meta })
      );
    });
    builder.addCase(createUser.rejected, (state, { meta, payload }) => {
      if (!(payload as ApiError).uiFieldErrorMessages) {
        state.dialog.add.isOpen = false;
      }

      state.dialog.add.request = requestReducer(
        state.dialog.add.request,
        rejected({ meta })
      );
    });
    builder.addCase(updateUser.fulfilled, (state, { meta }) => {
      if (state.dialog.edit.request.id === meta.requestId) {
        state.dialog.edit.isOpen = false;
        state.dialog.edit.request = requestReducer(
          state.dialog.edit.request,
          fulfilled({ meta })
        );
      }
    }),
      builder.addCase(updateUser.pending, (state, { meta }) => {
        state.dialog.edit.request = requestReducer(
          state.dialog.edit.request,
          pending({ meta })
        );
      }),
      builder.addCase(updateUser.rejected, (state, { meta, payload }) => {
        if (!(payload as ApiError).uiFieldErrorMessages) {
          state.dialog.edit.isOpen = false;
        }

        state.dialog.edit.request = requestReducer(
          state.dialog.edit.request,
          rejected({ meta })
        );
      }),
      builder.addCase(disableUser.fulfilled, (state, { meta }) => {
        if (state.dialog.disable.request.id === meta.requestId) {
          state.dialog.disable.isOpen = false;
          state.dialog.disable.request = requestReducer(
            state.dialog.disable.request,
            fulfilled({ meta })
          );
        }
      }),
      builder.addCase(disableUser.pending, (state, { meta }) => {
        state.dialog.disable.request = requestReducer(
          state.dialog.disable.request,
          pending({ meta })
        );
      }),
      builder.addCase(disableUser.rejected, (state, { meta }) => {
        state.dialog.disable.isOpen = false;
        state.dialog.disable.request = requestReducer(
          state.dialog.disable.request,
          rejected({ meta })
        );
      }),
      builder.addCase(enableUser.fulfilled, (state, { meta }) => {
        if (state.dialog.enable.request.id === meta.requestId) {
          state.dialog.enable.isOpen = false;
          state.dialog.enable.request = requestReducer(
            state.dialog.enable.request,
            fulfilled({ meta })
          );
        }
      }),
      builder.addCase(enableUser.pending, (state, { meta }) => {
        state.dialog.enable.request = requestReducer(
          state.dialog.enable.request,
          pending({ meta })
        );
      }),
      builder.addCase(enableUser.rejected, (state, { meta }) => {
        state.dialog.enable.isOpen = false;
        state.dialog.enable.request = requestReducer(
          state.dialog.enable.request,
          rejected({ meta })
        );
      });
  },
});

const { actions, reducer } = slice;

export const {
  setShowArchived: setInternalUserShowArchived,
  setSearch: setInternalUserSearch,
  setIsAddDialogOpen: setInternalUserIsAddDialogOpen,
  resetAddDialog: resetInternalUserAddDialog,
  setIsEditDialogOpen: setIsInternalUserEditDialogOpen,
  setEditDialogUser: setInternalUserEditDialogUser,
  resetEditDialog: resetInternalUserEditDialog,
  setIsDisableDialogOpen: setIsInternalUserDisableDialogOpen,
  setDisableDialogUser: setInternalUserDisableDialogUser,
  resetDisableDialog: resetInternalUserDisableDialog,
  setIsEnableDialogOpen: setIsInternalUserEnableDialogOpen,
  setEnableDialogUser: setInternalUserEnableDialogUser,
  resetEnableDialog: resetInternalUserEnableDialog,
} = actions;

export default reducer;
