import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import TemporaryDrawerHideIconButton from 'components/shared/SecondaryDrawer/TemporaryDrawerHideIconButton';
import KampusDrawerHeader from 'layout/components/drawer/KampusDrawerHeader';
import { TEMPORARY_DRAWER_WIDTH } from 'layout/constants';
import { useLayoutContext } from 'layout/context';
import DrawerList from './DrawerList';

function TemporaryDrawer() {
  const { isTemporaryDrawerOpen: isOpen, setIsTemporaryDrawerOpen: setIsOpen } =
    useLayoutContext();

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && <TemporaryDrawerHideIconButton onClick={onClose} />}
      <Drawer open={isOpen} onClose={onClose} variant="temporary" anchor="left">
        <Box
          component="nav"
          aria-label="\kampus navigation"
          sx={{
            flex: 1,
            width: TEMPORARY_DRAWER_WIDTH,
            bgcolor: (theme) => theme.palette.secondary.main,
          }}
        >
          <KampusDrawerHeader />
          <Box mt={0.5}>
            <DrawerList />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default TemporaryDrawer;
