import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import Rows from 'components/shared/Layout/Rows';
import LoginErrorAlert from 'features/auth/Alerts/LoginErrorAlert';
import LoginInfoAlert from 'features/auth/Alerts/LoginInfoAlert';
import PasswordResetCompleted from 'features/auth/ForgotPassword/PasswordResetCompleted';
import RequestCodeForm from 'features/auth/ForgotPassword/RequestCodeForm';
import SubmitCodePasswordForm from 'features/auth/ForgotPassword/SubmitCodePasswordForm';
import { ChallengeState } from 'features/auth/types';
import { useAuth } from 'features/auth/UseAuth';

const completedInfo = {
  title: 'Password reset successful!',
  detail: 'You can use your new password in the Login screen now.',
};

const ForgotPassword = () => {
  const {
    passwordRecoveryState,
    resetPasswordRecovery,
    submitPasswordRecovery,
    requestPasswordRecovery,
    error,
    isBusy,
  } = useAuth();

  const [searchParams] = useSearchParams();
  const codeFromUrl = searchParams.get('code');
  const usernameFromUrl = searchParams.get('user');
  const [username, setUsername] = useState(usernameFromUrl ?? '');
  const [code, setCode] = useState(codeFromUrl ?? '');
  const [password, setPassword] = useState('');

  // if we just loaded the page we start from the top.
  useEffect(() => {
    resetPasswordRecovery();
    // HACK: but if you do anything else this will cause Challenge States to not move.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it was gonna be idle, but we have a code, we go to Requested.
  const state =
    passwordRecoveryState == ChallengeState.Idle && codeFromUrl
      ? ChallengeState.Requested
      : passwordRecoveryState;

  const currentForm = useMemo(() => {
    switch (state) {
      case ChallengeState.Idle:
        setPassword('');
        setCode('');
        return (
          <RequestCodeForm
            busy={isBusy}
            username={username}
            setUsername={setUsername}
            onSubmit={() => requestPasswordRecovery(username)}
          />
        );
      case ChallengeState.Requested:
        return (
          <SubmitCodePasswordForm
            busy={isBusy}
            code={code}
            setCode={setCode}
            setOuterPassword={setPassword}
            onSubmit={() => submitPasswordRecovery(username, code, password)}
          />
        );
      default:
        return <PasswordResetCompleted />;
    }
  }, [
    state,
    username,
    code,
    password,
    isBusy,
    requestPasswordRecovery,
    submitPasswordRecovery,
  ]);

  let subtitle: string;
  if (state === ChallengeState.Requested) {
    subtitle =
      code.length === 0 ? 'Type the code you received' : 'Pick a new password';
  } else {
    subtitle =
      'Please enter the email address associated with your account and we will send you a reset code';
  }

  return (
    <Rows sx={{ width: '100%' }}>
      <Typography
        color="white"
        variant="h5"
        sx={{ fontWeight: '900', pb: 1.5 }}
      >
        Forgot your password?
      </Typography>
      <Typography color="white" variant="body1">
        {subtitle}
      </Typography>
      {currentForm}
      {!error && state === ChallengeState.Completed && (
        <LoginInfoAlert info={completedInfo} />
      )}
      {error && <LoginErrorAlert error={error} />}
    </Rows>
  );
};

export default ForgotPassword;
