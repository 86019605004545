import streamSaver from 'streamsaver';

streamSaver.mitm =
  window.location.hostname === 'localhost'
    ? `http://${window.location.hostname}:${window.location.port}/streamsaver/mitm.html`
    : `https://${window.location.hostname}/streamsaver/mitm.html`;

export const blobToBlobURL = (blob: Blob, mimeType?: string) => {
  const blobWithMimeType = new Blob([blob], {
    type: mimeType ?? 'application/octet-stream',
  });

  return (window.URL || window.webkitURL).createObjectURL(blobWithMimeType);
};

export const openBlobURLInNewWindow = (blobURL: string) => {
  window.open(blobURL);

  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 3_600_000 * 4);
};

export const openBlobInNewWindow = (blob: Blob, mimeType?: string) => {
  const blobURL = blobToBlobURL(blob, mimeType);

  openBlobURLInNewWindow(blobURL);
};

export const downloadReadableStream = async (
  readableStream: ReadableStream | null,
  filename: string
) => {
  if (readableStream === null) {
    throw new Error('ReadableStream must not null');
  }

  const fileStream = streamSaver.createWriteStream(filename);

  if (window.WritableStream && readableStream.pipeTo) {
    await readableStream.pipeTo(fileStream);

    return;
  }

  // if the WritableStream is unsupported, use polyfill
  // https://caniuse.com/mdn-api_writablestream
  if (!window.WritableStream) {
    const { WritableStream } = await import('web-streams-polyfill/es2018');

    streamSaver.WritableStream =
      WritableStream as typeof streamSaver.WritableStream;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).writer = fileStream.getWriter();

  const reader = readableStream.getReader();

  const writeToStream = () =>
    reader.read().then((readerResponse) =>
      readerResponse.done
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).writer.close()
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).writer.write(readerResponse.value).then(writeToStream)
    );

  await writeToStream();
};

export const downloadBlob = async (blob: Blob | null, filename: string) => {
  if (blob === null) {
    throw new Error('Blob must not null');
  }

  await downloadReadableStream(blob.stream(), filename);
};

export const parseFilenameFromContentDispositionHeader = (
  value: string | null
) => {
  if (value === null) {
    throw new Error('Content Disposition header must not null');
  }

  const hasAttachment = value && value.includes('attachment');

  if (!hasAttachment) {
    throw new Error('Content Disposition header must have attachment');
  }

  const filenameRegex = /filename[^\n;=]*=((["']).*?\2|[^\n;]*)/;
  const matches = filenameRegex.exec(value);
  const hasFilename = matches && Array.isArray(matches) && matches.length >= 2;

  if (!hasFilename) {
    throw new Error('Content Disposition header must have attachment filename');
  }

  return matches[1].replace(/["']/g, '');
};

export const htmlElementToBlob = async (element: HTMLElement) => {
  const { toBlob } = await import('html-to-image');

  return await toBlob(element, { backgroundColor: 'white', cacheBust: true });
};

export const blobToPDFAsBlob = async (
  blob: Blob | null,
  options: {
    format: 'PNG' | 'JPEG';
    width: number;
    height: number;
    margin: number;
  }
) => {
  if (blob === null) {
    throw new Error('Blob must not null');
  }

  const { jsPDF } = await import('jspdf');

  const pdf = new jsPDF({
    unit: 'px',
    compress: true,
  });

  const { format, width, height, margin } = options ?? {};

  const mimeType = format === 'PNG' ? 'image/png' : 'image/jpeg';

  const blobURL = blobToBlobURL(blob, mimeType);

  // The size ratio of our component
  const ratio = width / height;

  // Letter page size is 8.5 x 11 inches, here in pixels
  const letterWidth = 8.5 * 70;

  pdf.internal.pageSize.width = letterWidth;
  // Forces width to letter-like, height to fit
  pdf.internal.pageSize.height = letterWidth / ratio;

  const outputWidth = letterWidth - margin * 2;
  const outputHeight = outputWidth / ratio;

  pdf.addImage(blobURL, format, margin, margin, outputWidth, outputHeight);

  return pdf.output('blob') as Blob;
};
