import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  StandardUserWithMetadataDto,
  UserWithMetadataDtoTypeEnum,
} from '@kortxio/hub-api';
import Filler from 'components/shared/Layout/Filler';
import Rows from 'components/shared/Layout/Rows';
import AutoCompleteClientSelect from 'features/client/components/AutoCompleteClientSelect';
import { userSelector } from 'features/user/selectors';
import { useKampusRoutes } from 'routes/hooks';
import { useAppSelector } from 'store/hooks';

function ClientSelect() {
  const navigate = useNavigate();
  const { routeHome } = useKampusRoutes();

  const user = useAppSelector(userSelector);

  const { type: typeOfUser } = user ?? {};

  const { firstName } = user || {};

  useEffect(() => {
    if (typeOfUser === UserWithMetadataDtoTypeEnum.Standard) {
      const standardUser = user as StandardUserWithMetadataDto;

      const { client } = standardUser;

      if (client && routeHome) {
        navigate(routeHome);
      }
    }
  }, [user, typeOfUser, navigate, routeHome]);

  return (
    <Rows sx={{ height: 500, justifyContent: 'center' }}>
      {user && (
        <Rows sx={{ flex: 1 }}>
          <Filler />
          <Typography variant="h4" color="white" sx={{ pb: 3 }}>
            Hi {firstName}!
          </Typography>
          <AutoCompleteClientSelect />
          <Typography
            variant="body2"
            color="white"
            sx={{ py: 2, opacity: 0.5 }}
          >
            <small>
              You can switch to any client, any time, using the profile menu.
            </small>
          </Typography>
          <Filler />
        </Rows>
      )}
    </Rows>
  );
}

export default ClientSelect;
