import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import RequireAccessToAdvertiser from 'features/advertiser/components/RequireAccessToAdvertiser';
import WithSelectedAdvertiser from 'features/advertiser/components/WithSelectedAdvertiser';
import AuthLayout from 'features/auth/AuthLayout';
import { loginRedirectUrl } from 'features/auth/functions';
import RequireAuth from 'features/auth/RequireAuth';
import ErrorBackdrop from 'features/error/components/ErrorBackdrop';
import KampusContextProvider from 'features/kampus/context/KampusContextProvider';
import KampusUserProvider from 'features/kampus/KampusUserProvider';
import RequireTermsOfUseAccepted from 'features/user/components/RequireTermsOfUseAccepted';
import Layout from 'layout/index';
import config from 'libs/config';
import { base64ToUrl, urlToBase64 } from 'libs/string';
import ForgotPassword from 'pages/auth/forgot-password';
import Login from 'pages/auth/login';
import NewPassword from 'pages/auth/new-password';
import ClientSelect from 'pages/client-select';
import Error from 'pages/error';
import Index from 'pages/index';
import NotFound from 'pages/not-found';
import TermsOfUse from 'pages/terms-of-use';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { lazyRouteFunction } from './helper';

const routes = [
  {
    errorElement: <Error />,
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <KampusUserProvider>
          <KampusContextProvider>
            <ErrorBackdrop />
            <Outlet />
          </KampusContextProvider>
        </KampusUserProvider>
      </QueryParamProvider>
    ),
    children: [
      {
        path: '/auth',
        element: (
          <RequireAuth
            inverse
            redirectPath={(hubUser) => {
              return hubUser
                ? loginRedirectUrl(
                    hubUser,
                    base64ToUrl(window.location.hash.substring(1))
                  )
                : '/';
            }}
          >
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          </RequireAuth>
        ),
        children: [
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'new-password',
            element: <NewPassword />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: 'client-select',
        element: (
          <RequireAuth>
            <AuthLayout>
              <ClientSelect />
            </AuthLayout>
          </RequireAuth>
        ),
      },
      {
        path: '/terms-of-use',
        element: (
          <RequireAuth>
            <Layout />
            <TermsOfUse />
          </RequireAuth>
        ),
      },
      {
        // OAuth redirects to this page. The page itself isn't rendered, as we
        // are interested in the query params.
        // Should match VITE_OAUTH_REDIRECT_URL.
        path: '/oauth-redirect',
        element: <Outlet />,
      },
      {
        path: '/',
        element: (
          <RequireAuth
            redirectPath={() => {
              const { pathname } = window.location;
              // If the route is invalid (e.g. matchRoutes returns null), we
              // will serve a 404 page. no need to match Path here.
              // Individual pages need to handle 404 for resources however.
              if (pathname !== '/') {
                return '/auth/login#' + urlToBase64(pathname);
              } else {
                return 'auth/login';
              }
            }}
          >
            <RequireTermsOfUseAccepted>
              <Layout />
            </RequireTermsOfUseAccepted>
          </RequireAuth>
        ),
        children: [
          {
            index: true,
            element: <Index />,
          },
          {
            path: '/admin/user-management',
            lazy: lazyRouteFunction(
              () => import('pages/admin/user-management')
            ),
          },
          {
            path: '/profile',
            lazy: lazyRouteFunction(() => import('pages/profile')),
          },
          {
            path: '/clients/:clientId',
            children: [
              {
                path: 'home',
                lazy: lazyRouteFunction(() => import('pages/home')),
              },
              {
                path: 'services',
                lazy: lazyRouteFunction(() => import('pages/services')),
                children: [
                  {
                    index: true,
                    lazy: lazyRouteFunction(() => import('pages/services/all')),
                  },
                  {
                    path: 'all',
                    lazy: lazyRouteFunction(() => import('pages/services/all')),
                  },
                  {
                    path: ':productCategory',
                    lazy: lazyRouteFunction(
                      () => import('pages/services/[product-category]')
                    ),
                  },
                ],
              },
              {
                path: 'advertisers',
                children: [
                  {
                    index: true,
                    lazy: lazyRouteFunction(() => import('pages/advertisers')),
                  },
                  {
                    path: ':advertiserId',
                    lazy: lazyRouteFunction(
                      () => import('pages/advertisers/[advertiserId]')
                    ),
                    children: [
                      {
                        path: ':advertiserActiveTab',
                        lazy: lazyRouteFunction(
                          () =>
                            import(
                              'pages/advertisers/[advertiserId]/[activeTab]'
                            )
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'audiences',
                lazy: lazyRouteFunction(() => import('pages/audiences')),
                children: [
                  {
                    path: ':advertiserId',
                    element: (
                      <WithSelectedAdvertiser>
                        <RequireAccessToAdvertiser>
                          <Outlet />
                        </RequireAccessToAdvertiser>
                      </WithSelectedAdvertiser>
                    ),
                    children: [
                      {
                        path: 'segment-analysis',
                        lazy: lazyRouteFunction(
                          () => import('pages/audiences/segment-analysis')
                        ),
                        children: [
                          {
                            path: ':audienceId/web-insights',
                            lazy: lazyRouteFunction(
                              () =>
                                import(
                                  'pages/audiences/segment-analysis/[audienceId]/web-insights'
                                )
                            ),
                          },
                          {
                            path: ':audienceId/audience-composition',
                            lazy: lazyRouteFunction(
                              () =>
                                import(
                                  'pages/audiences/segment-analysis/[audienceId]/audience-composition'
                                )
                            ),
                          },
                        ],
                      },
                      {
                        path: 'taxonomy',
                        lazy: lazyRouteFunction(
                          () => import('pages/audiences/taxonomy')
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'campaigns',
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    lazy: lazyRouteFunction(() => import('pages/campaigns')),
                  },
                  {
                    path: ':campaignId',
                    lazy: lazyRouteFunction(
                      () => import('pages/campaigns/[campaignId]')
                    ),
                    children: [
                      {
                        path: ':campaignActiveTab',
                        lazy: lazyRouteFunction(
                          () =>
                            import('pages/campaigns/[campaignId]/[activeTab]')
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'reporting-suite',
                lazy: lazyRouteFunction(() => import('pages/reporting-suite')),
                children: [
                  {
                    path: ':advertiserId',
                    element: (
                      <WithSelectedAdvertiser>
                        <RequireAccessToAdvertiser>
                          <Outlet />
                        </RequireAccessToAdvertiser>
                      </WithSelectedAdvertiser>
                    ),
                    children: [
                      {
                        path: 'dashboards/custom-report',
                        lazy: lazyRouteFunction(
                          () =>
                            import(
                              'pages/reporting-suite/ReportingCustomDashboard'
                            )
                        ),
                      },
                      {
                        path: 'dashboards/:dashboardName',
                        lazy: lazyRouteFunction(
                          () => import('pages/reporting-suite/Dashboard')
                        ),
                      },
                      {
                        path: 'dashboards/native/:dashboardKey',
                        lazy: lazyRouteFunction(
                          () => import('pages/reporting-suite/NativeDashboard')
                        ),
                      },
                      {
                        path: 'dashboards',
                        lazy: lazyRouteFunction(
                          () => import('pages/reporting-suite/Dashboards')
                        ),
                      },
                      {
                        path: 'data-connections',
                        lazy: lazyRouteFunction(
                          () => import('pages/reporting-suite/DataConnections')
                        ),
                      },
                      {
                        path: 'assistant',
                        lazy: lazyRouteFunction(
                          () => import('pages/reporting-suite/AIAssistant')
                        ),
                      },
                      {
                        path: 'campaign-reporting',
                        lazy: async () => {
                          return {
                            element: <Outlet />,
                          };
                        },
                        children: [
                          {
                            index: true,
                            lazy: lazyRouteFunction(
                              () =>
                                import(
                                  'pages/reporting-suite/campaign-reporting'
                                )
                            ),
                          },
                          {
                            path: ':campaignId',
                            lazy: lazyRouteFunction(
                              () =>
                                import(
                                  'pages/reporting-suite/campaign-reporting/[campaignId]'
                                )
                            ),
                            children: [
                              {
                                path: ':activeTab',
                                lazy: lazyRouteFunction(
                                  () =>
                                    import(
                                      'pages/reporting-suite/campaign-reporting/[campaignId]/[activeTab]'
                                    )
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'builder',
                        lazy: lazyRouteFunction(
                          () => import('pages/reporting-suite/builder')
                        ),
                      },
                      {
                        path: 'builder/schedules',
                        lazy: lazyRouteFunction(
                          () =>
                            import('pages/reporting-suite/builder/schedules')
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'billing',
                lazy: lazyRouteFunction(() => import('pages/billing')),
              },
              {
                path: 'user-management',
                lazy: lazyRouteFunction(() => import('pages/user-management')),
              },
            ],
          },
        ],
      },
      { path: '*', element: <NotFound /> },
    ],
  },
];

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = config.sentry.isEnabled
  ? sentryCreateBrowserRouter(routes)
  : createBrowserRouter(routes as RouteObject[]);
