import React from 'react';
import {
  ChallengeState,
  TitledMessage,
  CurrentUser,
} from 'features/auth/types';

export type AuthContextProps = {
  // username is available for when a challenge prevents creation of a user, see new-password.
  username?: string;
  user?: CurrentUser;
  error?: TitledMessage;
  isBusy: boolean;
  isAuthenticating: boolean;

  login: (user: string, password: string) => void;
  logout: () => void;
  resetError: () => void;
  resetPasswordRecovery: () => void;
  // Requesting a password reset from cognito involves requesting a code.
  // Calling requestPasswordRecovery also changes state

  requestPasswordRecovery: (username: string) => void;

  submitPasswordRecovery: (
    username: string,
    code: string,
    password: string
  ) => void;
  passwordRecoveryState: ChallengeState;

  submitNewPassword: (
    username: string,
    previousPassword: string,
    password: string
  ) => void;
  submitChangePassword: (oldPassword: string, newPassword: string) => void;
  // AuthWall should catch this value (if it's === 1), set after Login, and redirect to a page of choice, where
  // the new password
  newPasswordState: ChallengeState;
};

const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined
);

export default AuthContext;
