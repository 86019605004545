import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalUserInputDto, InternalUserOutputDto } from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { AsyncThunkConfig } from 'store/types';

export const getUsers = createAsyncThunk(
  'admin/getUsers',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.internalUser.getAllInternalUsers();
      const payload = parseApiPayload(response);

      return payload?.content;
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const createUser = createAsyncThunk<
  InternalUserOutputDto,
  // Below we cast again to InternalUserInputDto to avoid the version field being sent to the server.
  // adding aversion causes a 400 errors as it is unexpected, but openAPI specs sets it as required for adds.
  { requestBody: Omit<InternalUserInputDto, 'version'> },
  AsyncThunkConfig
>(
  'admin/createUser',
  async ({ requestBody }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.internalUser.postInternalUser(
        requestBody as InternalUserInputDto
      );

      dispatch(getUsers());

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
            uiFieldErrorMessages: apiError.uiFieldErrorMessages,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const updateUser = createAsyncThunk<
  InternalUserOutputDto,
  {
    username: string;
    requestBody: Omit<Omit<InternalUserInputDto, 'username'>, 'version'>;
  },
  AsyncThunkConfig
>(
  'admin/updateUser',
  async ({ username, requestBody }, { dispatch, rejectWithValue }) => {
    try {
      const getUserByUsernameResponse =
        await api.internalUser.getInternalUserByUsername(username);
      const getUserByUsernamePayload = parseApiPayload(
        getUserByUsernameResponse
      );

      const response = await api.internalUser.putInternalUser(username, {
        ...requestBody,
        version: getUserByUsernamePayload.version,
      } as InternalUserInputDto);

      dispatch(getUsers());

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
            uiFieldErrorMessages: apiError.uiFieldErrorMessages,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const disableUser = createAsyncThunk<
  void,
  { username: string },
  AsyncThunkConfig
>('admin/disableUser', async ({ username }, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.internalUser.disableInternalUser(username);

    dispatch(getUsers());

    return parseApiPayload(response);
  } catch (error) {
    const apiError = toApiError(error);

    if (!apiError.isAbortedError) {
      dispatch(
        setError({
          message: apiError.message,
          uiMessage: apiError.uiMessage,
        })
      );
    }

    return rejectWithValue(apiError);
  }
});

export const enableUser = createAsyncThunk<
  InternalUserOutputDto,
  { username: string },
  AsyncThunkConfig
>('admin/enableUser', async ({ username }, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.internalUser.enableInternalUser(username);

    dispatch(getUsers());

    return parseApiPayload(response);
  } catch (error) {
    const apiError = toApiError(error);

    if (!apiError.isAbortedError) {
      dispatch(
        setError({
          message: apiError.message,
          uiMessage: apiError.uiMessage,
        })
      );
    }

    return rejectWithValue(apiError);
  }
});
