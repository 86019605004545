import { Link } from 'react-router-dom';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import GroupIcon from '@mui/icons-material/Group';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import BookOpenPageVariantOutlineIcon from 'mdi-material-ui/BookOpenPageVariantOutline';
import { UserWithMetadataDtoRoleEnum } from '@kortxio/hub-api';
import Filler from 'components/shared/Layout/Filler';
import Rows from 'components/shared/Layout/Rows';
import { roleSelector, userSelector } from 'features/user/selectors';
import DrawerListItem from 'layout/components/drawer/DrawerListItem';
import MagicBellInboxMenuDrawerListItem from 'layout/components/drawer/MagicBellInboxMenuDrawerListItem';
import ProfileMenuDrawerListItem from 'layout/components/drawer/ProfileMenuDrawerListItem';
import config from 'libs/config';
import { useAppSelector } from 'store/hooks';
import RouterDrawerListItem from './RouterDrawerListItem';

function AxonIcon() {
  return (
    <Box sx={{ width: 24, textAlign: 'center' }}>
      <Typography fontWeight={600} fontSize="1.5rem">
        A
      </Typography>
    </Box>
  );
}

function DrawerList() {
  const theme = useTheme();
  const { magicBell, axon } = config;

  const { isEnabled: isMagicBellEnabled } = magicBell;
  const { axonUrl } = axon;

  const user = useAppSelector(userSelector);
  const role = useAppSelector(roleSelector);
  const isReportingOnlyRole =
    role === UserWithMetadataDtoRoleEnum.ReportingOnly;

  return (
    <>
      {user && (
        <Rows
          sx={{
            flex: 1,
            p: 0,
            py: 1,
            [theme.breakpoints.down('md')]: {
              px: 1.5,
            },
          }}
        >
          <RouterDrawerListItem
            visible={!isReportingOnlyRole}
            to="/home"
            text="Home"
            icon={<HomeOutlinedIcon />}
          />
          <RouterDrawerListItem
            visible={!isReportingOnlyRole}
            to="/services"
            text="Services"
            icon={<BookOpenPageVariantOutlineIcon />}
          />
          <RouterDrawerListItem
            visible={!isReportingOnlyRole}
            to="/advertisers"
            text="Advertisers"
            icon={<FolderOpenOutlinedIcon />}
          />
          <RouterDrawerListItem
            visible={!isReportingOnlyRole}
            to="/campaigns"
            text="Campaigns"
            icon={<SensorsOutlinedIcon />}
          />
          <RouterDrawerListItem
            visible={!isReportingOnlyRole}
            to="/audiences"
            text="Audiences"
            icon={<GroupIcon />}
          />
          <RouterDrawerListItem
            to="/reporting-suite"
            text="Reporting Suite"
            icon={<BarChartOutlinedIcon />}
          />
          <Filler />
          {!isReportingOnlyRole && (
            <>
              <Divider
                sx={{
                  borderColor: (theme) =>
                    alpha(theme.palette.common.white, 0.12),
                }}
              />
              <RouterDrawerListItem
                visible={!isReportingOnlyRole}
                to="/billing"
                text="Billing"
                icon={<ReceiptOutlinedIcon />}
              />
              <DrawerListItem
                text="Axon Audience Manager"
                icon={<AxonIcon />}
                ListItemButtonProps={{
                  component: Link,
                  to: axonUrl,
                  target: '_blank',
                  sx: { color: 'blue' },
                }}
              />
              <Filler />
            </>
          )}

          <Divider
            sx={{
              my: 1,
              borderColor: (theme) => alpha(theme.palette.common.white, 0.12),
            }}
          />
          {isMagicBellEnabled && !isReportingOnlyRole && (
            <MagicBellInboxMenuDrawerListItem />
          )}
          <ProfileMenuDrawerListItem />
        </Rows>
      )}
    </>
  );
}

export default DrawerList;
