import { createSlice } from '@reduxjs/toolkit';
import { AnalyticsState } from 'features/analytics/types';

export const initialState: AnalyticsState = {
  currentWorkspaceId: undefined,
};

export const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // ReportingSuiteDrawer loads the workspace, puts it in the reducer so other components
    // don't have to use the hook and translate
    setWorkspaceId: (state, { payload: workspaceId }) => {
      state.currentWorkspaceId = workspaceId;
    },
  },
});

export const { setWorkspaceId } = slice.actions;

export default slice.reducer;
