import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputLabel, TextField } from '@mui/material';
import Columns from 'components/shared/Layout/Columns';
import Rows from 'components/shared/Layout/Rows';
import NoVisitLink from 'components/shared/NoVisitLink';
import { darkFieldSx } from 'features/auth/sx';

export type RequestCodeProps = {
  busy: boolean;
  username: string;
  setUsername: (a: string) => void;
  onSubmit: () => void;
};

const RequestCodeForm: React.FC<RequestCodeProps> = ({
  busy,
  username,
  setUsername,
  onSubmit,
}) => {
  const wrappedSubmit = React.useCallback(
    (
      e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
    ) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <Rows sx={{ pt: 1 }}>
      <form onSubmit={wrappedSubmit}>
        <Rows sx={{ py: 3 }}>
          <InputLabel htmlFor="recovery" sx={{ color: 'white' }}>
            <strong>Email</strong>
          </InputLabel>
          <TextField
            name="recovery"
            sx={darkFieldSx}
            disabled={busy}
            margin="normal"
            value={username}
            InputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Rows>
        <LoadingButton
          type="submit"
          sx={{ width: '100%' }}
          disabled={busy || username.length === 0}
          variant="contained"
          onClick={wrappedSubmit}
          loading={busy}
        >
          Submit
        </LoadingButton>
      </form>
      <Columns sx={{ py: 2, flex: 1, alignItems: 'center' }}>
        <NoVisitLink href="/auth/login">Back to log in</NoVisitLink>
      </Columns>
    </Rows>
  );
};

export default RequestCodeForm;
