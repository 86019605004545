import { ActionReducerMapBuilder, Draft } from '@reduxjs/toolkit';
import { getAllFilesByAdvertiserId } from 'features/advertiser/async';
import { AdvertiserByIdState } from 'features/advertiser/types';
import { onFulfilled, onPending, onRejected } from 'libs/slice';

export function advertiserDocuments(
  builder: ActionReducerMapBuilder<AdvertiserByIdState>
) {
  builder.addCase(
    getAllFilesByAdvertiserId.fulfilled,
    (state: Draft<AdvertiserByIdState>, { payload, meta }) => {
      onFulfilled(state.documents, meta, meta.requestId, payload);
    }
  );

  builder.addCase(getAllFilesByAdvertiserId.pending, (state, { meta }) => {
    onPending(state.documents, meta);
  });

  builder.addCase(getAllFilesByAdvertiserId.rejected, (state, { meta }) => {
    onRejected(state.documents, meta);
  });
}
