import React from 'react';
import WithClients from 'features/client/components/WithClients';
import WithSelectedClient from 'features/client/components/WithSelectedClient';
import WithTeam from 'features/client/components/WithTeam';
import WithMe from 'features/user/components/WithMe';

function KampusContextProvider({ children }: React.PropsWithChildren) {
  return (
    <WithMe>
      <WithClients>
        <WithSelectedClient>
          <WithTeam>{children}</WithTeam>
        </WithSelectedClient>
      </WithClients>
    </WithMe>
  );
}

export default KampusContextProvider;
