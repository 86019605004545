import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CognitoUserDto,
  PageInternalUserOutputDto,
  PageStandardUserOutputDto,
  StandardUserInputDto,
  StandardUserOutputDto,
  UserPatchInputDto,
  UserWithMetadataDto,
} from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiPayload, toApiError } from 'libs/api/helper';
import { fullstorySetUserVars } from 'libs/fullstory';
import { AsyncThunkConfig, RootState } from 'store/types';

export const getMe = createAsyncThunk<
  UserWithMetadataDto,
  void,
  AsyncThunkConfig
>('user/getMe', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.user.getMe();

    return parseApiPayload(response);
  } catch (error) {
    const apiError = toApiError(error);

    if (!apiError.isAbortedError) {
      dispatch(
        setError({ message: apiError.message, uiMessage: apiError.uiMessage })
      );
    }

    return rejectWithValue(apiError);
  }
});

export const getMeWithUpdates = createAsyncThunk<
  UserWithMetadataDto,
  void,
  AsyncThunkConfig
>(
  'user/getMeWithUpdates',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await api.user.getMe();
      const updatedUser = parseApiPayload(response);

      const state: RootState = getState();
      const isUpdated =
        JSON.stringify(state.user.user.data) !== JSON.stringify(updatedUser);

      if (isUpdated) {
        fullstorySetUserVars(updatedUser);
      }
      return updatedUser;
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const acceptTermsOfUse = createAsyncThunk<void, void, AsyncThunkConfig>(
  'user/acceptTermsOfUse',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.user.acceptTermsOfUse();

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const patchMe = createAsyncThunk<
  UserWithMetadataDto,
  { requestBody: UserPatchInputDto },
  AsyncThunkConfig
>('user/patchMe', async ({ requestBody }, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.user.patchMe(requestBody);

    return parseApiPayload(response);
  } catch (error) {
    const apiError = toApiError(error);

    if (!apiError.isAbortedError) {
      dispatch(
        setError({ message: apiError.message, uiMessage: apiError.uiMessage })
      );
    }

    return rejectWithValue(apiError);
  }
});

export const getAllStandardUsers = createAsyncThunk<
  PageStandardUserOutputDto,
  { clientId: number },
  AsyncThunkConfig
>(
  'users/getAllStandardUsers',
  async ({ clientId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.standardUser.getAllStandardUsersByClientId(
        clientId
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getStandardUserByUsername = createAsyncThunk<
  StandardUserOutputDto,
  { clientId: number; username: string },
  AsyncThunkConfig
>(
  'users/getStandardUserByUsername',
  async ({ clientId, username }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.standardUser.getStandardUserByUsername(
        clientId,
        username
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const createStandardUser = createAsyncThunk<
  StandardUserOutputDto,
  { clientId: number; requestBody: StandardUserInputDto },
  AsyncThunkConfig
>(
  'user/createStandardUser',
  async ({ clientId, requestBody }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.standardUser.postStandardUser(
        clientId,
        requestBody
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
            uiFieldErrorMessages: apiError.uiFieldErrorMessages,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const updateStandardUser = createAsyncThunk<
  StandardUserOutputDto,
  { clientId: number; username: string; requestBody: StandardUserInputDto },
  AsyncThunkConfig
>(
  'user/updateStandardUser',
  async (
    { clientId, username, requestBody },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.standardUser.putStandardUser(
        clientId,
        username,
        requestBody
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
            uiFieldErrorMessages: apiError.uiFieldErrorMessages,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const disableStandardUser = createAsyncThunk<
  void,
  { clientId: number; username: string },
  AsyncThunkConfig
>(
  'user/disableStandardUser',
  async ({ clientId, username }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.standardUser.disableStandardUser(
        clientId,
        username
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const enableStandardUser = createAsyncThunk<
  void,
  { clientId: number; username: string },
  AsyncThunkConfig
>(
  'user/enableStandardUser',
  async ({ clientId, username }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.standardUser.enableStandardUser(
        clientId,
        username
      );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({
            message: apiError.message,
            uiMessage: apiError.uiMessage,
          })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getAllInternalUsers = createAsyncThunk<
  PageInternalUserOutputDto,
  void,
  AsyncThunkConfig
>('users/getAllInternalUsers', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.internalUser.getAllInternalUsers();
    return parseApiPayload(response);
  } catch (error) {
    const apiError = toApiError(error);

    if (!apiError.isAbortedError) {
      dispatch(
        setError({ message: apiError.message, uiMessage: apiError.uiMessage })
      );
    }

    return rejectWithValue(apiError);
  }
});

export const getCognitoUserByUsername = createAsyncThunk<
  CognitoUserDto,
  string,
  AsyncThunkConfig
>(
  'cognitouser/getCognitoUserByUsername',
  async (username, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.cognitoUser.getCognitoUserByUsername(username);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getCognitoUserByClientIdAndUsername = createAsyncThunk<
  CognitoUserDto,
  { clientId: number; username: string },
  AsyncThunkConfig
>(
  'cognitouser/getCognitoUserByClientIdAndUsername',
  async ({ clientId, username }, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await api.cognitoUser.getCognitoUserByClientIdAndUsername(
          clientId,
          username
        );

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const resetUserPasswordByClientId = createAsyncThunk<
  void,
  { clientId: number; username: string },
  AsyncThunkConfig
>(
  'cognitouser/resetUserPasswordByClientId',
  async ({ clientId, username }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.cognitoUser.resetUserPasswordByClientId(
        clientId,
        username
      );
      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const resetUserPassword = createAsyncThunk<
  void,
  string,
  AsyncThunkConfig
>(
  'cognitouser/resetUserPassword',
  async (username, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.cognitoUser.resetUserPassword(username);
      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const resendInvitationMessage = createAsyncThunk<
  void,
  { clientId: number; username: string },
  AsyncThunkConfig
>(
  'cognitouser/resendInvitationMessage',
  async ({ clientId, username }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.cognitoUser.resendInvitationMessage(
        clientId,
        username
      );
      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);
