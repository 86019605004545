import { Link } from 'react-router-dom';
import Columns from 'components/shared/Layout/Columns';
import Rows from 'components/shared/Layout/Rows';
import NoVisitLink from 'components/shared/NoVisitLink';

const PasswordResetCompleted = () => {
  return (
    <Rows sx={{ pt: 1 }}>
      <Columns sx={{ mt: 2 }}>
        <Columns sx={{ flex: 1, alignItems: 'center' }}>
          <Link to="/auth/login">
            <NoVisitLink href="/auth/login">Back to log in</NoVisitLink>
          </Link>
        </Columns>
      </Columns>
    </Rows>
  );
};

export default PasswordResetCompleted;
