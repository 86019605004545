import { useEffect, useCallback } from 'react';
import {
  useNavigate,
  useLocation,
  useRouteError,
  isRouteErrorResponse,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { errorHandler } from 'libs/error';

export default function Error() {
  const navigate = useNavigate();
  const location = useLocation();

  const error = useRouteError();

  const { key } = location;

  useEffect(() => {
    if (error) {
      errorHandler.handle(error);
    }
  }, [error]);

  const onClickGoBack = useCallback(() => {
    const isGoBackSupported = key !== 'default';
    if (!isGoBackSupported) {
      navigate('/');

      return;
    }

    navigate(-1);
  }, [key, navigate]);

  const is404 =
    (isRouteErrorResponse(error) || error instanceof Response) &&
    error?.status === 404;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: 'secondary.main',
        minHeight: '100vh',
      }}
    >
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            fontSize: '30vh',
            color: 'common.white',
          }}
        >
          {is404 ? 404 : 500}
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="h2" gutterBottom sx={{ color: 'grey.500' }}>
            {is404 ? 'Oops. Nothing here...' : 'Oops. Something went wrong...'}
          </Typography>
        </Box>
        <Box mt={2} textAlign="center">
          <Button color="primary" variant="contained" onClick={onClickGoBack}>
            Go Back
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
