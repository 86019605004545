import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectedAdvertiserSelector } from 'features/advertiser/selectors';
import { hasAccessToAdvertiserSelector } from 'features/user/selectors';
import { useAppSelector } from 'store/hooks';

export default function RequireAccessToAdvertiser({
  children,
}: React.PropsWithChildren) {
  const navigate = useNavigate();

  const { advertiserId: advertiserIdAsParam } = useParams();

  const selectedAdvertiser = useAppSelector(selectedAdvertiserSelector);

  const hasAccessToAdvertiser = useAppSelector(
    hasAccessToAdvertiserSelector(advertiserIdAsParam, selectedAdvertiser)
  );

  useEffect(() => {
    if (hasAccessToAdvertiser === 'DENY') {
      throw new Response('Not found', { status: 404 });
    }
  }, [hasAccessToAdvertiser, navigate]);

  if (hasAccessToAdvertiser === 'PENDING') {
    return <></>;
  }

  return <>{children}</>;
}
