import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputLabel, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import Columns from 'components/shared/Layout/Columns';
import Rows from 'components/shared/Layout/Rows';
import NoVisitLink from 'components/shared/NoVisitLink';
import { darkFieldSx } from 'features/auth/sx';
import VisibilityAdornment from 'features/auth/VisibilityAdornment';
import { isValidPasswordPair } from '../functions';

export type SubmitCodePasswordProps = {
  busy: boolean;
  code: string;
  setCode: (a: string) => void;
  setOuterPassword: (a: string) => void;
  onSubmit: (password: string) => void; // this passes the password after we check it's the same in this comp.
};

const SubmitCodePasswordForm: React.FC<SubmitCodePasswordProps> = ({
  busy,
  code,
  setCode,
  setOuterPassword,
  onSubmit,
}) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const wrappedSubmit = React.useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
    ) => {
      e.preventDefault();
      onSubmit(password);
    },
    [onSubmit, password]
  );

  const enableSubmit =
    !busy &&
    isValidPasswordPair(password, passwordConfirmation) &&
    code.length === 6;

  const [showPass, setShowPass] = useState(false);

  return (
    <Rows sx={{ pt: 1 }}>
      <form onSubmit={wrappedSubmit}>
        <Rows>
          <InputLabel htmlFor="code" sx={{ color: 'white', pt: 2 }}>
            <strong>Code</strong>
          </InputLabel>
          <TextField
            name="code"
            sx={darkFieldSx}
            disabled={busy}
            margin="normal"
            autoComplete="off"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            helperText={code ? '' : 'This code was sent to your email'}
          />
          <Divider
            variant="middle"
            sx={{ mb: 2, mt: 2.5, borderColor: 'white' }}
          />
          <InputLabel htmlFor="password" sx={{ color: 'white', pt: 2 }}>
            <strong>New Password</strong>
          </InputLabel>
          <TextField
            name="new-password"
            sx={darkFieldSx}
            disabled={busy || code.length === 0}
            type={showPass ? 'text' : 'password'}
            margin="normal"
            value={password}
            onChange={(e) => {
              const str = e.target.value;
              // warning: will set the outer password even if the two passwords aren't valid
              // we use enableSubmit to make sure we don't set a password that doesn't match
              // we reset code and password when in stage Idle and Completed.
              setOuterPassword(str);
              setPassword(str);
            }}
            helperText="8 characters w/ uppercase, lowercase, number, special"
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <VisibilityAdornment
                  setIsVisible={setShowPass}
                  isVisible={showPass}
                />
              ),
            }}
          />
          <InputLabel htmlFor="password2" sx={{ color: 'white', pt: 2 }}>
            <strong>Confirm New Password</strong>
          </InputLabel>
          <TextField
            sx={darkFieldSx}
            name="new-password"
            disabled={busy || code.length === 0}
            type={showPass ? 'text' : 'password'}
            margin="normal"
            autoComplete="new-password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            helperText="Please confirm your password"
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <VisibilityAdornment
                  setIsVisible={setShowPass}
                  isVisible={showPass}
                />
              ),
            }}
          />
        </Rows>
        <LoadingButton
          type="submit"
          sx={{ width: '100%', mt: 2 }}
          disabled={!enableSubmit}
          variant="contained"
          onClick={wrappedSubmit}
          loading={busy}
        >
          Submit
        </LoadingButton>
        <Columns sx={{ py: 2, flex: 1, alignItems: 'center' }}>
          <NoVisitLink href="/auth/login">Back to log in</NoVisitLink>
        </Columns>
      </form>
    </Rows>
  );
};

export default SubmitCodePasswordForm;
