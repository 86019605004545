import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.info.main,
  },
}));

export type MagicBellInboxLinkProps = {
  actionUrl: string;
  actionMessage: string;
};

export default function MagicBellInboxLink({
  actionUrl,
  actionMessage,
}: MagicBellInboxLinkProps) {
  const { classes } = useStyles();

  if (!actionMessage) {
    return (
      <Link
        component={RouterLink}
        to={actionUrl}
        variant="caption"
        className={classes.link}
      >
        Open
      </Link>
    );
  }

  return (
    <Link
      component={RouterLink}
      to={actionUrl}
      variant="caption"
      className={classes.link}
    >
      {actionMessage}
    </Link>
  );
}
