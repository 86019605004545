import {
  AdvertiserDto,
  AdvertiserSummaryProjectionDto,
} from '@kortxio/hub-api';
import { RequestState } from 'features/shared/request';

export type AdvertiserAsOption = {
  id: number;
  label: string;
};

export type AdvertiserAsRow = {
  id?: number;
  name?: string;
  url?: string;
  website?: string;
  countOfCampaignsClosedWon: number;
};

export const dtoToAdvertiserAsRow = (
  dto: AdvertiserDto
): AdvertiserAsRow | undefined => {
  if (!dto) {
    return undefined;
  }

  return {
    id: dto.id,
    name: dto.name,
    url: dto.website,
    website: dto.website,
    countOfCampaignsClosedWon:
      dto?.countsForCurrentClient?.countOfCampaignsClosedWon ?? 0,
  };
};

export type AdvertiserUiState = {
  search: string | undefined;
};

export type AdvertiserState = {
  advertisers: {
    data: AdvertiserDto[] | undefined;
    request: RequestState;
  };
  advertisersUsingSummaryProjection: {
    data: AdvertiserSummaryProjectionDto[] | undefined;
    request: RequestState;
  };
  ui: AdvertiserUiState;
};
