import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import HelloGuySVG from 'assets/hello_guy.svg?react';
import { loginRedirectUrl } from 'features/auth/functions';
import { useAuth } from 'features/auth/UseAuth';
import { acceptTermsOfUse, getMeWithUpdates } from 'features/user/async';
import { roleSelector, userSelector } from 'features/user/selectors';
import config from 'libs/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function TermsOfUse() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { logout } = useAuth();

  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const user = useAppSelector(userSelector);
  const role = useAppSelector(roleSelector) || [];
  const isReportingOnlyUser = role === 'REPORTING_ONLY';

  const { firstName, termsOfUseAccepted } = user || {};

  const onSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      await dispatch(acceptTermsOfUse()).unwrap();

      await dispatch(getMeWithUpdates()).unwrap();
    } catch (error) {
      setIsOpen(false);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (termsOfUseAccepted && user) {
      navigate(loginRedirectUrl(user, undefined));
    }
  }, [termsOfUseAccepted, navigate, user]);

  return (
    <Dialog open={isOpen}>
      {user && termsOfUseAccepted !== undefined && !termsOfUseAccepted && (
        <DialogContent>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
            }}
            onClick={logout}
          >
            <CloseIcon />
          </IconButton>
          <Box display="flex" justifyContent="center">
            <HelloGuySVG width={270} />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="h3" align="center">
              {`Hi, ${firstName}!`}
            </Typography>
            <Box pt={1}>
              {isReportingOnlyUser ? (
                <Typography variant="body2" gutterBottom align="center">
                  Welcome to Kampus, your interactive dashboard for real-time
                  campaign reporting and more!
                </Typography>
              ) : (
                <Typography variant="body2" gutterBottom align="center">
                  Welcome to Kampus, your exclusive resource center that
                  provides up-to-date campaign metrics, billing information,
                  product details, and a direct line to your dedicated KORTX
                  support team.
                </Typography>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              pt={1}
              pb={1}
              width="100%"
            >
              <LoadingButton
                disabled={isLoading}
                loading={isLoading}
                type="submit"
                color="secondary"
                variant="contained"
                onClick={onSubmit}
                sx={{
                  width: {
                    xs: '100%',
                    sm: 330,
                  },
                  maxWidth: 330,
                }}
              >
                Continue
              </LoadingButton>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              pb={2}
            >
              <Typography
                variant="caption"
                color="textSecondary"
                align="center"
              >
                By clicking Continue you agree to Kampus
              </Typography>
              <Link
                href={`https://${config.s3BucketNameForPublicDocuments}.s3.amazonaws.com/KORTX_KAMPUS_TERMS_OF_SERVICE.pdf`}
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                variant="caption"
                color="textSecondary"
              >
                Terms of Use
              </Link>
            </Box>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
}
