import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputLabel, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Columns from 'components/shared/Layout/Columns';
import Rows from 'components/shared/Layout/Rows';
import NoVisitLink from 'components/shared/NoVisitLink';
import LoginErrorAlert from 'features/auth/Alerts/LoginErrorAlert';
import { isValidPasswordPair } from 'features/auth/functions';
import { darkFieldSx } from 'features/auth/sx';
import { ChallengeState } from 'features/auth/types';
import { useAuth } from 'features/auth/UseAuth';
import VisibilityAdornment from 'features/auth/VisibilityAdornment';

export default function NewPassword() {
  const { submitNewPassword, newPasswordState, error, resetError } = useAuth();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [busy, setBusy] = useState(false);
  const [username, setUsername] = useState('');
  const [previousPassword, setPreviousPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const wrappedSubmit = React.useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
    ) => {
      resetError();
      e.preventDefault();
      submitNewPassword(username, previousPassword, password);
      setBusy(true);
    },
    [
      submitNewPassword,
      username,
      previousPassword,
      password,
      setBusy,
      resetError,
    ]
  );

  useEffect(() => {
    const queryValue = search.get('username');
    if (queryValue != null) {
      setUsername(queryValue);
    } else {
      navigate('/auth/login');
    }
  }, [search, navigate]);

  useEffect(() => {
    if (newPasswordState === ChallengeState.Completed) {
      navigate('/');
      setBusy(false);
    }
    if (error) {
      setBusy(false);
    }
  }, [setBusy, newPasswordState, navigate, error]);

  const enableSubmit =
    !busy &&
    isValidPasswordPair(password, passwordConfirmation) &&
    username.length > 2 &&
    previousPassword.length > 1;

  const [showPass, setShowPass] = useState(false);

  return (
    <Rows sx={{ py: 3, width: 450 }}>
      <Typography variant="h5" color="white">
        <strong>Almost there!</strong>
      </Typography>
      <Typography variant="body1" color="white">
        Set your password to complete your registration.
      </Typography>
      <Rows sx={{ pt: 4 }}>
        <form onSubmit={wrappedSubmit}>
          <Rows>
            <InputLabel htmlFor="temporary" sx={{ color: 'white', pt: 2 }}>
              <strong>Temporary Password</strong>
            </InputLabel>
            <TextField
              sx={darkFieldSx}
              disabled={busy}
              type={showPass ? 'text' : 'password'}
              margin="normal"
              name="temporary"
              value={previousPassword}
              onChange={(e) => setPreviousPassword(e.target.value)}
              helperText="The temporary password in the email"
              InputProps={{
                autoComplete: 'off',
                endAdornment: (
                  <VisibilityAdornment
                    setIsVisible={setShowPass}
                    isVisible={showPass}
                  />
                ),
              }}
            />
            <Divider sx={{ m: (theme) => theme.spacing(2) }} />
            <Typography variant="body1" color="white">
              <strong>Choose a new password:</strong>
            </Typography>
            <TextField
              sx={darkFieldSx}
              disabled={busy}
              type={showPass ? 'text' : 'password'}
              margin="normal"
              name="recovery"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText="8 characters w/ uppercase, lowercase, number, special"
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <VisibilityAdornment
                    setIsVisible={setShowPass}
                    isVisible={showPass}
                  />
                ),
              }}
            />
            <TextField
              sx={darkFieldSx}
              disabled={busy}
              type={showPass ? 'text' : 'password'}
              margin="normal"
              name="recovery-confirm"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              helperText="Please confirm your password"
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <VisibilityAdornment
                    setIsVisible={setShowPass}
                    isVisible={showPass}
                  />
                ),
              }}
            />
          </Rows>
          <Columns sx={{ my: 2 }}>
            <Columns
              sx={{ flex: 1, alignItems: 'center', justifyContent: 'end' }}
            >
              <NoVisitLink href="/auth/login">Back to log in</NoVisitLink>
            </Columns>
          </Columns>
          <LoadingButton
            type="submit"
            sx={{ width: '100%' }}
            disabled={!enableSubmit}
            loading={busy}
            variant="contained"
          >
            <strong>Set Password</strong>
          </LoadingButton>
        </form>
        {error && <LoginErrorAlert error={error} />}
      </Rows>
    </Rows>
  );
}
