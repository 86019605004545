import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { resetStore } from 'store';
import { UserWithMetadataDto } from '@kortxio/hub-api';
import {
  confirmResetPassword,
  confirmSignIn,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';
import AuthContext from 'features/auth/AuthContext';
import {
  toLoginError,
  toNewPasswordError,
  toPasswordRecoveryError,
} from 'features/auth/functions';
import {
  ChallengeState,
  CurrentUser,
  TitledMessage,
} from 'features/auth/types';
import { getMe } from 'features/user/async';
import { errorHandler } from 'libs/error';
import { useAppDispatch } from 'store/hooks';

type AuthProviderProps = {
  onAfterLogin?: (user: UserWithMetadataDto) => void;
  onAfterLogout?: () => void;
} & React.PropsWithChildren;

const AuthProvider: React.FC<AuthProviderProps> = ({
  onAfterLogin,
  onAfterLogout,
  children,
}) => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { pathname } = location;

  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState<TitledMessage | undefined>(undefined);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [username, setUsername] = useState<string | undefined>('');
  const [authUser, setAuthUser] = useState<CurrentUser | undefined>(undefined);
  const [newPasswordState, setNewPasswordState] = useState(ChallengeState.Idle);

  const [passwordRecoveryState, setPasswordRecoveryState] = useState(
    ChallengeState.Idle
  );

  useEffect(() => {
    async function trySetAuthUser() {
      setIsAuthenticating(true);

      try {
        const cognitoAuthUser = await getCurrentUser();

        setAuthUser({ username: cognitoAuthUser.username });
        setIsAuthenticating(false);
      } catch {
        setError(undefined);
        setAuthUser(undefined);
        setIsAuthenticating(false);
      }
    }

    trySetAuthUser();
  }, [pathname]);

  const onSuccessfulLogin = async () => {
    const cognitoAuthUser = await getCurrentUser();

    setAuthUser({ username: cognitoAuthUser.username });

    if (onAfterLogin) {
      try {
        onAfterLogin(await dispatch(getMe()).unwrap());
      } catch (onAfterLoginError) {
        errorHandler.handle(onAfterLoginError);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUnsuccessfulLogin = async (error: any) => {
    const isUserUserAlreadyAuthenticated =
      error?.name === 'UserAlreadyAuthenticatedException';

    if (isUserUserAlreadyAuthenticated) {
      await onSuccessfulLogin();

      return;
    }

    setError(toLoginError(error));
  };

  const login = async (username: string, password: string) => {
    setIsBusy(true);
    // set username is only cleared on log out, overwritten on log in
    // otherwise challenge pages like new-password may find an indeterminate value.
    setUsername(username);
    resetError();
    // Auth only uses context, not the store, this should be safe.
    dispatch(resetStore());

    try {
      const cognitoSignInOutput = await signIn({
        username: username,
        password,
        options: {
          authFlowType: 'USER_SRP_AUTH',
        },
      });

      const { nextStep } = cognitoSignInOutput;

      switch (nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED': {
          // Note: unlike password reset, which is something the user browses to,
          // detection of a new password sets this state value so that
          // AuthWall can do the correct redirecting upon Login.
          setNewPasswordState(ChallengeState.Requested);
          break;
        }
        case 'DONE': {
          await onSuccessfulLogin();

          break;
        }
        default: {
          throw new Error(
            `Unknown nextStep.signInStep '${nextStep.signInStep}`
          );
        }
      }
    } catch (error) {
      await onUnsuccessfulLogin(error);
    } finally {
      setIsBusy(false);
    }
  };

  const logout = async () => {
    setIsBusy(true);
    setIsAuthenticating(true);

    try {
      await signOut();

      setUsername(undefined);
      setAuthUser(undefined);
      setIsAuthenticating(false);

      if (onAfterLogout) {
        onAfterLogout();
      }
      // If on after logout tries to use the store it may be empty.
      dispatch(resetStore());
    } finally {
      setIsBusy(false);
    }
  };

  const resetPasswordRecovery = () => {
    setPasswordRecoveryState(ChallengeState.Idle);
  };

  const resetError = () => {
    setError(undefined);
  };

  const requestPasswordRecovery = async (username: string) => {
    setIsBusy(true);
    resetError();

    try {
      await resetPassword({ username });
    } finally {
      setIsBusy(false);
      setPasswordRecoveryState(ChallengeState.Requested);
    }
  };

  const submitPasswordRecovery = async (
    username: string,
    confirmationCode: string,
    newPassword: string
  ) => {
    try {
      await confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
      });

      setPasswordRecoveryState(ChallengeState.Completed);
    } catch (error) {
      setError(toPasswordRecoveryError(error));
    } finally {
      setIsBusy(false);
    }
  };

  const submitNewPassword = async (
    username: string,
    previousPassword: string,
    password: string
  ) => {
    // Auth only uses context, not the store, this should be safe.
    dispatch(resetStore());

    try {
      const cognitoSignInOutput = await signIn({
        username: username,
        password: previousPassword,
        options: {
          authFlowType: 'USER_SRP_AUTH',
        },
      });

      const { nextStep } = cognitoSignInOutput;

      switch (nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED': {
          await confirmSignIn({ challengeResponse: password });

          const cognitoAuthUser = await getCurrentUser();

          setAuthUser({ username: cognitoAuthUser.username });
          setNewPasswordState(ChallengeState.Completed);

          break;
        }
        case 'DONE': {
          setError({
            title: "We couldn't create a new password",
            detail: "The user doesn't need a new password.",
          });

          break;
        }
        default: {
          throw new Error(
            `Unknown nextStep.signInStep '${nextStep.signInStep}`
          );
        }
      }
    } catch (error) {
      setError(toNewPasswordError(error));
    } finally {
      setIsBusy(false);
    }
  };

  const submitChangePassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (error) {
      throw toLoginError(error);
    }
  };

  const finalValues = {
    username,
    user: authUser,
    isAuthenticating,
    isBusy,
    error,
    passwordRecoveryState,
    newPasswordState,
    resetError,
    login,
    logout,
    resetPasswordRecovery,
    submitPasswordRecovery,
    requestPasswordRecovery,
    submitNewPassword,
    submitChangePassword,
  };

  return (
    <AuthContext.Provider value={finalValues}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
