import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useLayoutContext } from 'layout/context';

const TemporaryDrawerToggleIconButton = () => {
  const { isTemporaryDrawerOpen: isOpen, setIsTemporaryDrawerOpen: setIsOpen } =
    useLayoutContext();

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <IconButton
      aria-label="open drawer"
      edge="start"
      onClick={onClick}
      disableRipple
      sx={(theme) => ({
        marginLeft: theme.spacing(0.5),
      })}
    >
      <MenuIcon htmlColor="white" />
    </IconButton>
  );
};

export default TemporaryDrawerToggleIconButton;
