import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  clientsAsOptionsSelector,
  selectedClientAsOptionSelector,
} from 'features/client/selectors';
import { ClientAsOption } from 'features/client/types';
import { createRouteToHome } from 'routes/hooks';
import { useAppSelector } from 'store/hooks';

export type AutoCompleteClientSelectProps = {
  onClose?: () => void;
  textFieldPlaceholder?: string;
};

const focusDelay = 250;

export default function AutoCompleteClientSelect({
  onClose,
  textFieldPlaceholder = 'Select a client',
}: AutoCompleteClientSelectProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const { clientId } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const clientsAsOptions = useAppSelector(clientsAsOptionsSelector);
  const selectedClientAsOption = useAppSelector(selectedClientAsOptionSelector);

  const onChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: ClientAsOption | null) => {
      if (value) {
        const { id } = value ?? {};

        if (id.toString() != clientId) {
          const currentPath = location.pathname;
          // This is the token following clientId: it's consistent for pages that start with /client
          // so we stay in the same section.
          // Trailing slash (in /client/) is added so client-select doesn't trigger
          // the wrong block, rather it recognizes a client-scoped page.
          if (currentPath.startsWith('/client/')) {
            const prev = currentPath.split('/').slice(3, 4);
            navigate(`/clients/${id}/${prev}`);
          } else {
            // If switching when on page /client-select, /profile, etc...
            navigate(createRouteToHome(id));
          }
          onClose?.call(undefined);
        }
      }
    },
    [clientId, location.pathname, onClose, navigate]
  );

  useEffect(() => {
    if (clientsAsOptions) {
      setTimeout(() => inputRef.current?.select(), focusDelay);
    }
  }, [clientsAsOptions]);

  return (
    <Autocomplete
      autoFocus
      value={selectedClientAsOption ?? null}
      onChange={onChange}
      disableClearable={!!selectedClientAsOption}
      options={clientsAsOptions ?? []}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={textFieldPlaceholder}
          inputRef={inputRef}
        />
      )}
      sx={{ width: 300 }}
    />
  );
}
