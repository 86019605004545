import React from 'react';
import { Box, BoxProps } from '@mui/material';

export type RowsBoxProps<
  D extends React.ElementType = 'div',
  P = unknown
> = BoxProps<D, P>;

const Rows: React.FC<RowsBoxProps> = (props) => {
  const { sx: otherSx, ...otherProps } = props;
  return (
    <Box
      sx={{ ...otherSx, display: 'flex', flexDirection: 'column' }}
      {...otherProps}
    />
  );
};

export default Rows;
