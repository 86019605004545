import { PropsWithChildren } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ThemeProvider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { indigo } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { darkTheme } from 'theme';
import KampusBadge from 'components/shared/KampusBadge';
import Columns from 'components/shared/Layout/Columns';
import Rows from 'components/shared/Layout/Rows';

export type AuthLayoutProps = {
  backgroundImageUrl?: string;
} & PropsWithChildren;

function AuthLayout({
  backgroundImageUrl = '/auth/loginbg.jpg',
  children,
}: AuthLayoutProps) {
  const theme = useTheme();
  return (
    <ThemeProvider theme={darkTheme}>
      <Columns>
        <Rows
          sx={{
            flexShrink: 0,
            px: 12,
            justifyContent: 'center',
            position: 'relative',
            bgcolor: (theme) => theme.palette.secondary.main,
            width: '570px',
            height: '100vh',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        >
          <Box>
            <KampusBadge />
            <Columns
              sx={{
                mt: 1,
                flex: 1,
                alignItems: 'center',
              }}
            >
              {children}
            </Columns>
          </Box>
        </Rows>
        <Stack
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
            justifyContent: 'center',
            width: '100%',
            backgroundImage: `url("${backgroundImageUrl}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Stack
            sx={{
              // Aligns banner visually with form on the right
              mt: 9,
              // Avoids opacity being applied to text
              bgcolor: 'rgba(255,255,255,.3)',
              p: 8,
              backdropFilter: 'blur(12px)',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                maxWidth: 800,
                minWidth: 500,
              }}
            >
              <Typography variant="h4" gutterBottom>
                <strong>Welcome to Kampus! 🎉</strong>
              </Typography>
              <Box
                component="div"
                sx={{
                  mt: 3,
                  '& a': {
                    textDecoration: 'none',
                    color: indigo[500],
                  },
                }}
              >
                <p>
                  We&apos;re excited to introduce our enhanced marketing
                  platform, Kampus! Experience a fresh interface, advanced
                  features, and a seamless transition from the KORTX Hub. Your
                  Account Manager is on hand for any questions.
                </p>
                <p>
                  We hope you enjoy navigating your new all-in-one marketing
                  platform.
                </p>
                <p>
                  <a
                    target="_blank"
                    href="https://kortx.io/news/kampus-timeline/"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                    rel="noreferrer"
                  >
                    Check out our blog to learn more &nbsp; <ArrowForwardIcon />
                  </a>
                </p>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Columns>
    </ThemeProvider>
  );
}

export default AuthLayout;
