/* Immer relies on these 'dirty' tricks: https://redux-toolkit.js.org/api/createSlice#examples
 * note that "state" is already a draft of the original state and meant to be reassigned */
import { createSlice } from '@reduxjs/toolkit';
import {
  fulfilled,
  initialRequestState,
  pending,
  rejected,
  requestReducer,
} from 'features/shared/request';
import { getAllInvoices, getInvoicesMetrics } from './async';
import { InvoiceState } from './types';

export const initialState: InvoiceState = {
  invoices: {
    data: undefined,
    request: initialRequestState,
  },
  metrics: {
    data: undefined,
    request: initialRequestState,
  },
  ui: {
    search: undefined,
    contactDialog: {
      isOpen: false,
      contentType: 'form',
      selectedInvoice: undefined,
    },
  },
};
const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setSearch(state, { payload }) {
      state.ui.search = payload;
    },
    setIsContactDialogOpen(state, { payload }) {
      state.ui.contactDialog.isOpen = payload;
    },
    setContactDialogContentType(state, { payload }) {
      state.ui.contactDialog.contentType = payload;
    },
    setContactDialogSelectedInvoice(state, { payload }) {
      state.ui.contactDialog.selectedInvoice = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInvoices.fulfilled, (state, { meta, payload }) => {
      if (state.invoices.request.id === meta.requestId) {
        state.invoices.data = payload;
        state.invoices.request = requestReducer(
          state.invoices.request,
          fulfilled({ meta })
        );
      }
    });

    builder.addCase(getAllInvoices.pending, (state, { meta }) => {
      state.invoices.data = undefined;
      state.invoices.request = requestReducer(
        state.invoices.request,
        pending({ meta })
      );
    });

    builder.addCase(getAllInvoices.rejected, (state, { meta }) => {
      state.invoices.data = undefined;
      state.invoices.request = requestReducer(
        state.invoices.request,
        rejected({ meta })
      );
    });

    builder.addCase(
      getInvoicesMetrics.fulfilled,
      (state, { meta, payload }) => {
        if (state.metrics.request.id === meta.requestId) {
          state.metrics.data = payload;
          state.metrics.request = requestReducer(
            state.metrics.request,
            fulfilled({ meta })
          );
        }
      }
    );

    builder.addCase(getInvoicesMetrics.pending, (state, { meta }) => {
      state.metrics.data = undefined;
      state.metrics.request = requestReducer(
        state.metrics.request,
        pending({ meta })
      );
    });

    builder.addCase(getInvoicesMetrics.rejected, (state, { meta }) => {
      state.metrics.data = undefined;
      state.metrics.request = requestReducer(
        state.metrics.request,
        rejected({ meta })
      );
    });
  },
});

const { actions, reducer } = slice;

export const {
  setSearch,
  setIsContactDialogOpen,
  setContactDialogContentType,
  setContactDialogSelectedInvoice,
} = actions;

export default reducer;
