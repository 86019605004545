import { createSlice } from '@reduxjs/toolkit';
import { getMagicBellHmacForCurrentUser } from 'features/notification/async';
import { NotificationsState } from 'features/notification/types';

export const initialState: NotificationsState = {
  magicBellHmac: undefined,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getMagicBellHmacForCurrentUser.fulfilled,
      (state, { payload }) => {
        state.magicBellHmac = payload;
      }
    );

    builder.addCase(getMagicBellHmacForCurrentUser.pending, (state) => {
      state.magicBellHmac = undefined;
    });

    builder.addCase(getMagicBellHmacForCurrentUser.rejected, (state) => {
      state.magicBellHmac = undefined;
    });
  },
});

const { reducer } = slice;

export default reducer;
