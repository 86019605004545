import { Theme } from '@mui/material';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { alpha } from '@mui/material/styles';
import { darkPalette } from 'theme/palettes';

export const drawerListItemStyles = (theme: Theme) => ({
  listItem: {
    justifyContent: 'center',
  },
  listItemButton: {
    width: '3rem',
    height: '3rem',
    justifyContent: 'center',
    flexGrow: 0,
    borderRadius: '0.375rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
    },
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        darkPalette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    [`&.${listItemButtonClasses.selected}`]: {
      color: theme.palette.primary.main,
      backgroundColor: alpha(
        theme.palette.primary.main,
        darkPalette.action.selectedOpacity
      ),
      [`&.${listItemButtonClasses.focusVisible}`]: {
        backgroundColor: alpha(
          theme.palette.primary.main,
          darkPalette.action.selectedOpacity + darkPalette.action.focusOpacity
        ),
      },
    },
    [`&.${listItemButtonClasses.selected}:hover`]: {
      backgroundColor: alpha(
        theme.palette.primary.main,
        darkPalette.action.selectedOpacity + darkPalette.action.hoverOpacity
      ),
      '@media (hover: none)': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          darkPalette.action.selectedOpacity
        ),
      },
    },
    [`&.${listItemButtonClasses.focusVisible}`]: {
      backgroundColor: alpha(
        theme.palette.common.white,
        darkPalette.action.focusOpacity
      ),
    },
  },
  listItemIcon: {
    minWidth: 'auto',
    color: 'inherit',
  },
  listItemText: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
  },
});
