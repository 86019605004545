import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getCustomReportsForAdvertiser } from 'features/advertiser/async';
import { AdvertiserByIdState } from 'features/advertiser/types';
import { onFulfilled, onPending, onRejected } from 'libs/slice';

export function customReportsCases(
  builder: ActionReducerMapBuilder<AdvertiserByIdState>
) {
  builder.addCase(
    getCustomReportsForAdvertiser.fulfilled,
    (state, { meta, payload }) => {
      onFulfilled(state.customReports, meta, meta.requestId, payload);
    }
  );

  builder.addCase(getCustomReportsForAdvertiser.pending, (state, { meta }) => {
    onPending(state.customReports, meta);
  });

  builder.addCase(getCustomReportsForAdvertiser.rejected, (state, { meta }) => {
    onRejected(state.customReports, meta);
  });
}
