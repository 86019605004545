import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientDto, ClientTeamDto } from '@kortxio/hub-api';
import { setError } from 'features/error/slice';
import api from 'libs/api';
import { parseApiHeader, parseApiPayload, toApiError } from 'libs/api/helper';
import { blobToBlobURL } from 'libs/media';
import { AsyncThunkConfig } from 'store/types';

export const getAllClients = createAsyncThunk<
  ClientDto[],
  void,
  AsyncThunkConfig
>('client/getAllClients', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await api.client.getAllClients();

    return parseApiPayload(response);
  } catch (error) {
    const apiError = toApiError(error);

    if (!apiError.isAbortedError) {
      dispatch(
        setError({ message: apiError.message, uiMessage: apiError.uiMessage })
      );
    }

    return rejectWithValue(apiError);
  }
});

export const getAllClientsWithUpdates = createAsyncThunk<
  ClientDto[],
  void,
  AsyncThunkConfig
>(
  'client/getAllClientsWithUpdates',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.client.getAllClients();

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getSelectedClientById = createAsyncThunk<
  ClientDto,
  { id: number },
  AsyncThunkConfig
>(
  'client/getSelectedClientById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.client.getClientById(id);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getSelectedClientByIdWithUpdates = createAsyncThunk<
  ClientDto,
  { id: number },
  AsyncThunkConfig
>(
  'client/getSelectedClientByIdWithUpdates',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.client.getClientById(id);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getTeamByClientId = createAsyncThunk<
  ClientTeamDto,
  { clientId: number },
  AsyncThunkConfig
>(
  'client/getTeamByClientId',
  async ({ clientId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.client.getTeamByClientId(clientId);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const getTeamByClientIdWithUpdates = createAsyncThunk<
  ClientTeamDto,
  { clientId: number },
  AsyncThunkConfig
>(
  'client/getTeamByClientIdWithUpdates',
  async ({ clientId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.client.getTeamByClientId(clientId);

      return parseApiPayload(response);
    } catch (error) {
      const apiError = toApiError(error);

      if (!apiError.isAbortedError) {
        dispatch(
          setError({ message: apiError.message, uiMessage: apiError.uiMessage })
        );
      }

      return rejectWithValue(apiError);
    }
  }
);

export const downloadAccountManagerPhotoByClientId = createAsyncThunk<
  string,
  { clientId: number },
  AsyncThunkConfig
>(
  'client/downloadAccountManagerPhotoByClientId',
  async ({ clientId }, { rejectWithValue }) => {
    try {
      const response = await api.client.downloadAccountManagerPhotoByClientId(
        clientId,
        { responseType: 'blob' }
      );

      const payload = parseApiPayload(response);

      return blobToBlobURL(payload, parseApiHeader('content-type', response));
    } catch (error) {
      const apiError = toApiError(error);

      return rejectWithValue(apiError);
    }
  }
);
